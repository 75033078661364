import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../css/commercial.scss";
import { logout } from "../store/store";
import config from "./../config/config";

const AdLeftBanner = () => {
  //광고배너 불러오기
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    window.location.href = "/";
  };
  const [adLeft, _adLeft] = useState("");
  const [scrollTop, _scrollTop] = useState("60%");

  useEffect(() => {
    const scrollCheck = () => {
      if (window.scrollY > 100) {
        _scrollTop("7%");
      } else if (window.scrollY < 10) {
        _scrollTop("60%");
      }
    };
    window.addEventListener("scroll", scrollCheck);
    return () => {
      window.removeEventListener("scroll", scrollCheck);
    };
  }, []);

  const getLeftBanner = async () => {
    try {
      const position = "left";
      const mode = "adbannerfront";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("position", position);

      const response = await axios.post(
        `${config.URL + "adBanner.php"}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const banners = response.data.data;
      if (banners.length > 0) {
        const randomBanner =
          banners[Math.floor(Math.random() * banners.length)];
        _adLeft(randomBanner);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getLeftBanner();
  }, []);

  const bannerClick = async () => {
    try {
      if (!adLeft) return;
      const formData = new FormData();
      formData.append("mode", "click");
      formData.append("adBannerID", adLeft.id);
      formData.append("userID", user.userID);

      await axios.post(`${config.URL + "banner.php"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      window.open(adLeft.link, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.log("클릭 저장 실패:", error);
    }
  };

  const bannerView = async () => {
    try {
      if (!adLeft) return;
      const formData = new FormData();
      formData.append("mode", "view");
      formData.append("adBannerID", adLeft.id);

      await axios.post(`${config.URL + "banner.php"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.log("클릭 저장 실패:", error);
    }
  };

  return (
    <>
      {adLeft && (
        <img
          src={`${config.FILEURL + adLeft.img}`}
          className="adLeftBanner"
          alt="leftBanner"
          onClick={bannerClick}
          onLoad={bannerView}
          style={{ cursor: "pointer", top: scrollTop }}
        />
      )}
    </>
  );
};

export default AdLeftBanner;
