import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

const RollingUniv = () => {
  const [listdata, _listdata] = useState([]);
  const [type, _type] = useState("N");
  const [title, _title] = useState("");
  const [hidden, _hidden] = useState("N");
  const [link, _link] = useState("");
  const [logo, _logo] = useState("");
  const [id, _id] = useState("");
  const [preview1, _preview1] = useState("");

  const getHtml = async () => {
    try {
      const mode = "rollingunivlist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(
        config.URL + "rollingUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "rollingunivadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("title", title);
      formData.append("link", link);
      formData.append("hidden", hidden);
      logo && formData.append("logo", logo);

      const response = await axios.post(
        config.URL + "rollingUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (univ) => {
    _id(univ.id);
    _hidden(univ.hidden);
    _title(univ.title);
    _link(univ.link);
    _preview1(config.FILEURL + univ.logo);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "rollingunivupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("title", title);
      formData.append("link", link);
      formData.append("hidden", hidden);
      formData.append("id", id);
      logo && formData.append("logo", logo);

      const response = await axios.post(
        config.URL + "rollingUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteCheck = () => {
    if (window.confirm("삭제하시겠습니까? 복구할 수 없습니다.")) {
      deleteData();
    } else {
      return;
    }
  };

  const deleteData = async () => {
    try {
      const mode = "rollingunivdel";
      const formData = new FormData();
      formData.append("id", id);
      formData.append("mode", mode);

      const response = await axios.post(
        config.URL + "rollingUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const maxLogo = () => {
    if (listdata.length == 16) {
      alert("최대 16개까지 등록이 가능합니다.");
      return;
    }
    postData();
  };

  useEffect(() => {
    getHtml();
  }, []);

  const ImgFileChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      _logo(file);
      _preview1(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>롤링배너 대학교 관리
              {type === "N" ? (
                <button onClick={() => maxLogo()}>추가</button>
              ) : (
                <>
                  <button onClick={deleteCheck}>삭제</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">대학교 이름</td>
              <td width="250px">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => _title(e.target.value)}
                  style={{ width: "80%", border: "0" }}
                />
              </td>
              <td className="tableTitle">사용여부</td>
              <td>
                <input
                  value="N"
                  checked={hidden === "N"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                사용
                <input
                  value="Y"
                  checked={hidden === "Y"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                미사용
              </td>
            </tr>
            <tr>
              <td className="tableTitle">홈페이지 링크</td>
              <td>
                <input
                  type="text"
                  value={link}
                  onChange={(e) => _link(e.target.value)}
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
              <td className="tableTitle">로고</td>
              <td rowSpan={2} style={{ textAlign: "center" }}>
                {preview1 && (
                  <img
                    src={preview1}
                    alt="미리보기"
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={ImgFileChange1}
                  style={{ width: "90%" }}
                />
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>대학교 목록
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width="10%">
                번호
              </td>
              <td className="tableListTitle">대학교</td>
              <td className="tableListTitle" width="30%">
                로고
              </td>
              <td className="tableListTitle" width="15%">
                홈페이지
              </td>
              <td className="tableListTitle" width="15%">
                사용여부
              </td>
            </tr>
            {listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{univ.title}</td>
                  <td className="tableListTitle">
                    <img
                      src={config.FILEURL + univ.logo}
                      style={{
                        width: "180px",
                        objectFit: "contain",
                      }}
                    />
                  </td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="tableListTitle">
                    {univ.hidden == "N"
                      ? "사용"
                      : univ.hidden == "Y"
                      ? "미사용"
                      : ""}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RollingUniv;
