import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

const AdminNotice = () => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [bbsType, _bbsType] = useState(page);
  const [content, _content] = useState("");
  const [notice, _notice] = useState("N");
  const [type, _type] = useState("N");
  const [title, _title] = useState("");
  const [writer, _writer] = useState("");
  const [img, _img] = useState("");
  const [hidden, _hidden] = useState("N");
  const [id, _id] = useState("");

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          "link",
          { indent: "-1" },
          { indent: "+1" },
        ],
        [
          {
            color: [
              "#000000",
              "#e60000",
              "#ff9900",
              "#ffff00",
              "#008a00",
              "#0066cc",
              "custom-color",
            ],
          },
          { background: [] },
        ],
        ["image", "video"],
      ],
    },
  };

  const getHtml = async () => {
    try {
      const mode = "bbslist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _content(response.data.data);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const reload = () => {
    window.location.reload();
  };

  const postData = async () => {
    try {
      const mode = "bbsadd";
      // const formatTitle = title.replaceAll("'", '"');
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      formData.append("title", title);
      formData.append("content", content);
      formData.append("writer", writer);
      formData.append("hidden", hidden);
      formData.append("notice", notice);
      img && formData.append("img", img);

      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (bbs) => {
    _title(bbs.title);
    _writer(bbs.writer);
    _img(bbs.img);
    _content(bbs.content);
    _hidden(bbs.hidden);
    _notice(bbs.notice);
    _id(bbs.id);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "bbsupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      formData.append("title", title);
      formData.append("content", content);
      formData.append("writer", writer);
      formData.append("hidden", hidden);
      formData.append("notice", notice);
      formData.append("id", id);
      img && formData.append("img", img);

      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      reload();
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const EditorChange = (value) => {
    _content(value);
  };

  useEffect(() => {
    if (page) {
      _bbsType(page);
    } else {
      _bbsType("O");
    }
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [bbsType]);

  const ImgFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      _img(file);
    }
  };

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>
              {bbsType === "O"
                ? "공지사항"
                : bbsType === "E"
                ? "이벤트"
                : bbsType === "CI"
                ? "커뮤니티 입시"
                : bbsType === "CS"
                ? "커뮤니티 학습"
                : bbsType === "CC"
                ? "커뮤니티 진로·취업"
                : bbsType === "N"
                ? "입시뉴스"
                : bbsType === "V"
                ? "입시영상"
                : "공지사항"}
              {type === "N" ? (
                <button onClick={() => postData()}>추가</button>
              ) : (
                <>
                  <button onClick={reload}>초기화</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">제목</td>
              <td colSpan={4}>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => _title(e.target.value)}
                  style={{ border: "0" }}
                />
              </td>
              <td className="tableTitle">공지</td>
              <td width="20%">
                <input
                  value="Y"
                  checked={notice === "Y"}
                  onChange={(e) => _notice(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                공지
                <input
                  value="N"
                  checked={notice === "N"}
                  onChange={(e) => _notice(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                해지
              </td>
            </tr>
            <tr>
              <td className="tableTitle">작성자</td>
              <td>
                <input
                  type="text"
                  value={writer}
                  onChange={(e) => _writer(e.target.value)}
                  style={{ width: "80%", border: "0" }}
                />
              </td>
              <td>{/* ({user.name}) */}</td>
              <td className="tableTitle">첨부파일</td>
              <td>
                <input
                  type="file"
                  accept="image/*"
                  onChange={ImgFileChange}
                  style={{ width: "90%" }}
                />
              </td>
              <td className="tableTitle">사용여부</td>
              <td width="20%">
                <input
                  value="N"
                  checked={hidden == "N"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                사용
                <input
                  value="Y"
                  checked={hidden == "Y"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                미사용
              </td>
            </tr>
            <tr>
              <td className="tableTitle">내용</td>
              <td colSpan={6} style={{ height: "290px" }}>
                <ReactQuill
                  value={content}
                  style={{ width: "100%", height: "250px", marginTop: "-41px" }}
                  modules={modules}
                  onChange={EditorChange}
                />
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>
              {bbsType === "O"
                ? "공지사항 목록"
                : bbsType === "E"
                ? "이벤트 목록"
                : bbsType === "CI"
                ? "커뮤니티 입시 목록"
                : bbsType === "CS"
                ? "커뮤니티 학습 목록"
                : bbsType === "CC"
                ? "커뮤니티 진로·취업 목록"
                : bbsType === "N"
                ? "입시뉴스 목록"
                : bbsType === "V"
                ? "입시영상 목록"
                : "공지사항 목록"}
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" style={{ width: "120px" }}>
                순서
              </td>
              <td className="tableListTitle">제목</td>
              <td className="tableListTitle" style={{ width: "120px" }}>
                작성자
              </td>
              <td className="tableListTitle" style={{ width: "120px" }}>
                작성일
              </td>
              <td className="tableListTitle" style={{ width: "80px" }}>
                사용여부
              </td>
            </tr>
            {listdata &&
              listdata.map((bbs, index) => (
                <tr key={index} onClick={() => changeData(bbs)}>
                  <td className="tableListTitle" style={{ width: "120px" }}>
                    {index + 1}
                  </td>
                  <td
                    className="tableListTitle"
                    style={{ textAlign: "left", paddingLeft: "10px" }}
                  >
                    {bbs.title}
                  </td>
                  <td className="tableListTitle" style={{ width: "120px" }}>
                    {bbs.writer}
                  </td>
                  <td className="tableListTitle" style={{ width: "120px" }}>
                    {bbs.createDate}
                  </td>
                  <td className="tableListTitle" style={{ width: "80px" }}>
                    {bbs.hidden == "Y"
                      ? "미사용"
                      : bbs.hidden == "N"
                      ? "사용"
                      : ""}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminNotice;
