/* eslint-disable jsx-a11y/alt-text */
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import ScheduleModal from "./../../component/scheduleModal";
import Footer from "./../../layout/footer";
import Header from "./../../layout/header";

const VisitingExpo = () => {
  const navigate = useNavigate();
  const [editor, setEditor] = useState("");
  const [scheduleDate, _scheduleDate] = useState("");
  const [selectedID, _selectedID] = useState("");
  const [pinCode, _pinCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scheduleList, _scheduleList] = useState([]);

  const koreanButtonText = {
    today: "오늘",
    prevYear: "작년",
    nextYear: "내년",
    month: "월",
    week: "주",
    day: "일",
    list: "목록",
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    getCalendar();
    setIsModalOpen(false);
  };

  const extoday = new Date();
  const dateClick = (info) => {
    if (new Date(info.dateStr) <= extoday) {
      alert("예약불가능한 날짜입니다");
    } else {
      getDayTotal(info.dateStr);

      _selectedID(null);
      _scheduleDate(info.dateStr);
      openModal();
    }
  };

  const eventClick = (info) => {
    console.log("evnet info : ", info);
    _scheduleDate(null);
    _selectedID(info.event._def.extendedProps.idx);
    _pinCode(info.event._def.extendedProps.pinCode);
    openModal();
  };

  //스케쥴 가저오기
  const getCalendar = useCallback(async () => {
    try {
      const mode = "schedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const data = response.data.data;
      if (data) {
        const scheduleDataList = data.map((item) => ({
          title: item.schoolName,
          date: item.scheduleDate,
          idx: item.id,
          pinCode: item.pinCode,
        }));
        _scheduleList(scheduleDataList);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  //예약 총 갯수 확인
  const getDayTotal = async (day) => {
    try {
      const mode = "totalschedule";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("type", day);
      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const data = response.data.data;

      console.log("data", data);
      if (data.total >= 10) {
        alert("신청이 마감된 일자 입니다.");
        closeModal();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCalendar();
  }, []);

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <div className="expoBannerBox">
        <div
          className="expoBanner"
          style={{ paddingTop: "40px", height: "230px" }}
        >
          <h1>찾아가는 박람회</h1>
          <h3 style={{ margin: "20px 0 0 0" }}>
            <strong>신청하실 날자를 선택하고 신청서를 작성해 주세요.</strong>
            <br />
            신청내역을 수정 및 취소 할 경우 신청날자의 고등학교를 선택 후 수정해
            주세요.
          </h3>
          <p>
            신청기간 지역별 상이(행사 2일전까지 신청 가능)신청 문의사항이 있으신
            경우
            <br />
            사무국 (T.02-2624-1800)으로 문의 바랍니다.
          </p>
          <h3>
            신청 완료 후 신청내역을 메시지로 받아보실수 있으며,
            <br />
            행사 1~2일전 한번 더 확인 메시지를 전송합니다.
          </h3>
        </div>
        <img
          src="/assets/exposition/visitingExpoBanner.png"
          alt="visitingExpoBanner"
          data-aos="fade-left"
          data-aos-duration="1000"
        />
      </div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <div
          className=""
          style={{
            width: "1230px",
            margin: "0 atuo",
            padding: "50px 0 50px 0",
          }}
        >
          <FullCalendar
            dayMaxEvents={true}
            plugins={[dayGridPlugin, interactionPlugin]}
            buttonText={koreanButtonText}
            weekends="false"
            hiddenDays={[0, 6]}
            locale={"ko"}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev,today",
              center: "title",
              right: "next",
            }}
            dateClick={dateClick}
            events={scheduleList}
            eventClick={eventClick}
          />
        </div>
      </div>
      {isModalOpen && (
        <ScheduleModal
          isOpen={isModalOpen}
          onClose={closeModal}
          selectDay={scheduleDate}
          selectID={selectedID}
          selectPinCode={pinCode}
        />
      )}
      <Footer />
    </>
  );
};

export default VisitingExpo;
