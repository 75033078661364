import axios from "axios";
import { format } from "date-fns";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import AdLeftBanner from "../../component/leftBanner";
import Pagination from "../../component/pagination";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import NewIcon from "./../../assets/common/newIcon.png";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const BoardList = () => {
  const { page, keyword } = useParams();
  const [listdata, _listdata] = useState([]);
  const [writeBtn, _writeBtn] = useState(false);

  const [boardPage, _boardPage] = useState(1);
  const itemsPerPage = 10;
  const [currentList, _currentList] = useState(listdata);
  const indexOfLastItem = boardPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [loading, _loading] = useState(true);
  const [color, _color] = useState("#ffffff");

  const changePage = (boardPage) => {
    _boardPage(boardPage);
  };

  const getHtml = async (page) => {
    try {
      const mode = "bbslist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", page);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);

      _listdata(response.data.data);
      _currentList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getSearch = async () => {
    try {
      const mode = "bbssearch";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("keyword", keyword);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      if (response.data.data.length > 0) {
        _listdata(response.data.data);
        _currentList(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //뉴아이콘 생성
  const checkDay = dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD");

  useEffect(() => {
    _boardPage(1);
    if (page === "search") {
      getSearch();
    } else {
      getHtml(page);
      page === "CI" ||
      page === "CS" ||
      page === "CL" ||
      page === "CC" ||
      page === "CM"
        ? _writeBtn(true)
        : _writeBtn(false);
    }
  }, [page, keyword]);

  useEffect(() => {
    _currentList(listdata.slice(indexOfFirstItem, indexOfLastItem));
  }, [boardPage, listdata]);

  //페이지네이션 구현
  //현재 페이지 번호
  // const [page, _page] = useState(1);

  //한 페이지에 보여줄 게시글 개수
  // const itemsPerPage = 10;

  //페이지 핸들링
  // const changePageHandler = (page) => {
  //   _page(page);
  // }

  //현재 페이지에 보일 원본 리스트에서 slice 한 리스트
  // const [currentList, _currentList] = useState(listdata);

  //stockList에서 slice할 index 범위
  // const indexOfLastItem = page * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //페이지가 바뀌거나 필터 변경으로 리스트 변경 시, currentList 다시 정의
  // useEffect(() => {
  //   _currentList(listdata.slice(indexOfFirstItem, indexOfLastItem));
  // }, [page, listdata]);

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <div className="communityBanner">
        <h1>
          {page === "N" && "입시뉴스"}
          {page === "R" && "입시리포트"}
          {page === "V" && "입시영상"}
          {page === "O" && "공지사항"}
          {page === "E" && "이벤트"}

          {page === "CI" && "입시"}
          {page === "CS" && "학습"}
          {page === "CC" && "진로·취업"}
          {page === "CL" && "생활"}
          {page === "CM" && "학과별 게시판"}
        </h1>
      </div>
      {writeBtn == true && (
        <div className="handleBakcBtn">
          <Link to="/boardwrite">글쓰기</Link>
        </div>
      )}
      <div className="boardListContent">
        <table>
          <thead>
            <tr className="boardListTitle">
              <th width={"10%"}>No.</th>
              {page === "search" && <th width={"10%"}>카테고리</th>}
              <th>제목</th>
              <th width={"15%"}>글쓴이</th>
              <th width={"15%"}>작성일</th>
            </tr>
          </thead>
          <tbody>
            {!listdata ? (
              <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              currentList.map((bbs, index) => (
                <tr key={index}>
                  <td>
                    {boardPage > 1
                      ? (boardPage - 1) * itemsPerPage + index + 1
                      : index + 1}
                  </td>
                  {page === "search" && (
                    <td>
                      {bbs.bbsType === "N" && "입시뉴스"}
                      {bbs.bbsType === "R" && "입시리포트"}
                      {bbs.bbsType === "V" && "입시영상"}
                      {bbs.bbsType === "O" && "공지사항"}
                      {bbs.bbsType === "E" && "이벤트"}

                      {bbs.bbsType === "CI" && "입시"}
                      {bbs.bbsType === "CS" && "학습"}
                      {bbs.bbsType === "CC" && "진로·취업"}
                      {bbs.bbsType === "CL" && "생활"}
                      {bbs.bbsType === "CM" && "학과별 게시판"}
                    </td>
                  )}
                  <td style={{ textAlign: "left" }}>
                    <Link
                      to={`/boardview/${bbs.bbsType}`}
                      state={{ data: bbs }}
                    >
                      {bbs.title}
                      {bbs.createDate >= checkDay && (
                        <img
                          src={NewIcon}
                          style={{
                            width: "30px",
                            height: "auto",
                            margin: "0 0 -2px 5px",
                          }}
                        />
                      )}
                    </Link>
                  </td>
                  <td>{bbs.writer}</td>
                  <td>{format(bbs.createDate, "yyyy-MM-dd")}</td>
                </tr>
              ))
            )}
          </tbody>
          <tr>
            <td colSpan={10}>
              <Pagination
                activePage={boardPage} // 현재 페이지
                itemsCountPerPage={itemsPerPage} // 한 페이지 당 보여줄 아이템 갯수
                totalItemsCount={listdata.length} // 총 아이템 갯수
                pageRangeDisplayed={10} // paginator에 나타낼 페이지 범위
                prevPageText={"<"} // "이전"을 나타낼 텍스트
                nextPageText={">"} // "다음"을 나타낼 텍스트
                onChange={changePage} // 페이지 변경을 핸들링하는 함수
              />
            </td>
          </tr>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default BoardList;
