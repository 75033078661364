import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config";
import "../../css/member.scss";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const FindID = () => {
  const [name, _name] = useState();
  const [email, _email] = useState();
  const [id, _id] = useState();
  const [password, _password] = useState();
  const [name2, _name2] = useState();
  const [email2, _email2] = useState();
  const navigate = useNavigate();

  const getUserID = async () => {
    try {
      const mode = "findid";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("name", name);
      formData.append("email", email);
      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      if (response.data.data) {
        let userID = response.data.data.userID;
        let length = response.data.data.userID.length;
        let mask = userID.substr(0, length - 2);
        alert("아이디는 " + mask + "** 입니다.");
      } else {
        alert("회원정보를 찾을 수 없습니다.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const FindID = () => {
    if (!name) {
      alert("이름을 입력해 주세요");
      return;
    }
    if (!email) {
      alert("이메일을 입력해 주세요");
      return;
    }
    getUserID();
  };

  const getPassword = async () => {
    try {
      const mode = "findpassword";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("id", id);
      formData.append("name", name2);
      formData.append("email", email2);
      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.data) {
        navigate("/changePassword", {
          state: { memberID: response.data.data.id },
        });
      } else {
        alert("회원정보를 찾을 수 없습니다.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const FindPassword = () => {
    if (!id) {
      alert("아이디를 입력해 주세요");
      return;
    }
    if (!name2) {
      alert("이름을 입력해 주세요");
      return;
    }
    if (!email2) {
      alert("이메일을 입력해 주세요");
      return;
    }
    getPassword();
  };

  return (
    <>
      <Header type="simple" />
      <div className="joinLayout">
        <h2>회원 찾기</h2>
        <div className="loginInfo">
          <h2>아이디 찾기</h2>
          <div className="joinInput">
            <h3>이름</h3>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => _name(e.target.value)}
            />
          </div>
          <div className="joinInput">
            <h3>이메일</h3>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => _email(e.target.value)}
            />
          </div>
        </div>
        <div className="nextBox">
          <button type="submit" onClick={FindID}>
            찾기
          </button>
          <Link to="/Login" style={{ backgroundColor: "#fff", color: "#333" }}>
            로그인
          </Link>
        </div>
        <div className="loginInfo">
          <h2>비밀번호 찾기</h2>
          <div className="joinInput">
            <h3>아이디</h3>
            <input
              type="text"
              id="id"
              value={id}
              onChange={(e) => _id(e.target.value)}
            />
          </div>
          <div className="joinInput">
            <h3>이름</h3>
            <input
              type="text"
              id="name"
              value={name2}
              onChange={(e) => _name2(e.target.value)}
            />
          </div>
          <div className="joinInput">
            <h3>이메일</h3>
            <input
              type="text"
              id="email"
              value={email2}
              onChange={(e) => _email2(e.target.value)}
            />
          </div>
        </div>
        <div className="nextBox">
          <button type="submit" onClick={FindPassword}>
            찾기
          </button>
          <Link to="/Login" style={{ backgroundColor: "#fff", color: "#333" }}>
            로그인
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FindID;
