import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import config from "../config/config";
import PrivacyPoliceModal from "./../config/PrivacyPolicyModal";

const ScheduleModal = ({
  isOpen,
  onClose,
  selectDay = null,
  selectID = null,
  selectPinCode = null,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [schoolName, _schoolName] = useState([]);

  const [checkIdx, setCheckIdx] = useState(null);

  const [scheduleDate, _scheduleDate] = useState(null);
  const [scheduleSTime, _scheduleSTime] = useState("09시");
  const [scheduleSMin, _scheduleSMin] = useState("00분");
  const [highschool, _highschool] = useState(null);
  const [teacherName, _teacherName] = useState(null);
  const [position, _position] = useState(null);
  const [phone, _phone] = useState(null);
  const [schoolPhone, _schoolPhone] = useState(null);
  const [email, _email] = useState(null);
  const [pinCode, setPinCode] = useState(null);
  const [place, _place] = useState(null);

  const [editOriUniList, setEditOriUniList] = useState(null);

  const [scheduleTime, setScheduleTime] = useState(null);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [policy, _policy] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [options, setOptions] = useState([]);
  const [scheduleTitle, setScheduleTitle] = useState(null);
  const [school, setSchool] = useState(null);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const getSchoolList = async () => {
    try {
      const mode = "schoollist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      _schoolName(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (schoolName) {
      const mappedOptions = schoolName.map((list) => ({
        value: list.area,
        label: "(" + list.area + ")" + list.schoolName,
      }));
      setOptions(mappedOptions);
    }
  }, [schoolName]);

  const handleChange = (value) => {
    console.log("value:", value);
    if (value) {
      setSchool(value);
      setScheduleTitle("(" + value.value + ")" + value.label);
      _schoolName(value.label);
    }
  };

  const openPolicy = () => {
    _policy(true);
  };

  const closePolicy = () => {
    _policy(false);
  };

  // const openCancelModal = () => {
  //   setIsCancelModalOpen(true);
  // };

  // const closeCancelModal = () => {
  //   setIsCancelModalOpen(false);
  // };

  const getScheduleInfo = async () => {
    // 스케쥴 정보 가져오기
    try {
      const mode = "scheduleinfo";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("id", selectID);
      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      _scheduleDate(response.data.data.scheduleDate);
      _teacherName(response.data.data.teacherName);
      _phone(response.data.data.phone);
      _schoolPhone(response.data.data.schoolPhone);
      _email(response.data.data.email);
    } catch (error) {
      console.log("error", error);
    }
  };

  const goPasswordPage = () => {
    if (checkIdx == selectPinCode) {
      getScheduleInfo();
      selectID = setCurrentPage(currentPage + 1);
      setCheckIdx(null);
    } else {
      alert("비밀번호를 확인해주세요");
      setCheckIdx(null);
      return;
    }
  };

  const handleSchedule = () => {
    if (schoolName == null) {
      alert("고등학교명을 입력해주세요");
      return;
    }

    if (teacherName == null) {
      alert("신청교사 성명을 입력해주세요");
      return;
    }

    if (position == null) {
      alert("직책을 선택해주세요");
      return;
    }

    if (phone == null) {
      alert("휴대폰번호를 입력해 주세요");
      return;
    }

    if (schoolPhone == null) {
      alert("직통번호를 입력해 주세요");
      return;
    }

    if (email == null) {
      alert("이메일을 입력해 주세요");
      return;
    }

    if (isChecked == false) {
      alert("개인정보 수집에 동의해 주세요");
      return;
    }

    if (selectID) {
      updateData();
    } else {
      postData();
    }
  };

  const resetData = () => {
    _scheduleSTime("09시");
    _scheduleSMin("00분");
    _teacherName(null);
    _position(null);
    _schoolPhone(null);
    _phone(null);
    setIsChecked(false);
    setCurrentPage(1);
    setPinCode(null);
  };

  const postData = async () => {
    try {
      let scheduleTime = scheduleSTime + "" + scheduleSMin;
      const mode = "scheduleadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("scheduleDate", scheduleDate);
      formData.append("scheduleTime", scheduleTime);
      formData.append("schoolName", highschool);
      formData.append("teacherName", teacherName);
      formData.append("position", position);
      formData.append("phone", phone);
      formData.append("schoolPhone", schoolPhone);
      formData.append("email", email);
      formData.append("pinCode", pinCode);
      formData.append("place", place);

      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      alert("신청이 완료 되었습니다. ");
      resetData();
      onClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateData = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      let scheduleTime = scheduleSTime + "" + scheduleSMin;
      const mode = "scheduleupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("scheduleDate", scheduleDate);
      formData.append("scheduleTime", scheduleTime);
      formData.append("schoolName", highschool);
      formData.append("teacherName", teacherName);
      formData.append("position", position);
      formData.append("place", place);
      formData.append("phone", phone);
      formData.append("schoolPhone", schoolPhone);
      formData.append("pinCode", pinCode);
      formData.append("email", email);
      formData.append("id", selectID);

      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      alert("수정이 완료 되었습니다. ");

      resetData();
      onClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const mobileNoChange = (event) => {
    const input = event.target.value.replace(/\D/g, "");

    let formattedInput = "";

    formattedInput = input
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/-{1,2}$/g, "");

    _phone(formattedInput);
  };

  const telNoChange = (event) => {
    const input = event.target.value.replace(/\D/g, "");
    let changeinput = "";
    if (input.length > 10 && input.length < 12) {
      changeinput = input
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/-{1,2}$/g, "");
    } else if (input.length > 9 && input.length < 11) {
      changeinput = input
        .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/-{1,2}$/g, "");
    } else {
      changeinput = input
        .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/-{1,2}$/g, "");
    }

    _schoolPhone(changeinput);
  };

  const cancelData = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const mode = "scheduledel";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("id", selectID);

      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      alert("신청 취소 되었습니다. ");

      window.location.replace("/VisitingExpo");
      resetData();
      onClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  const cancelSchedule = () => {
    if (window.confirm("신청을 취소 하시겠습니까?")) {
      cancelData();
    } else {
      return;
    }
  };
  useEffect(() => {
    getSchoolList();
    if (selectDay) {
      _scheduleDate(selectDay);
    }
    selectID && selectID != null ? setCurrentPage(0) : setCurrentPage(1);
  }, [selectDay, selectID]);

  return (
    <>
      <div className="overlay" onClick={onClose}>
        <div
          className={`teacherExpoModal ${isOpen ? "" : "hidden"}`}
          style={{ width: "800px", fontSize: "12px", height: "630px" }}
          onClick={(e) => e.stopPropagation()}
        >
          <form onSubmit={handleSchedule}>
            {currentPage === 0 && (
              <>
                <div className="modalTitleBox">
                  <h3>찾아가는 박람회 신청 내역</h3>
                </div>
                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "30px 0 10px",
                  }}
                >
                  비밀번호
                </h3>
                <input
                  type="password"
                  onChange={(e) => setCheckIdx(e.target.value)}
                  id="checkidx"
                  style={{
                    border: "1px solid #dfdfdf",
                    width: "155px",
                    height: "25px",
                    textAlign: "center",
                    padding: "0 10px",
                    backgroundColor: "#f5f5f5",
                  }}
                  required
                />

                <div className="modalBtn" style={{ marginTop: "30px" }}>
                  <button
                    type="button"
                    onClick={() => goPasswordPage()}
                    className="nextBtn"
                  >
                    확인
                  </button>
                  <button onClick={onClose} type="button">
                    취소
                  </button>
                </div>
              </>
            )}
            {currentPage === 1 && (
              <>
                <div>
                  <div className="modalTitleBox">
                    <h3>찾아가는 박람회 신청</h3>
                  </div>
                  <div className="modalDateBox">
                    <div className="modalDevide">
                      <h3>날짜</h3>
                      <input
                        type="text"
                        value={selectDay ? selectDay : scheduleDate}
                        id="scheduleDate"
                        required
                      />
                    </div>
                    <div className="modalDevide">
                      <h3>요청 시간</h3>
                      <div>
                        <select
                          id="scheduleSTime"
                          value={scheduleSTime}
                          onChange={(e) => _scheduleSTime(e.target.value)}
                        >
                          <option value="09시">09시</option>
                          <option value="10시">10시</option>
                          <option value="11시">11시</option>
                          <option value="12시">12시</option>
                          <option value="13시">13시</option>
                          <option value="14시">14시</option>
                          <option value="15시">15시</option>
                          <option value="16시">16시</option>
                        </select>
                        <select
                          id="scheduleSMin"
                          value={scheduleSMin}
                          onChange={(e) => _scheduleSMin(e.target.value)}
                        >
                          <option value="00분">00분</option>
                          <option value="05분">05분</option>
                          <option value="10분">10분</option>
                          <option value="15분">15분</option>
                          <option value="20분">20분</option>
                          <option value="25분">25분</option>
                          <option value="30분">30분</option>
                          <option value="35분">35분</option>
                          <option value="40분">40분</option>
                          <option value="45분">45분</option>
                          <option value="50분">50분</option>
                          <option value="55분">55분</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="modalDateBox">
                    <div className="modalDevide">
                      <h3>고등학교 명</h3>
                      <Select
                        value={school}
                        onChange={handleChange}
                        options={options}
                      />
                    </div>

                    <div className="modalDevide">
                      <h3>신청 장소</h3>
                      <ul>
                        <li>
                          <input
                            type="radio"
                            name="place"
                            value="G"
                            checked={place === "G" && "checked"}
                            onChange={(e) => _place(e.target.value)}
                          />
                          체육관
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="place"
                            value="H"
                            checked={place === "H" && "checked"}
                            onChange={(e) => _place(e.target.value)}
                          />
                          강당
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="place"
                            value="C"
                            checked={place === "C" && "checked"}
                            onChange={(e) => _place(e.target.value)}
                          />
                          교실
                        </li>
                        <li>
                          <input
                            type="radio"
                            name="place"
                            value="E"
                            checked={place === "E" && "checked"}
                            onChange={(e) => _place(e.target.value)}
                          />
                          기타
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="modalDateBox">
                    <div className="modalDevide">
                      <h3>신청교사 성명</h3>
                      <input
                        type="text"
                        value={teacherName}
                        onChange={(e) => _teacherName(e.target.value)}
                        placeholder=""
                        required
                        style={{
                          border: "1px solid #dfdfdf",
                          width: "155px",
                          padding: "0 10px",
                          backgroundColor: "#f5f5f5",
                        }}
                      />
                    </div>
                    <div className="modalDevide">
                      <h3>신청교사 직책</h3>
                      <select
                        value={position}
                        onChange={(e) => _position(e.target.value)}
                        style={{ width: "180px" }}
                      >
                        <option>직책을 선택해주세요.</option>
                        <option value="3학년부장">3학년부장</option>
                        <option value="3학년담임">3학년담임</option>
                        <option value="진로진학부장">진로진학부장</option>
                        <option value="기타">기타</option>
                      </select>
                    </div>
                  </div>

                  <div className="modalDateBox">
                    <div className="modalDevide">
                      <h3>휴대폰번호</h3>
                      <input
                        type="text"
                        value={phone}
                        onChange={mobileNoChange}
                        placeholder="010-1234-5678"
                        maxLength="13"
                        required
                        style={{
                          border: "1px solid #dfdfdf",
                          width: "155px",
                          padding: "0 10px",
                          backgroundColor: "#f5f5f5",
                        }}
                      />
                    </div>

                    <div className="modalDevide">
                      <h3>직통번호</h3>
                      <input
                        type="text"
                        value={schoolPhone}
                        onChange={telNoChange}
                        placeholder="031-1234-5678"
                        maxLength="13"
                        required
                        style={{
                          border: "1px solid #dfdfdf",
                          width: "155px",
                          padding: "0 10px",
                          backgroundColor: "#f5f5f5",
                        }}
                      />
                    </div>
                  </div>

                  <div className="modalDateBox">
                    <div className="modalDevide">
                      <h3>비밀번호</h3>
                      <input
                        type="text"
                        value={pinCode}
                        onChange={(e) => setPinCode(e.target.value)}
                        maxLength="10"
                        required
                        style={{
                          border: "1px solid #dfdfdf",
                          width: "155px",
                          padding: "0 10px",
                          backgroundColor: "#f5f5f5",
                        }}
                      />
                    </div>

                    <div className="modalDevide">
                      <h3>이메일</h3>
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => _email(e.target.value)}
                        required
                        style={{
                          border: "1px solid #dfdfdf",
                          width: "155px",
                          padding: "0 10px",
                          backgroundColor: "#f5f5f5",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <h2>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      style={{ width: "15px", margin: "0 3px 1px 0" }}
                    />
                    <button
                      onClick={() => openPolicy()}
                      style={{
                        width: "200px",
                        border: "0",
                        padding: "0",
                        margin: "0 0 3px 0",
                      }}
                    >
                      개인정보 수집 및 이용 동의
                    </button>
                  </h2>
                </div>
                <div className="modalBtn">
                  {selectID ? (
                    <>
                      <button
                        type="button"
                        onClick={() => handleSchedule()}
                        className="nextBtn"
                      >
                        수정하기
                      </button>
                      <button type="button" onClick={() => cancelSchedule()}>
                        신청 취소
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => handleSchedule()}
                        className="nextBtn"
                      >
                        신청하기
                      </button>
                      <button onClick={onClose} type="button">
                        취소
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </form>
          {policy && (
            <PrivacyPoliceModal isOpen={policy} onClose={closePolicy} />
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleModal;
