import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuickMenuRight from "../../component/quickMenuR";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

import "react-quill-new/dist/quill.snow.css";

const Qa = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [qaType, _qaType] = useState("");
  const [companyName, _companyName] = useState("");
  const [qaName, _qaName] = useState("");
  const [phone, _phone] = useState("");
  const [eMail, _eMail] = useState("");
  const [title, _title] = useState("");
  const [content, _content] = useState("");

  const EditorChange = (value) => {
    _content(value);
  };

  const postData = async () => {
    if (!qaType) {
      alert("이름을 입력해주세요");
      return;
    }
    if (!qaName) {
      alert("이름을 입력해주세요");
      return;
    }
    if (!phone) {
      alert("휴대폰 번호를 입력해주세요");
      return;
    }
    if (!eMail) {
      alert("이메일을 입력해주세요");
      return;
    }
    if (!title) {
      alert("제목을 입력해주세요");
      return;
    }
    if (!content) {
      alert("내용을 입력해주세요");
      return;
    }

    try {
      const mode = "bbsadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("qaType", qaType);
      formData.append("companyName", companyName);
      formData.append("qaName", qaName);
      formData.append("phone", phone);
      formData.append("eMail", eMail);
      formData.append("title", title);
      formData.append("content", content);

      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      // navigate("/qatype");
    } catch (error) {
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   if (page) {
  //     _qaType(page);
  //   } else {
  //     _qaType("N");
  //   }
  // }, [page]);

  return (
    <>
      <Header />
      <QuickMenuRight />
      <img src="/assets/contactus/qaBanner.png" className="communityBanner" />
      <h2 className="qaTitle">
        <span></span>
        문의하기
        {/* {qaType == "N"
          ? "일반 문의"
          : qaType == "E"
          ? "설명회/박람회 문의"
          : qaType == "P"
          ? "매체상품 문의"
          : qaType == "J"
          ? "제휴 문의"
          : "일반 문의"} */}
      </h2>
      <h3
        className="qaSub"
        style={{ color: "#df0000", fontSize: "14px", marginBottom: "10px" }}
      >
        *는 필수 항목입니다.
      </h3>
      <div className="subContent">
        <table className="table">
          <tr>
            <td className="tableTitle">
              질문 유형 선택<font color="#df0000">*</font>
            </td>
            <td style={{ fontSize: "15px" }} colSpan={5}>
              <input
                value="N"
                name="qaType"
                type="radio"
                className="tableRadio"
                onChange={(e) => _qaType(e.target.value)}
                checked={qaType == "N" && "checked"}
              />
              일반 문의
              <input
                value="E"
                name="qaType"
                type="radio"
                className="tableRadio"
                onChange={(e) => _qaType(e.target.value)}
                checked={qaType == "E" && "checked"}
              />
              설명회/박람회 문의
              <input
                value="P"
                name="qaType"
                type="radio"
                className="tableRadio"
                onChange={(e) => _qaType(e.target.value)}
                checked={qaType == "P" && "checked"}
              />
              매체상품 문의
              <input
                value="J"
                name="qaType"
                type="radio"
                className="tableRadio"
                onChange={(e) => _qaType(e.target.value)}
                checked={qaType == "J" && "checked"}
              />
              제휴 문의
            </td>
          </tr>
          <tr>
            <td className="tableTitle">
              이름<font color="#df0000">*</font>
            </td>
            <td width={"35%"}>
              <input
                type="text"
                value={qaName}
                onChange={(e) => _qaName(e.target.value)}
                style={{ width: "80%", border: "0" }}
              />
            </td>
            <td className="tableTitle">
              이메일 주소<font color="#df0000">*</font>
            </td>
            <td colSpan={3}>
              <input
                type="text"
                value={eMail}
                onChange={(e) => _eMail(e.target.value)}
                style={{ width: "90%", border: "0" }}
              />
            </td>
          </tr>
          <tr>
            <td className="tableTitle">
              전화번호<font color="#df0000">*</font>
            </td>
            <td>
              <input
                type="text"
                value={phone}
                onChange={(e) => _phone(e.target.value)}
                style={{ width: "90%", border: "0" }}
              />
            </td>

            <td className="tableTitle">회사명 또는 학교명</td>
            <td>
              <input
                type="text"
                value={companyName}
                onChange={(e) => _companyName(e.target.value)}
                style={{ width: "80%", border: "0" }}
              />
            </td>
          </tr>
          <tr>
            <td className="tableTitle">
              제목<font color="#df0000">*</font>
            </td>
            <td colSpan={5}>
              <input
                type="text"
                value={title}
                onChange={(e) => _title(e.target.value)}
                style={{ width: "97%", border: "0" }}
              />
            </td>
          </tr>
          <tr>
            <td className="tableTitle">내용</td>
            <td style={{ height: "350px" }} colSpan={5}>
              <textarea
                value={content}
                onChange={(e) => _content(e.target.value)}
              />
            </td>
          </tr>
        </table>
      </div>
      <div className="okQa">
        <button onClick={postData} className="okQa">
          문의하기
        </button>
      </div>
      <Footer />
    </>
  );
};

export default Qa;
