import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

const Univ = () => {
  const [listdata, _listdata] = useState([]);
  const [type, _type] = useState("N");
  const [schoolName, _schoolName] = useState("");
  const [local, _local] = useState("S");
  const [hidden, _hidden] = useState("N");
  const [link, _link] = useState("");
  const [link2, _link2] = useState("");
  const [link3, _link3] = useState("");
  const [logo, _logo] = useState("");
  const [emblem, _emblem] = useState("");
  const [id, _id] = useState("");
  const [preview1, _preview1] = useState("");
  const [preview2, _preview2] = useState("");
  const [bestUniv, _bestUniv] = useState("N");

  const getHtml = async () => {
    try {
      const mode = "univlist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "univ.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "univadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", schoolName);
      formData.append("local", local);
      formData.append("homepage", link);
      formData.append("earlyAdmissionLink", link2);
      formData.append("regularAdmissionLink", link3);
      formData.append("hidden", hidden);
      formData.append("bestUniv", bestUniv);
      logo && formData.append("logo", logo);
      emblem && formData.append("emblem", emblem);

      const response = await axios.post(config.URL + "univ.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (univ) => {
    _id(univ.id);
    _hidden(univ.hidden);
    _schoolName(univ.schoolName);
    _local(univ.local);
    _link(univ.homepage);
    _link2(univ.earlyAdmissionLink);
    _link3(univ.regularAdmissionLink);
    _preview1(config.FILEURL + univ.logo);
    _preview2(config.FILEURL + univ.emblem);
    _bestUniv(univ.bestUniv);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "univupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", schoolName);
      formData.append("local", local);
      formData.append("homepage", link);
      formData.append("earlyAdmissionLink", link2);
      formData.append("regularAdmissionLink", link3);
      formData.append("hidden", hidden);
      formData.append("bestUniv", bestUniv);
      formData.append("id", id);
      logo && formData.append("logo", logo);
      emblem && formData.append("emblem", emblem);

      const response = await axios.post(config.URL + "univ.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteCheck = () => {
    if (window.confirm("삭제하시겠습니까? 복구할 수 없습니다.")) {
      deleteData();
    } else {
      return;
    }
  };

  const deleteData = async () => {
    try {
      const mode = "univdel";
      const formData = new FormData();
      formData.append("id", id);
      formData.append("mode", mode);

      const response = await axios.post(config.URL + "univ.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, []);

  const ImgFileChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      _logo(file);
      _preview1(URL.createObjectURL(file));
    }
  };

  const ImgFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      _emblem(file);
      _preview2(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>대학교 관리
              {type === "N" ? (
                <button onClick={() => postData()}>추가</button>
              ) : (
                <>
                  <button onClick={deleteCheck}>삭제</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">대학교 이름</td>
              <td width="250px">
                <input
                  type="text"
                  value={schoolName}
                  onChange={(e) => _schoolName(e.target.value)}
                  style={{ width: "80%", border: "0" }}
                />
              </td>
              <td className="tableTitle">지역</td>
              <td width={"130px"}>
                <select value={local} onChange={(e) => _local(e.target.value)}>
                  <option value="S">서울</option>
                  <option value="C">충청</option>
                  <option value="H">호남</option>
                  <option value="G">경남</option>
                </select>
              </td>
              <td className="tableTitle">사용여부</td>
              <td>
                <input
                  value="N"
                  checked={hidden === "N"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                사용
                <input
                  value="Y"
                  checked={hidden === "Y"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                미사용
              </td>
            </tr>
            <tr>
              <td className="tableTitle">홈페이지 링크</td>
              <td colSpan={3}>
                <input
                  type="text"
                  value={link}
                  onChange={(e) => _link(e.target.value)}
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
              <td className="tableTitle">BEST노출</td>
              <td>
                <input
                  value="Y"
                  checked={bestUniv === "Y"}
                  onChange={(e) => _bestUniv(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                노출
                <input
                  value="N"
                  checked={bestUniv === "N"}
                  onChange={(e) => _bestUniv(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                미노출
              </td>
            </tr>
            <tr>
              <td className="tableTitle">수시 정보 링크</td>
              <td colSpan={3}>
                <input
                  type="text"
                  value={link2}
                  onChange={(e) => _link2(e.target.value)}
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
              {/* <td className="tableTitle" rowSpan={2}>
                로고
              </td>
              <td rowSpan={2} style={{ textAlign: "center" }}>
                {preview1 && (
                  <img
                    src={preview1}
                    alt="미리보기"
                    style={{
                      width: "200px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={ImgFileChange1}
                  style={{ width: "90%" }}
                />
              </td> */}
              <td className="tableTitle" rowSpan={2}>
                앰블럼
              </td>
              <td rowSpan={2} style={{ textAlign: "center" }}>
                {preview2 && (
                  <img
                    src={preview2}
                    alt="미리보기"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={ImgFileChange2}
                  style={{ width: "90%" }}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">정시 정보 링크</td>
              <td colSpan={3}>
                <input
                  type="text"
                  value={link3}
                  onChange={(e) => _link3(e.target.value)}
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>대학교 목록
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle">지역</td>
              <td className="tableListTitle">대학교</td>
              {/* <td className="tableListTitle" width="20%">
                로고
              </td> */}
              <td className="tableListTitle" width="15%">
                앰블럼
              </td>
              <td className="tableListTitle" width="10%">
                홈페이지
              </td>
              <td className="tableListTitle" width="10%">
                수시
              </td>
              <td className="tableListTitle" width="10%">
                정시
              </td>
              <td className="tableListTitle" width="10%">
                사용여부
              </td>
              <td className="tableListTitle" width="10%">
                BEST
              </td>
            </tr>
            {listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">
                    {univ.local === "S"
                      ? "서울"
                      : univ.local === "C"
                      ? "충청"
                      : univ.local === "H"
                      ? "호남"
                      : univ.local === "G"
                      ? "경남"
                      : ""}
                  </td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  {/* <td className="tableListTitle">
                    <img
                      src={config.FILEURL + univ.logo}
                      style={{
                        width: "180px",
                        objectFit: "contain",
                      }}
                      alt="logo"
                    />
                  </td> */}
                  <td className="tableListTitle">
                    <img
                      src={config.FILEURL + univ.emblem}
                      style={{
                        width: "60px",
                        objectFit: "contain",
                      }}
                      alt="emblem"
                    />
                  </td>
                  <td className="tableListTitle">
                    {univ.homepage ? (
                      <a href={univ.homepage} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="tableListTitle">
                    {univ.earlyAdmissionLink ? (
                      <a
                        href={univ.earlyAdmissionLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="tableListTitle">
                    {univ.regularAdmissionLink ? (
                      <a
                        href={univ.regularAdmissionLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="tableListTitle">
                    {univ.hidden == "N"
                      ? "사용"
                      : univ.hidden == "Y"
                      ? "미사용"
                      : ""}
                  </td>
                  <td className="tableListTitle">
                    {univ.bestUniv == "Y"
                      ? "노출"
                      : univ.bestUniv == "N"
                      ? "미노출"
                      : ""}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Univ;
