import axios from "axios";
import { format } from "date-fns";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuR from "../../component/quickMenuR";
import TeacherExpoModal from "../../component/teacherExpoModal";
import config from "../../config/config";
import "./../../css/exposition.scss";
import "./../../css/main.scss";
import Footer from "./../../layout/footer";
import Header from "./../../layout/header";

const TeacherExpoBS = () => {
  const [isModalOpen, _isModalOpen] = useState(false);
  const [collegeList, _collegeList] = useState([]);
  const [scheduleList, _scheduleList] = useState([]);
  const [startDate, _startDate] = useState();
  const [local, _local] = useState();
  const [city, _city] = useState();
  const [id, _id] = useState();

  //대학리스트 불러오기
  const getCollegeList = async () => {
    try {
      const mode = "teacherunivlist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", "B");
      const response = await axios.post(
        config.URL + "teacherUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _collegeList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //행사일정 불러오기
  const getSchedule = async () => {
    try {
      const mode = "teacherschedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", "B");
      const response = await axios.post(config.URL + "expo.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _scheduleList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const openModal = (startDate, local, city, id) => {
    _startDate(startDate);
    _local(local);
    _city(city);
    _id(id);
    _isModalOpen(true);
  };

  const closeModal = () => {
    _isModalOpen(false);
  };

  useEffect(() => {
    getCollegeList();
    getSchedule();
  }, []);

  const endDate = (startDate) => {
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    const checkDate = dayjs(startDate).add(-1, "day").format("YYYY-MM-DD");
    if (today > checkDate) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <div
        className="DJExpoBanner"
        style={{
          backgroundImage: "url(./../assets/exposition/teacherExpoBS.png)",
        }}
      >
        <h1>부산·울산·경남 지역 대학교 연합 설명회</h1>
        <h2>교사대상 설명회 신청 안내</h2>
        <h3>
          본 설명회는 아이앤유니가 주관하는 2025년 교사대상 설명회입니다
          <br />
          행사일시와 장소를 확인하신 후 아래 신청하기 버튼을 클릭하셔서 신청을
          진행해주시기 바랍니다.
          <br />
          <point>(신청기간 이전에는 신청불가)</point>
        </h3>
      </div>
      <div className="univLinkTitle">
        <h1>
          <span></span>참여대학 입학처 바로가기
        </h1>
        <ul>
          {collegeList.map((college) => (
            <li>
              <a href={college.homepage} target="_blank" rel="noreferrer">
                <img src={config.FILEURL + college.emblem} />
                {college.schoolName}
                <span>홈페이지 바로가기</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="univLinkTitle" style={{ marginBottom: "100px" }}>
        <h1>
          <span></span>행사일정
        </h1>
        <div className="scheduleList">
          {scheduleList &&
            scheduleList.map((scheduleList, index) =>
              scheduleList.local == "B" ? (
                <div key={index} className="scheduleContent">
                  <h2>
                    {format(scheduleList.startDate, "MM월 dd일")}
                    <br />
                    {scheduleList.city}
                  </h2>
                  {endDate(scheduleList.startDate) ? (
                    <button
                      type="button"
                      onClick={() => {
                        alert("신청이 마감 되었습니다.");
                      }}
                      style={{ backgroundColor: "#bbb" }}
                    >
                      마감
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        openModal(
                          scheduleList.startDate,
                          scheduleList.local,
                          scheduleList.city,
                          scheduleList.id
                        );
                      }}
                    >
                      {" "}
                      신청{" "}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )
            )}
        </div>
      </div>
      {isModalOpen && (
        <TeacherExpoModal
          isOpen={isModalOpen}
          onClose={closeModal}
          startDate={startDate}
          local={local}
          city={city}
          id={id}
        />
      )}
      <Footer />
    </>
  );
};

export default TeacherExpoBS;
