import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config/config";
import "./../../css/mypage.scss";

const ChangePW = () => {
  const user = useSelector((state) => state.auth.user);
  const [id, _id] = useState("");
  const [password, _password] = useState("");
  const [checkPassword, _checkPassword] = useState("");
  const [passwordLabel, _passwordLabel] = useState("");
  const [passwordLabelOK, _passwordLabelOK] = useState("");
  const [newPassword, _newPassword] = useState("");
  const [newPasswordCheck, _newPasswordCheck] = useState("");
  const [confirmPasswordLabel, _confirmPasswordLabel] = useState("");
  const [confirmPasswordLabelOK, _confirmPasswordLabelOK] = useState("");

  const getHtml = async () => {
    try {
      const mode = "passwordinfo";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("userID", user.userID);
      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _password(response.data.data.password);
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateData = async () => {
    try {
      const mode = "passwordupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("password", newPassword);
      formData.append("userID", user.userID);
      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      alert("비밀번호 변경이 완료되었습니다.");
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, []);

  useEffect(() => {
    if (checkPassword) {
      if (password === checkPassword) {
        _passwordLabelOK(" 비밀번호 확인");
        _passwordLabel("");
      } else {
        _passwordLabel(" 비밀번호가 일치하지 않습니다.");
        _passwordLabelOK("");
      }
    }
  }, [checkPassword, password]);

  useEffect(() => {
    if (newPasswordCheck) {
      if (newPassword === newPasswordCheck) {
        _confirmPasswordLabelOK(" 비밀번호 일치");
        _confirmPasswordLabel("");
      } else {
        _confirmPasswordLabel(" 비밀번호가 다릅니다.");
        _confirmPasswordLabelOK("");
      }
    }
  }, [newPasswordCheck, newPassword]);

  return (
    <div className="mypageBox">
      <div className="substance">
        <h2>
          <span></span>비밀번호 변경
        </h2>
      </div>
      <table className="mypageTable">
        <tr>
          <td className="tableTitle">현재 비밀번호</td>
          <td>
            <input
              type="password"
              value={checkPassword}
              onChange={(e) => _checkPassword(e.target.value)}
            />
            <label style={{ color: "green" }}>{passwordLabelOK}</label>
            <label style={{ color: "red" }}>{passwordLabel}</label>
          </td>
        </tr>
        <tr>
          <td className="tableTitle">새 비밀번호</td>
          <td>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => _newPassword(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td className="tableTitle">새 비밀번호 확인</td>
          <td>
            <input
              type="password"
              value={newPasswordCheck}
              onChange={(e) => _newPasswordCheck(e.target.value)}
            />
            <label style={{ color: "green" }}>
              {confirmPasswordLabelOK}
            </label>
            <label style={{ color: "red" }}>{confirmPasswordLabel}</label>
          </td>
        </tr>
      </table>
      <div className="okQa" style={{ marginBottom: "50px" }}>
        <button onClick={updateData}>수정하기</button>
      </div>
    </div>
  );
};

export default ChangePW;
