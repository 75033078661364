import Aos from "aos";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../css/commercial.scss";
import { logout } from "../store/store";
import config from "./../config/config";
Aos.init();

const CollegeBanner = () => {
  //광고배너 불러오기
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    window.location.href = "/";
  };
  const [adWide, _adWide] = useState("");

  const getWideBanner = async () => {
    try {
      const position = "wide";
      const mode = "adbannerfront";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("position", position);

      const response = await axios.post(
        `${config.URL + "adBanner.php"}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const banners = response.data.data;
      if (banners.length > 0) {
        const randomBanner =
          banners[Math.floor(Math.random() * banners.length)];
        _adWide(randomBanner);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getWideBanner();
  }, []);

  const bannerClick = async () => {
    try {
      if (!adWide) return;
      const formData = new FormData();
      formData.append("mode", "click");
      formData.append("adBannerID", adWide.id);
      formData.append("userID", user.userID);

      await axios.post(`${config.URL + "banner.php"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      window.open(adWide.link, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.log("클릭 저장 실패:", error);
    }
  };

  const bannerView = async () => {
    try {
      if (!adWide) return;
      const formData = new FormData();
      formData.append("mode", "view");
      formData.append("adBannerID", adWide.id);

      await axios.post(`${config.URL + "banner.php"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.log("클릭 저장 실패:", error);
    }
  };

  return (
    <div data-aos="flip-down" data-aos-duration="1000">
      {adWide && (
        <img
          src={`${config.FILEURL + adWide.img}`}
          className="college_banner"
          alt="wideBanner"
          onClick={bannerClick}
          onLoad={bannerView}
          style={{ cursor: "pointer" }}
        />
      )}
    </div>
  );
};

export default CollegeBanner;
