import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { logout } from "../../store/store";
import "./../../css/mypage.scss";

const QuitMember = () => {
  const user = useSelector((state) => state.auth.user);
  const [tabState, _tabState] = useState("quitInfo");
  const [id, _id] = useState("");
  const [userID, _userID] = useState("");
  const dispatch = useDispatch();

  //약관동의
  const [checkedInputs, setCheckedInputs] = useState([]);

  //회원탈퇴
  const deleteData = async () => {
    try {
      const mode = "delmember";
      const formData = new FormData();
      formData.append("userID", user.userID);
      formData.append("mode", mode);

      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      dispatch(logout());
      _tabState("completedel");
    } catch (error) {
      console.log("error", error);
    }
  };

  const delData = () => {
    if (checkedInputs.includes("agreeQuit")) {
      deleteData();
    } else {
      alert("탈퇴약관에 동의해 주세요.");
    }
  };

  //회원탈퇴 선택여부
  const onCheckHandler = useCallback((checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      setCheckedInputs(checkedInputs.filter((el) => el !== id));
    }
  });

  return (
        <div className="mypageBox">
          <div className="substance">
            <h2>
              <span></span>회원 탈퇴
            </h2>
          </div>
          {tabState === "quitInfo" && (
            <>
              <div className="quitInfo">
                <p style={{ marginTop: "20px" }}>
                  *회원탈퇴 진행 전 안내사항을 확인해 주세요*
                </p>
                <ul>
                  <li style={{ margin: "0 0 10px 0", fontSize: "17px" }}>
                    <strong>
                      가입하신 아이디({user.userID})에 대한 탈퇴 전
                      안내사항입니다.
                    </strong>
                  </li>
                  <li>
                    탈퇴를 진행할 경우 가입된 회원정보 모두가 삭제되며 복구가
                    불가합니다.
                  </li>
                  <li>
                    게시판에 작성한 게시물은 삭제되지 않고 남아 있습니다.
                    <br />
                    탈퇴 후에는 회원정보가 삭제되어 게시글을 임의로 삭제해드릴
                    수 없습니다.
                    <p>
                      게시글 삭제를 원하시는 경우 탈퇴 전 삭제하시기 바랍니다.
                    </p>
                  </li>
                </ul>
                <div className="quitCheck">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      onCheckHandler(e.currentTarget.checked, "agreeQuit");
                    }}
                    checked={checkedInputs.includes("agreeQuit") ? true : false}
                  />
                  <label>안내사항을 모두 확인하였으며, 이에 동의합니다.</label>
                </div>
              </div>
              <div className="okQa" style={{ marginBottom: "50px" }}>
                <button onClick={delData}>탈퇴하기</button>
              </div>
            </>
          )}
          {tabState === "completedel" && (
            <>
              <div className="completeQuit">
                <img src="/assets/common/checkIcon.png" />
                <h1>회원 탈퇴 완료</h1>
                <p>
                  그동안 아이앤유니를 이용해주셔서 감사합니다.
                  <br />
                  더욱더 노력하고 발전하는 아이앤유니가 되겠습니다.
                </p>
                <Link to="/">홈으로 돌아가기</Link>
              </div>
            </>
          )}
        </div>
  );
};

export default QuitMember;
