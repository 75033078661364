import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const TeacherReservationList = () => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [tabState, _tabState] = useState("D");
  const [excelData, _excelData] = useState([]);

  const getHtml = async () => {
    try {
      const mode = "teacherexporequestlist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(
        config.URL + "teacherExpoRequest.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
      _excelData(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = "excel_data";

  const excelDownload = (excelData) => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "행사장소",
        "행사개최일",
        "고등학교명",
        "교사이름",
        "직책",
        "휴대폰",
        "신청현황",
      ],
    ]);

    var today = Date.now();

    excelData.forEach((data) => {
      const scheduleState = data.cancel == "N" ? "신청" : "신청취소";
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            data.city,
            data.startDate,
            data.schoolName,
            data.teacherName,
            data.jobPosition,
            data.phone,
            scheduleState,
          ],
        ],
        {
          origin: -1,
        }
      );
      return false;
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelBuffer], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
  };

  useEffect(() => {
    page == "D"
      ? _tabState("D")
      : page == "B"
      ? _tabState("B")
      : _tabState("D");
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [tabState]);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>교사설명회 신청 내역
            </h2>
          </div>
          <ul
            className="tabStateUl"
            style={{ width: "1060px", marginBottom: "30px" }}
          >
            <li
              className={tabState === "D" ? "tabOn" : ""}
              onClick={() => {
                _tabState("D");
              }}
            >
              대전지역
            </li>
            <li
              className={tabState === "B" ? "tabOn" : ""}
              onClick={() => {
                _tabState("B");
              }}
            >
              부산 · 울산 · 경남지역
            </li>
          </ul>
          <div className="substance">
            <h2>
              <button type="button" onClick={() => excelDownload(excelData)}>
                excel
              </button>
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width={"5%"}>
                분류
              </td>
              <td className="tableListTitle" width={"10%"}>
                행사장소
              </td>
              <td className="tableListTitle" width={"13%"}>
                행사 개최일
              </td>
              <td className="tableListTitle" width={"19%"}>
                고등학교명
              </td>
              <td className="tableListTitle" width={"10%"}>
                교사이름
              </td>
              <td className="tableListTitle" width={"15%"}>
                직책
              </td>
              <td className="tableListTitle" width={"18%"}>
                휴대폰
              </td>
              <td className="tableListTitle" width={"10%"}>
                신청현황
              </td>
            </tr>
            {tabState === "D" &&
              listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{schedule.city}</td>
                  <td className="tableListTitle">
                    {format(schedule.startDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.jobPosition}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
            {tabState === "B" &&
              listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{schedule.city}</td>
                  <td className="tableListTitle">
                    {format(schedule.startDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.jobPosition}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TeacherReservationList;
