import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

const BannerStateDetail = () => {
  const { page } = useParams();
  const [banner, _banner] = useState();
  const [viewList, _viewList] = useState([]);
  const [clickList, _clickList] = useState([]);
  const [tabState, _tabState] = useState("V");

  const getHtml = async () => {
    try {
      const mode = "info";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("id", page);
      const response = await axios.post(
        config.URL + "bannerState.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _banner(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getView = async () => {
    try {
      const mode = "view";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("id", page);
      const response = await axios.post(
        config.URL + "bannerState.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _viewList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getClick = async () => {
    try {
      const mode = "click";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("id", page);
      const response = await axios.post(
        config.URL + "bannerState.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _clickList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
    getView();
    getClick();
  }, [page]);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>배너 정보
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">제목</td>
              <td>{banner?.title}</td>
              <td className="tableTitle">사용여부</td>
              <td>{banner?.hidden}</td>
              <td className="tableTitle" rowSpan={2}>
                배너이미지
              </td>
              <td rowSpan={2}>
                <img
                  src={config.FILEURL + banner?.img}
                  alt="미리보기"
                  style={{ width: "100px", height: "100px" }}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">시작일</td>
              <td>{banner?.startDate}</td>
              <td className="tableTitle">종료일</td>
              <td>{banner?.endDate}</td>
            </tr>
            <tr>
              <td className="tableTitle">링크</td>
              <td colSpan={3}>{banner?.link}</td>
            </tr>
          </table>
          <ul
            className="tabStateUl"
            style={{ width: "1060px", marginBottom: "30px" }}
          >
            <li
              className={tabState === "V" ? "tabOn" : ""}
              onClick={() => {
                _tabState("V");
              }}
            >
              조회수
            </li>
            <li
              className={tabState === "C" ? "tabOn" : ""}
              onClick={() => {
                _tabState("C");
              }}
            >
              클릭수
            </li>
          </ul>
          {tabState === "V" && (
            <>
              <div className="substance" style={{ marginTop: "50px" }}>
                <h2>
                  <span></span>배너 조회수 목록
                </h2>
              </div>
              <table className="tableList">
                <tr style={{ backgroundColor: "#f0f0f0" }}>
                  <td className="tableListTitle" width="7%">
                    순서
                  </td>
                  <td className="tableListTitle">조회수</td>
                  <td className="tableListTitle">조회IP</td>
                  <td className="tableListTitle">조회일시</td>
                </tr>
                {viewList &&
                  viewList.map((view, index) => (
                    <tr key={index}>
                      <td className="tableListTitle">
                        {viewList.length - index}
                      </td>
                      <td className="tableListTitle">{view.showCount}</td>
                      <td className="tableListTitle">{view.clickIP}</td>
                      <td className="tableListTitle">{view.createDate}</td>
                    </tr>
                  ))}
              </table>
            </>
          )}
          {tabState === "C" && (
            <>
              <div className="substance" style={{ marginTop: "50px" }}>
                <h2>
                  <span></span>배너 클릭수 목록
                </h2>
              </div>
              <table className="tableList">
                <tr style={{ backgroundColor: "#f0f0f0" }}>
                  <td className="tableListTitle" width="7%">
                    순서
                  </td>
                  <td className="tableListTitle">클릭수</td>
                  <td className="tableListTitle">클릭IP</td>
                  <td className="tableListTitle">클릭ID</td>
                  <td className="tableListTitle">클릭일자</td>
                </tr>
                {clickList &&
                  clickList.map((click, index) => (
                    <tr key={index}>
                      <td className="tableListTitle">{index + 1}</td>
                      <td className="tableListTitle">{click.clickCount}</td>
                      <td className="tableListTitle">{click.clickIP}</td>
                      <td className="tableListTitle">{click.userID}</td>
                      <td className="tableListTitle">{click.createDate}</td>
                    </tr>
                  ))}
              </table>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BannerStateDetail;
