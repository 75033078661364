import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Select from "react-select";
import config from "../config/config";

const TeacherExpoModal = ({ isOpen, onClose, startDate, local, city, id }) => {
  const [scheduleSubmit, _scheduleSubmit] = useState(1);
  const [schoolName, _schoolName] = useState([]);
  const [highschool, _highschool] = useState();
  const [teacherData, _teacherData] = useState([
    { teacherName: "", jobPosition: "3학년부장", phone: "" },
  ]);

  const [options, setOptions] = useState([]);
  const [scheduleTitle, setScheduleTitle] = useState(null);
  const [school, setSchool] = useState(null);

  console.log(startDate, local, city, id);

  const getSchoolList = async () => {
    try {
      const mode = "schoollist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "expo.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _schoolName(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (schoolName) {
      const mappedOptions = schoolName.map((list) => ({
        value: list.area,
        label: "(" + list.area + ")" + list.schoolName,
      }));
      setOptions(mappedOptions);
    }
  }, [schoolName]);

  const handleChange = (value) => {
    console.log("value:", value);
    if (value) {
      setSchool(value);
      setScheduleTitle("(" + value.value + ")" + value.label);
      _schoolName(value.label);
    }
  };

  const postData = async () => {
    try {
      const mode = "scheduleadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", highschool);
      formData.append("teacherData", JSON.stringify(teacherData));
      formData.append("startDate", startDate);
      formData.append("local", local);
      formData.append("city", city);
      formData.append("id", id);

      console.log(teacherData);
      const response = await axios.post(config.URL + "expo.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      // getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const nextStep = () => {
    if (schoolName == null) {
      alert("학교를 선택해 주세요.");
      return;
    }
    if (teacherData.some((input) => input.teacherName === "")) {
      alert("모든 성명을 입력해 주세요.");
      return;
    }
    if (teacherData.some((input) => input.jobPosition === "")) {
      alert("모든 직책을 선택해 주세요.");
      return;
    }
    if (teacherData.some((input) => input.phone === "")) {
      alert("모든 휴대폰번호를 입력해 주세요.");
      return;
    }
    _scheduleSubmit(scheduleSubmit + 1);
    postData();
  };

  const addTeacher = () => {
    _teacherData([
      ...teacherData,
      { teacherName: "", jobPosition: "3학년부장", phone: "" },
    ]);
  };

  const inputChange = (index, field, value) => {
    const updatedData = [...teacherData];
    updatedData[index][field] = value;
    _teacherData(updatedData);
  };

  const deleteTeacher = (index) => {
    if (teacherData.length > 1) {
      const updatedData = teacherData.filter((_, i) => i !== index);
      _teacherData(updatedData);
    }
  };

  //핸드폰 번호 입력 시 - 추가 기능
  const mobileNoChange = (index, event) => {
    const input = event.target.value.replace(/\D/g, "");

    let formattedInput = "";

    formattedInput = input
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/-{1,2}$/g, "");

    inputChange(index, "phone", formattedInput);
  };

  useEffect(() => {
    // getHtml();
    getSchoolList();
  }, []);

  return (
    <form>
      {scheduleSubmit == 1 && (
        <div className="overlay">
          <div
            className={`teacherExpoModal ${isOpen ? "" : "hidden"}`}
            style={{ zIndex: 200 }}
          >
            <img src="/assets/common/x.png" onClick={onClose} alt="img" />
            <h1>설명회 신청</h1>
            <div className="modalInfoBox">
              <h2>
                <span>
                  개최일시: <strong>{format(startDate, "MM월 dd일")}</strong>
                </span>
                <span style={{ paddingLeft: "50px" }}>
                  개최지역: <strong>{city}</strong>
                </span>
              </h2>
            </div>

            <div className="modalContent">
              <div className="modalSub" style={{ width: "500px" }}>
                <h3>고등학교 명</h3>
                <Select
                  value={school}
                  onChange={handleChange}
                  options={options}
                />
              </div>
            </div>

            <div className="addTeacher">
              <h3>신청교사 정보</h3>
              {teacherData.map((teacher, index) => (
                <div className="modalContent">
                  <div
                    key={index}
                    className="modalSub"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                      height: "30px",
                    }}
                  >
                    <input
                      type="text"
                      readOnly="true"
                      value={index + 1}
                      style={{
                        width: "10px",
                        backgroundColor: "#fff",
                        border: "0",
                      }}
                    ></input>
                    <input
                      type="text"
                      placeholder="교사 성명"
                      value={teacher.teacherName}
                      onChange={(e) =>
                        inputChange(index, "teacherName", e.target.value)
                      }
                    />
                    <select
                      value={teacher.jobPosition}
                      onChange={(e) =>
                        inputChange(index, "jobPosition", e.target.value)
                      }
                    >
                      <option value="3학년부장">3학년부장</option>
                      <option value="3학년담임">3학년담임</option>
                      <option value="진로진학부장">진로진학부장</option>
                      <option value="기타">기타</option>
                    </select>
                    <input
                      type="text"
                      placeholder="휴대폰번호를 입력해주세요"
                      value={teacher.phone}
                      onChange={(e) => {
                        mobileNoChange(index, e);
                      }}
                      style={{ width: "170px" }}
                    />
                    <div className="modalAddBtn" style={{ borderBottom: "0" }}>
                      <button
                        type="button"
                        onClick={() => deleteTeacher(index)}
                        style={{
                          padding: "0",
                          margin: "9px 0 0 0",
                        }}
                      >
                        - 삭제
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="modalAddBtn">
              <button type="button" onClick={addTeacher}>
                +추가하기
              </button>
            </div>
            <button type="button" className="submitBtn" onClick={nextStep}>
              신청하기
            </button>
          </div>
        </div>
      )}
      {scheduleSubmit == 2 && (
        <div className="overlay">
          <div
            className={'completePage ${isOpen ? "" : "hidden"}'}
            style={{ zIndex: 200 }}
          >
            <img src="/assets/common/x.png" onClick={onClose} />
            <h1>
              신청이 완료되었습니다.
              <br />
              신청해 주셔서 감사합니다.
            </h1>
            <h2>
              신청내역은 마이페이지에서
              <br />
              확인 하실 수 있습니다.
            </h2>
          </div>
        </div>
      )}
    </form>
  );
};

export default TeacherExpoModal;
