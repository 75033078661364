import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "./config";

const PrivacyPoliceModal = ({ isOpen, onClose }) => {
  const [policy, _policy] = useState([]);

  const getHtml = async () => {
    try {
      const mode = "privacypolice";
      const response = await axios.post(config.URL + "homepage.php", { mode });
      console.log(response);
      _policy(response.data.data.polish);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, []);

  return (
    <>
      <div className="overlay" onClick={onClose}>
        <div
          className={`teacherExpoModal ${isOpen ? "" : "hidden"}`}
          style={{ width: "800px", fontSize: "12px" }}
          onClick={(e) => e.stopPropagation()}
        >
          <img src="/assets/common/x.png" onClick={onClose} alt="close" />
          <h2>개인정보처리방침</h2>
          <span
            style={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{ __html: policy }}
          ></span>
        </div>
      </div>
    </>
  );
};

export default PrivacyPoliceModal;
