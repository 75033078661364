import { configureStore, createSlice } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// 초기 상태 정의
const initialState = {
    user: null, 
    isLoggedIn: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    login: (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
    },
    logout: (state) => {
        state.user = null;
        state.isLoggedIn = false;
    },
    },
});

export const { login, logout } = authSlice.actions;

const persistConfig = {
    key: 'auth',
    storage,
};

const persistedReducer = persistReducer(persistConfig, authSlice.reducer);

const store = configureStore({
    reducer: {
        auth: persistedReducer,
    },
});

const persistor = persistStore(store);

export { store, persistor };
