import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuRight from "../../component/quickMenuR";
import config from "../../config/config";
import "../../css/company.scss";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const CompanyMain = () => {
  const { page } = useParams();
  const [tabState, _tabState] = useState("history");
  const [history, _history] = useState([]);
  const [greeting, _greeting] = useState("");

  const getHtml = async () => {
    try {
      const mode = "history";
      const response = await axios.post(`${config.URL}/company.php`, { mode });
      console.log(response);
      _history(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getHtml2 = async () => {
    try {
      const mode = "greeting";
      const response = await axios.post(`${config.URL}/company.php`, { mode });
      console.log(response);
      _greeting(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    page === "history"
      ? _tabState("history")
      : page === "greeting"
      ? _tabState("greeting")
      : _tabState("member");
  }, [page]);

  useEffect(() => {
    getHtml();
    getHtml2();
  }, []);

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuRight />
      <div className="contents">
        <div className="companyLayout">
          <img src="/assets/Company/CompanyBanner.png" alt="banner" />
          <ul className="companyUl">
            <li
              className={tabState === "history" ? "tabOn" : ""}
              onClick={() => {
                _tabState("history");
              }}
            >
              연혁
            </li>
            <li
              className={tabState === "greeting" ? "tabOn" : ""}
              onClick={() => {
                _tabState("greeting");
              }}
            >
              대표자인사말
            </li>
            <li
              className={tabState === "member" ? "tabOn" : ""}
              onClick={() => {
                _tabState("member");
              }}
            >
              조직도
            </li>
          </ul>
          {tabState === "history" && (
            <div className="historyLayout" tabState="history">
              <>
                {(() => {
                  const groupedHistoryObject = {};
                  history.forEach((item) => {
                    if (!groupedHistoryObject[item.year]) {
                      groupedHistoryObject[item.year] = {
                        year: item.year,
                        items: [],
                      };
                    }
                    groupedHistoryObject[item.year].items.push(item);
                  });

                  const groupedHistory = Object.values(
                    groupedHistoryObject
                  ).sort((a, b) => b.year - a.year);
                  // console.log("groupedHistoryObject", groupedHistory);
                  return Object.values(groupedHistory).map((group, index) => (
                    <>
                      <div
                        key={group.year}
                        className={
                          index % 2 === 0 ? "historyTabR" : "historyTabL"
                        }
                        data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-offset="200"
                        data-aos-once="true"
                      >
                        {index % 2 !== 0 && (
                          <ul
                            data-aos="fade-right"
                            data-aos-duration="800"
                            data-aos-offset="300"
                          >
                            {group.items.map((item) => (
                              <li
                                key={`${item.year}-${item.month}-${item.day}`}
                              >
                                {item.content}{" "}
                                <strong>
                                  {item.year}.{item.month}.{item.day}
                                </strong>
                              </li>
                            ))}
                          </ul>
                        )}
                        <div className="historyYear">
                          {group.year}
                          <br />~
                        </div>
                        {index % 2 === 0 && (
                          <ul
                            data-aos="fade-left"
                            data-aos-duration="800"
                            data-aos-offset="300"
                          >
                            {group.items.map((item) => (
                              <li
                                key={`${item.year}-${item.month}-${item.day}`}
                              >
                                <strong>
                                  {item.year}.{item.month}.{item.day}
                                </strong>{" "}
                                {item.content}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <hr className="dashLine"></hr>
                    </>
                  ));
                })()}
              </>
            </div>
          )}
          {tabState === "greeting" && (
            <div className="greetingLayout" tabState="greeting">
              <div
                className="imgBox"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-anchor="#imgBox"
              >
                <img
                  src={config.FILEURL + greeting.img}
                  alt="ceo이미지"
                  width={"300px"}
                  height={"360px"}
                  style={{ objectFit: "cover" }}
                />
                {/* <div
                  className="nameBox"
                  data-aos="flip-down"
                  data-aos-duration="600"
                  data-aos-delay="400"
                  data-aos-once="true"
                  id="nameBox"
                >
                  <h1>CEO</h1>
                  <h2>{greeting.ceoName}</h2>
                </div> */}
              </div>
              <div
                className="greetingBox"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div dangerouslySetInnerHTML={{ __html: greeting.content }} />
              </div>
            </div>
          )}
          {tabState === "member" && (
            <div tabState="member">
              <img
                src="/assets/company/member.png"
                style={{ paddingTop: "50px", backgroundColor: "#fff" }}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CompanyMain;
