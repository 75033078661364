import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Welcome from "../../assets/member/welcomeJoinUs.png";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const Join = () => {
  //스탭박스
  const [tabState, _tabState] = useState("agreement");

  //약관동의
  const [checkedInputs, setCheckedInputs] = useState([]);

  //회원정보 기입
  const [id, _id] = useState();
  const [password, _password] = useState();
  const [passwordCheck, _passwordCheck] = useState();
  const [userType, _userType] = useState();
  const [name, _name] = useState();
  const [nickName, _nickName] = useState();
  const [phone, _phone] = useState();
  const [phone2, _phone2] = useState();
  const [phone3, _phone3] = useState();
  const [localPhone, _localPhone] = useState();
  const [localPhone2, _localPhone2] = useState();
  const [localPhone3, _localPhone3] = useState();
  const [email, _email] = useState();
  const [confirmPasswordLabel, _confirmPasswordLabel] = useState("");

  //약관 가져오기
  const [policy, _policy] = useState([]);
  const [agreement, _agreement] = useState([]);

  const getPolicy = async () => {
    try {
      const mode = "privacypolice";
      const response = await axios.post(config.URL + "homepage.php", { mode });
      console.log(response);
      _policy(response.data.data.polish);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAgreement = async () => {
    try {
      const mode = "agreement";
      const response = await axios.post(config.URL + "homepage.php", { mode });
      console.log(response);
      _agreement(response.data.data.agreement);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPolicy();
    getAgreement();
  }, []);

  //오늘 날자 구하기
  const now = new Date();
  //날자 가져오기
  const [birth, _birth] = useState({
    year: "2025",
    month: "01",
    day: "01",
  });
  //날자 계산
  let years = [];
  for (let y = now.getFullYear(); y >= 1930; y -= 1) {
    years.push(y);
    // console.log(y)
  }
  let month = [];
  for (let m = 1; m <= 12; m += 1) {
    if (m < 10) {
      month.push("0" + m.toString());
    } else {
      month.push(m.toString());
    }
  }
  let days = [];
  let date = new Date(birth.year, birth.month, 0).getDate();
  for (let d = 1; d <= date; d += 1) {
    if (d < 10) {
      days.push("0" + d.toString());
    } else {
      days.push(d.toString());
    }
  }

  //약관동의 선택여부
  const onCheckHandler = useCallback((checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      setCheckedInputs(checkedInputs.filter((el) => el !== id));
    }
  });

  //약관동의 체크
  const policyCheck = () => {
    if (
      checkedInputs.includes("usingListCheck") &&
      checkedInputs.includes("personalInfoCheck")
    ) {
      _tabState("inputMember");
    } else {
      alert("필수약관에 동의해 주세요.");
    }
  };

  const passwordCheckSma = () => {
    if (password != passwordCheck) {
      _confirmPasswordLabel("비밀번호가 일치 하지 않습니다다");
      return;
    } else if (password === passwordCheck) {
      _confirmPasswordLabel("비밀번호가 일치 합니다.");
    }
  };

  //회원정보 체크
  const join = async () => {
    if (!id) {
      alert("아이디를 입력해주세요");
      return;
    }
    if (!password) {
      alert("비밀번호를 입력해주세요");
      return;
    }
    if (!passwordCheck) {
      alert("비밀번호를 입력해주세요");

      return;
    }

    if (!userType) {
      alert("회원등급을 선택해주세요");
      return;
    }
    if (!name) {
      alert("성명을 입력해주세요");
      return;
    }
    if (!phone) {
      alert("연락처를 입력해주세요");
      return;
    }
    if (!email) {
      alert("이메일을 입력해주세요");
      return;
    }

    try {
      const data = {
        id: id,
        password: password,
        userType: userType,
        name: name,
        nickName: nickName,
        birth: birth.year + birth.month + birth.day,
        phone: phone + phone2 + phone3,
        localPhone: localPhone + localPhone2 + localPhone3,
        email: email,
      };
      const result = await axios.post(
        "http://biyeun2334.cafe24.com/controller/join.php",
        data,
        { headers: { "Content-Type": "application/json" } }
      );

      if (result) {
        _tabState("complete");
      } else {
        alert("가입에 실패했습니다 );");
        return;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Header type="simple" />
      <div className="joinLayout">
        <h2>회원가입</h2>
        <ul className="joinStep">
          <li className={tabState === "agreement" ? "tabOn" : ""}>이용약관</li>
          <li className={tabState === "inputMember" ? "tabOn" : ""}>
            회원정보 등록
          </li>
          <li className={tabState === "complete" ? "tabOn" : ""}>가입 완료</li>
        </ul>
        {tabState === "agreement" && (
          <>
            <div className="loginInfo">
              <h2>약관동의</h2>
              <div className="agreementBox">
                <h3>이용약관</h3>
                <span
                  className="agreementCont"
                  dangerouslySetInnerHTML={{ __html: agreement }}
                ></span>
                <div className="agreeCheck">
                  <input
                    type="checkbox"
                    id="usingListCheck"
                    onChange={(e) => {
                      onCheckHandler(e.currentTarget.checked, "usingListCheck");
                    }}
                    checked={
                      checkedInputs.includes("usingListCheck") ? true : false
                    }
                  />
                  <label>
                    <font color="#df0000">(필수)</font>약관에 동의합니다.
                  </label>
                </div>
              </div>
              <div className="agreementBox">
                <h3>개인정보처리방침</h3>
                <span
                  className="agreementCont"
                  dangerouslySetInnerHTML={{ __html: policy }}
                ></span>
                <div className="agreeCheck">
                  <input
                    type="checkbox"
                    id="personalInfoCheck"
                    onChange={(e) => {
                      onCheckHandler(
                        e.currentTarget.checked,
                        "personalInfoCheck"
                      );
                    }}
                    checked={
                      checkedInputs.includes("personalInfoCheck") ? true : false
                    }
                  />
                  <label>
                    <font color="#df0000">(필수)</font>약관에 동의합니다.
                  </label>
                </div>
              </div>
            </div>
            <div className="nextBox">
              <button type="button" onClick={policyCheck}>
                동의
              </button>
            </div>
          </>
        )}
        {tabState === "inputMember" && (
          <>
            <div className="loginInfo">
              <h2>로그인 정보</h2>
              <div className="joinInput">
                <h3>
                  아이디 <font color="#df0000">*</font>
                </h3>
                <input
                  type="text"
                  id="id"
                  value={id}
                  onChange={(e) => _id(e.target.value)}
                />
                <button>중복확인</button>
              </div>
              <div className="joinInput">
                <h3>
                  비밀번호 <font color="#df0000">*</font>
                </h3>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => _password(e.target.value)}
                />
              </div>
              <div className="joinInput">
                <h3>
                  비밀번호 확인 <font color="#df0000">*</font>
                </h3>
                <input
                  type="password"
                  value={passwordCheck}
                  onChange={(e) => {
                    _passwordCheck(e.target.value);
                    passwordCheckSma();
                  }}
                />
                <label style={{ color: "red" }}>{confirmPasswordLabel}</label>
              </div>
            </div>
            <div className="loginInfo">
              <h2>회원 정보</h2>
              <div className="joinInput">
                <h3>
                  회원등급 <font color="#df0000">*</font>
                </h3>
                <input
                  type="radio"
                  className="memberTypeInput"
                  name="userType"
                  value="M"
                  onChange={(e) => _userType(e.target.value)}
                />
                일반회원 &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  className="memberTypeInput"
                  name="userType"
                  value="S"
                  onChange={(e) => _userType(e.target.value)}
                />
                학생 &nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  className="memberTypeInput"
                  name="userType"
                  value="T"
                  onChange={(e) => _userType(e.target.value)}
                />
                교사
              </div>
              <div className="joinInput">
                <h3>
                  이름 <font color="#df0000">*</font>
                </h3>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => _name(e.target.value)}
                />
              </div>
              <div className="joinInput">
                <h3>닉네임</h3>
                <input
                  type="text"
                  value={nickName}
                  onChange={(e) => _nickName(e.target.value)}
                />
              </div>
              <div className="joinInput">
                <h3>
                  생년월일 <font color="#df0000">*</font>
                </h3>
                <select
                  value={birth.year}
                  onChange={(e) => _birth({ ...birth, year: e.target.value })}
                  style={{ width: "90px", margin: "0" }}
                >
                  {years.map((item) => (
                    <option value={item} key={item}>
                      {" "}
                      {item}{" "}
                    </option>
                  ))}
                </select>
                년
                <select
                  value={birth.month}
                  onChange={(e) => _birth({ ...birth, month: e.target.value })}
                >
                  {month.map((item) => (
                    <option value={item} key={item}>
                      {" "}
                      {item}{" "}
                    </option>
                  ))}
                </select>
                월
                <select
                  value={birth.day}
                  onChange={(e) => _birth({ ...birth, day: e.target.value })}
                >
                  {days.map((item) => (
                    <option value={item} key={item}>
                      {" "}
                      {item}{" "}
                    </option>
                  ))}
                </select>
                일
              </div>
              <div className="joinInput">
                <h3>
                  휴대폰 <font color="#df0000">*</font>
                </h3>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => _phone(e.target.value)}
                  style={{ width: "35px" }}
                />
                -
                <input
                  type="text"
                  value={phone2}
                  onChange={(e) => _phone2(e.target.value)}
                  style={{ width: "50px" }}
                />
                -
                <input
                  type="text"
                  value={phone3}
                  onChange={(e) => _phone3(e.target.value)}
                  style={{ width: "50px" }}
                />
              </div>
              <div className="joinInput">
                <h3>전화번호</h3>
                <input
                  type="text"
                  value={localPhone}
                  onChange={(e) => _localPhone(e.target.value)}
                  style={{ width: "35px" }}
                />
                -
                <input
                  type="text"
                  value={localPhone2}
                  onChange={(e) => _localPhone2(e.target.value)}
                  style={{ width: "50px" }}
                />
                -
                <input
                  type="text"
                  value={localPhone3}
                  onChange={(e) => _localPhone3(e.target.value)}
                  style={{ width: "50px" }}
                />
              </div>
              <div className="joinInput">
                <h3>
                  이메일 <font color="#df0000">*</font>
                </h3>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => _email(e.target.value)}
                />
              </div>
            </div>
            <div className="nextBox">
              <button
                type="button"
                onClick={() => {
                  _tabState("agreement");
                }}
                style={{ backgroundColor: "#fff", color: "#333" }}
              >
                뒤로
              </button>
              <button type="button" onClick={join}>
                가입
              </button>
            </div>
          </>
        )}
        {tabState === "complete" && (
          <>
            <div className="completeBox">
              <h1>아이앤유니 가입을 환영합니다!</h1>
              <h2>
                회원가입이 완료되었습니다.
                <br />
                로그인 후 서비스를 이용해 주세요.
              </h2>
              <img src={Welcome} />
              <div className="completeBtn">
                <Link to="/" style={{ backgroundColor: "#fff", color: "#333" }}>
                  홈으로 이동
                </Link>
                <Link to="/Login">로그인</Link>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Join;
