import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../config/config";

const IpsiBoard = () => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [tabState, _tabState] = useState("seoul");

  const getBBS = async () => {
    try {
      const mode = "bbslist";
      const bbsType = "N";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getBBS();
  }, [page]);

  return (
    <div className="ipsiNewsBox">
      <div className="ipsiNewsTitle">
        <img src="/assets/ipsi/ipsiNewsIcon1.png" alt="ipsiNewsIcon1" />
        <h2>입시뉴스</h2>
      </div>
      <div className="ipsiGallery">
        {listdata &&
          listdata.map((bbs, index) => (
            <Link
              to="/boardview/N"
              state={{ data: bbs }}
              key={index}
              className="ipsiGalleryHeadline"
            >
              <img src={config.FILEURL + bbs.img} alt={index} />
              <h2>{bbs.title}</h2>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default IpsiBoard;
