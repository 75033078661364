import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../css/member.scss";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import { login } from "../../store/store";
import config from "./../../config/config";

const Login = () => {
  const [id, _id] = useState("");
  const [password, _password] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginProcess = async () => {
    if (!id) {
      alert("아이디를 입력해 주세요");
      return;
    }
    if (!password) {
      alert("비밀번호를 입력해 주세요");
      return;
    }
    try {
      const data = { mode: "login", id, password };
      const response = await axios.post(`${config.URL}/login.php`, data, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.data.status === "success") {
        dispatch(login(response.data.user));
        navigate(response.data.user.userType === "A" ? "/admin_main" : "/");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("로그인 요청 중 오류 발생:", error);
      alert("로그인 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const checkEnter = (e) => {
    if (e.key === "Enter") {
      loginProcess();
    }
  };

  return (
    <>
      <div className="bodySection">
        <Header type="simple" />
        <div className="loginLayout">
          <h2>로그인</h2>
          <div className="input_itemId">
            <input
              type="text"
              id="id"
              value={id}
              onChange={(e) => _id(e.target.value)}
              onKeyDown={checkEnter}
              placeholder="아이디를 입력해주세요"
            />
          </div>
          <div className="input_itemPassword">
            <input
              type="password"
              value={password}
              onChange={(e) => _password(e.target.value)}
              onKeyDown={checkEnter}
              placeholder="비밀번호를 입력해주세요"
            />
          </div>
          <div className="login_keep_wrap"></div>
          <div className="login_submit">
            <button type="submit" onClick={loginProcess}>
              로그인
            </button>
          </div>
          <Link to="/Join" className="join_member">
            회원가입
          </Link>
          <ul className="find_memberInfo">
            <li>
              <Link to="/FindID">아이디 찾기</Link>
            </li>
            <span className="txt_bar" />
            <li>
              <Link to="/FindID">비밀번호 찾기</Link>
            </li>
          </ul>
        </div>
        <Footer type="login" />
      </div>
    </>
  );
};

export default Login;
