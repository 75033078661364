import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenu from "../../component/quickMenu";
import QuickMenuR from "../../component/quickMenuR";
import "./../../css/exposition.scss";
import "./../../css/main.scss";
import Footer from "./../../layout/footer";
import Header from "./../../layout/header";

const TeacherExpo = () => {
  const [localUnivList, _localUnivList] = useState([]);

  //지역 별 대학리스트 불러오기
  const getLocalUnivList = async () => {
    try {
      const response = await axios.get(URL + "collegeList.php?local=");
      _localUnivList(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getLocalUnivList();
  }, []);

  return (
    <div>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <div className="expoBannerBox">
        <div className="expoBanner">
          <h1>교사대상 설명회</h1>
          <h2>
            2025학년도 아이앤유니와 함께하는
            <br />
            전국 13개 대학교 연합 교사대상 설명회
          </h2>
        </div>
        <img
          src="/assets/exposition/teacherExpoBanner.png"
          data-aos="fade-left"
          data-aos-duration="1000"
        />
      </div>
      <div className="teacherExpoContent">
        <Link to="/TeacherExpoDJ" className="localContent1">
          <h3>
            대전지역 국·공립 대학교
            <br />
            연합 설명회
          </h3>
          <div className="univContent">
            {localUnivList.forEach((college) => {
              <img src={college.img} />;
            })}
            <img src="/assets/collegeLogo/KonYang.png" />
            <img src="/assets/collegeLogo/GongJu.png" />
            <img src="/assets/collegeLogo/HanBat.png" />
            <img src="/assets/collegeLogo/Woosong.png" />
            <img src="/assets/collegeLogo/KoreaTech.png" />
          </div>
        </Link>
        <Link to="/TeacherExpoBS" className="localContent2">
          <h3>
            부산·울산·경남지역 대학교
            <br />
            연합 설명회
          </h3>
          <div className="univContent">
            <img src="/assets/collegeLogo/KyunSung.png" />
            <img src="/assets/collegeLogo/TongMyong.png" />
            <img src="/assets/collegeLogo/DongSeo.png" />
            <img src="/assets/collegeLogo/BuSan.png" />
            <img src="/assets/collegeLogo/SilLa.png" />
            <img src="/assets/collegeLogo/YoungSan.png" />
            <img src="/assets/collegeLogo/InJe.png" />
            <img src="/assets/collegeLogo/UlSan.png" />
          </div>
        </Link>
      </div>
      <QuickMenu type="margin" />
      <Footer />
    </div>
  );
};

export default TeacherExpo;
