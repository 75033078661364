import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

const BannerState = () => {
  const [listdata, _listdata] = useState([]);
  const [tabState, _tabState] = useState("A");

  const getHtml = async () => {
    try {
      const mode = "totallist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(
        config.URL + "bannerState.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, [tabState]);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>배너 통계 목록
            </h2>
          </div>
          <ul
            className="tabStateUl"
            style={{ width: "1060px", marginBottom: "30px" }}
          >
            <li
              className={tabState === "A" ? "tabOn" : ""}
              onClick={() => {
                _tabState("A");
              }}
            >
              전체
            </li>
            <li
              className={tabState === "T" ? "tabOn" : ""}
              onClick={() => {
                _tabState("T");
              }}
            >
              상단배너
            </li>
            <li
              className={tabState === "W" ? "tabOn" : ""}
              onClick={() => {
                _tabState("W");
              }}
            >
              본문 배너
            </li>
            <li
              className={tabState === "L" ? "tabOn" : ""}
              onClick={() => {
                _tabState("L");
              }}
            >
              왼쪽 배너
            </li>
          </ul>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width="7%">
                순서
              </td>
              <td className="tableListTitle">배너위치</td>
              <td className="tableListTitle">배너제목</td>
              <td className="tableListTitle">베너이미지</td>
              <td className="tableListTitle">노출수</td>
              <td className="tableListTitle">클릭수</td>
              <td className="tableListTitle">상세</td>
            </tr>
            {listdata &&
              listdata.map((user, index) => (
                <tr key={index}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{user.position}</td>
                  <td className="tableListTitle">{user.title}</td>
                  <td className="tableListTitle">
                    {user.link ? (
                      <a href={user.link} target="_blank" rel="noreferrer">
                        <img
                          src={config.FILEURL + user.img}
                          alt="banner img"
                          style={{ width: "100px", height: "50px" }}
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="tableListTitle">
                    {user.show > 0 ? user.show : 0}
                  </td>
                  <td className="tableListTitle">
                    {user.click > 0 ? user.click : 0}
                  </td>

                  <td className="tableListTitle">
                    {user.show > 0 || user.click > 0 ? (
                      <Link to={`/bannerstatedetail/${user.id}`}>상세보기</Link>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BannerState;
