import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config/config";
import "../css/commercial.scss";

const CollegeLogoBanner = (props) => {
  const [listdata, _listdata] = useState([]);

  const getHtml = async () => {
    try {
      const mode = "rollingunivlist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(
        config.URL + "rollingUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, []);

  return (
    <section className="d-flex flex-column vh-100 justify-content-center align-items-center">
      <div className="container brandsCarousel">
        <div
          className="d-flex carouselTrack marquee_wrap"
          style={{
            "--carousel-width": `calc(200px * ${listdata.length})`,
          }}
        >
          {listdata &&
            listdata.map((univ, index) => (
              <div className="brandLogo marquee_box box1" key={index}>
                <a href={univ.link} target="_blank" rel="noreferrer">
                  <img
                    src={config.FILEURL + univ.logo}
                    onClick={() => window.open(`${univ.link}`, "_blank")}
                    alt="ui"
                  />
                </a>
              </div>
            ))}
          {listdata &&
            listdata.map((univ, index) => (
              <div className="brandLogo marquee_box box2" key={index}>
                <a href={univ.link} target="_blank" rel="noreferrer">
                  <img
                    src={config.FILEURL + univ.logo}
                    onClick={() => window.open(`${univ.link}`, "_blank")}
                    alt="ui"
                  />
                </a>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default CollegeLogoBanner;
