import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config/config";
import "./../../css/mypage.scss";

const Mypage = () => {
  const user = useSelector((state) => state.auth.user);
  const [id, _id] = useState("");
  const [userID, _userID] = useState("");
  const [name, _name] = useState("");
  const [nickName, _nickName] = useState("");
  const [email, _email] = useState("");
  const [phone, _phone] = useState("");
  const [localPhone, _localPhone] = useState("");
  const [userType, _userType] = useState("");
  const [createDate, _createDate] = useState("");
  const [birth, _birth] = useState({
    year: "2025",
    month: "01",
    day: "01",
  });

  const getHtml = async () => {
    try {
      const mode = "userinfo";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("userID", user.userID);
      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _id(response.data.data.id);
      _userID(response.data.data.userID);
      _name(response.data.data.name);
      _nickName(response.data.data.nickName);
      _email(response.data.data.email);
      _phone(response.data.data.phone);
      _localPhone(response.data.data.localPhone);
      _userType(response.data.data.userType);
      _createDate(response.data.data.createDate);
      _birth.year(response.data.data.birth.subStr(0,4));
      _birth.month(response.data.data.birth.subStr(5,6));
      _birth.day(response.data.data.birth.subStr(7,8));
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateData = async () => {
    try {
      const mode = "userupdate";
      const birthday = birth.year + birth.month + birth.day;
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("nickName", nickName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("localPhone", localPhone);
      formData.append("userType", userType);
      formData.append("birth", birthday);
      formData.append("id", id);

      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      alert("회원정보 수정이 완료되었습니다.");
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  //오늘 날자 구하기
  const now = new Date();
  //날자 가져오기
 
  //날자 계산
  let years = [];
  for (let y = now.getFullYear(); y >= 1930; y -= 1) {
    years.push(y);
    // console.log(y)
  }
  let month = [];
  for (let m = 1; m <= 12; m += 1) {
    if (m < 10) {
      month.push("0" + m.toString());
    } else {
      month.push(m.toString());
    }
  }
  let days = [];
  let date = new Date(birth.year, birth.month, 0).getDate();
  for (let d = 1; d <= date; d += 1) {
    if (d < 10) {
      days.push("0" + d.toString());
    } else {
      days.push(d.toString());
    }
  }

  useEffect(() => {
    getHtml();
  }, []);

  return (
      <div className="mypageBox">
        <div className="substance">
          <h2>
            <span></span>회원정보 수정
          </h2>
        </div>
        <table className="mypageTable">
          <tr>
            <td className="tableTitle">아이디</td>
            <td>{userID}</td>
          </tr>
          <tr>
            <td className="tableTitle">이름</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td className="tableTitle">닉네임</td>
            <td>
              <input
                type="text"
                value={nickName}
                onChange={(e) => _nickName(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="tableTitle">이메일</td>
            <td>
              <input
                type="text"
                value={email}
                onChange={(e) => _email(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="tableTitle">생년월일</td>
            <td>
              <select
                value={birth.year}
                onChange={(e) => _birth({ ...birth, year: e.target.value })}
                style={{ width: "70px", marginLeft: "0" }}
              >
                {years.map((item) => (
                  <option value={item} key={item}>
                    {" "}
                    {item}{" "}
                  </option>
                ))}
              </select>
              년
              <select
                value={birth.month}
                onChange={(e) => _birth({ ...birth, month: e.target.value })}
              >
                {month.map((item) => (
                  <option value={item} key={item}>
                    {" "}
                    {item}{" "}
                  </option>
                ))}
              </select>
              월
              <select
                value={birth.day}
                onChange={(e) => _birth({ ...birth, day: e.target.value })}
              >
                {days.map((item) => (
                  <option value={item} key={item}>
                    {" "}
                    {item}{" "}
                  </option>
                ))}
              </select>
              일
            </td>
          </tr>
          <tr>
            <td className="tableTitle">휴대폰번호</td>
            <td>
              <input
                type="text"
                value={phone}
                onChange={(e) => _phone(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="tableTitle">전화번호</td>
            <td>
              <input
                type="text"
                value={localPhone}
                onChange={(e) => _localPhone(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="tableTitle">회원등급</td>
            <td>
              <input
                type="radio"
                name="userType"
                className="userTypeInput"
                value="M"
                onChange={(e) => _userType(e.target.value)}
                style={{ marginLeft: "0" }}
                checked={userType == "M" && "checked"}
              />
              일반회원
              <input
                type="radio"
                name="userType"
                className="userTypeInput"
                value="S"
                onChange={(e) => _userType(e.target.value)}
                checked={userType == "S" && "checked"}
              />
              학생
              <input
                type="radio"
                name="userType"
                className="userTypeInput"
                value="T"
                onChange={(e) => _userType(e.target.value)}
                checked={userType == "T" && "checked"}
              />
              교사
            </td>
          </tr>
          <tr>
            <td className="tableTitle">가입일</td>
            <td>{createDate.substring(0,10)}</td>
          </tr>
        </table>
        <div className="okQa" style={{ marginBottom: "50px" }}>
          <button onClick={updateData}>수정하기</button>
        </div>
      </div>
  );
};

export default Mypage;
