import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuR from "../../component/quickMenuR";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import "./../../css/mypage.scss";
import ChangePW from "./changePw";
import ExpoHistory from "./expoHistory";
import Mypage from "./myPage";
import PostHistory from "./postHistory";
import QaHistory from "./qaHistory";
import QuitMember from "./quitMember";

const MyPage = () => {
  const { page } = useParams();
  const [tabState, _tabState] = useState("");

  const validTabs = [
    "myPage",
    "changePw",
    "quit",
    "expoHistory",
    "postHistory",
    "qaHistory",
  ];

  useEffect(() => {
    const newTab = validTabs.includes(page) ? page : "myPage";
    _tabState(newTab);
  }, [page]);

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <img src="/assets/member/mypageBanner.png" className="communityBanner" />
      <ul className="boardUl">
        <li
          className={tabState === "myPage" ? "tabOn" : ""}
          onClick={() => {
            _tabState("myPage");
          }}
        >
          회원정보 수정
        </li>
        <li
          className={tabState === "changePw" ? "tabOn" : ""}
          onClick={() => {
            _tabState("changePw");
          }}
        >
          비밀번호 변경
        </li>
        <li
          className={tabState === "quit" ? "tabOn" : ""}
          onClick={() => {
            _tabState("quit");
          }}
        >
          회원 탈퇴
        </li>
        <li
          className={tabState === "expoHistory" ? "tabOn" : ""}
          onClick={() => {
            _tabState("expoHistory");
          }}
        >
          박람회/설명회 신청현황
        </li>
        <li
          className={tabState === "postHistory" ? "tabOn" : ""}
          onClick={() => {
            _tabState("postHistory");
          }}
        >
          내 게시글 관리
        </li>
        <li
          className={tabState === "qaHistory" ? "tabOn" : ""}
          onClick={() => {
            _tabState("qaHistory");
          }}
        >
          문의내역
        </li>
      </ul>
      {tabState === "myPage" && <Mypage />}
      {tabState === "changePw" && <ChangePW />}
      {tabState === "quit" && <QuitMember />}
      {tabState === "expoHistory" && <ExpoHistory />}
      {tabState === "postHistory" && <PostHistory />}
      {tabState === "qaHistory" && <QaHistory />}
      <Footer />
    </>
  );
};

export default MyPage;
