import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

const Greeting = () => {
  const [editor, _editor] = useState("");
  const [ceoName, _ceoName] = useState("");
  const [pic, _pic] = useState("");
  const [preview, _preview] = useState("");

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          "link",
          { indent: "-1" },
          { indent: "+1" },
        ],
        [
          {
            color: [
              "#000000",
              "#e60000",
              "#ff9900",
              "#ffff00",
              "#008a00",
              "#0066cc",
              "custom-color",
            ],
          },
          { background: [] },
        ],
        ["image", "video"],
      ],
    },
  };

  const getHtml = async () => {
    try {
      const mode = "ceogreeting";
      const response = await axios.post(config.URL + "homepage.php", { mode });
      console.log(response);
      _editor(response.data.data.content);
      _ceoName(response.data.data.ceoName);
      _preview(config.FILEURL + response.data.data.img);
    } catch (error) {
      console.log("error", error);
    }
  };

  const postHtml = async () => {
    try {
      const mode = "ceogreetingupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("namae", ceoName);
      formData.append("editor", editor);
      pic && formData.append("pic", pic);

      const response = await axios.post(config.URL + "homepage.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      alert(response.data.message);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, []);

  const EditorChange = (value) => {
    _editor(value);
  };

  const ImgFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      _pic(file);
      _preview(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>대표자 인사말
              <button type="button" onClick={postHtml}>
                저장
              </button>
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">CEO 사진</td>
              <td>
                {preview && (
                  <img
                    src={preview}
                    alt="CEO 미리보기"
                    style={{ width: "250px" }}
                  />
                )}
                <input type="file" accept="image/*" onChange={ImgFileChange} />
              </td>
              <td className="tableTitle">CEO이름</td>
              <td>
                <input
                  type="text"
                  value={ceoName}
                  onChange={(e) => _ceoName(e.target.value)}
                  style={{ width: "80%", border: "0" }}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">내용</td>
              <td colSpan={6} style={{ height: "290px" }}>
                <ReactQuill
                  value={editor}
                  style={{ width: "100%", height: "50vh" }}
                  modules={modules}
                  onChange={EditorChange}
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Greeting;
