import axios from "axios";
import React, { useState } from "react";
import config from "../../config/config";

import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const PostHistory = () => {
  const [listdata, _listdata] = useState([]);
  const [id, _id] = useState("");

  const getHtml = async () => {
    try {
      const mode = "memberlist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteData = async () => {
    try {
      const mode = "adbannerdel";
      const formData = new FormData();
      formData.append("id", id);
      formData.append("mode", mode);

      const response = await axios.post(config.URL + "adBanner.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
        <div className="mypageBox">
          <div className="substance">
            <h2>
              <span></span>내 게시글 관리
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width="6%">
                No.
              </td>
              <td className="tableListTitle" width="20%">
                게시판
              </td>
              <td className="tableListTitle" width="40%">
                제목
              </td>
              <td className="tableListTitle" width="15%">
                작성일
              </td>
              <td className="tableListTitle" width="7%">
                삭제
              </td>
            </tr>
            {listdata &&
              listdata.map((adTop, index) => (
                <tr>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{adTop.type}</td>
                  <td className="tableListTitle">{adTop.title}</td>
                  <td className="tableListTitle">
                    {format(adTop.createDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">
                    <button type="button" onClick={deleteData}>
                      삭제
                    </button>
                  </td>
                </tr>
              ))}
            <tr>
              <td className="tableListTitle">1</td>
              <td className="tableListTitle">학과별 게시판</td>
              <td className="tableListTitle">춘식이는 말랑말랑</td>
              <td className="tableListTitle">2025-02-27</td>
              <td className="tableListTitle">
                <button
                  type="button"
                  onClick={deleteData}
                  className="historyBtn"
                >
                  삭제
                </button>
              </td>
            </tr>
          </table>
        </div>
  );
};

export default PostHistory;
