import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config/config";
import "./../../css/mypage.scss";

const ChangePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userinfo = { ...location.state };
  const [id, _id] = useState("");
  const [newPassword, _newPassword] = useState("");
  const [newPasswordCheck, _newPasswordCheck] = useState("");
  const [confirmPasswordLabel, _confirmPasswordLabel] = useState("");
  const [confirmPasswordLabelOK, _confirmPasswordLabelOK] = useState("");

  const updateData = async () => {
    try {
      const mode = "passwordupdateid";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("password", newPassword);
      formData.append("id", id);
      const response = await axios.post(config.URL + "member.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      alert("비밀번호 변경이 완료되었습니다.");
      navigate("/");
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    _id(userinfo.memberID);
  }, [userinfo]);

  useEffect(() => {
    if (newPasswordCheck) {
      if (newPassword === newPasswordCheck) {
        _confirmPasswordLabelOK(" 비밀번호 일치");
        _confirmPasswordLabel("");
      } else {
        _confirmPasswordLabel(" 비밀번호가 다릅니다.");
        _confirmPasswordLabelOK("");
      }
    }
  }, [newPasswordCheck, newPassword]);

  return (
    <div className="mypageBox">
      <div className="substance">
        <h2>
          <span></span>비밀번호 변경
        </h2>
      </div>
      <table className="mypageTable">
        <tr>
          <td className="tableTitle">새 비밀번호</td>
          <td>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => _newPassword(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td className="tableTitle">새 비밀번호 확인</td>
          <td>
            <input
              type="password"
              value={newPasswordCheck}
              onChange={(e) => _newPasswordCheck(e.target.value)}
            />
            <label style={{ color: "green" }}>{confirmPasswordLabelOK}</label>
            <label style={{ color: "red" }}>{confirmPasswordLabel}</label>
          </td>
        </tr>
      </table>
      <div className="okQa" style={{ marginBottom: "50px" }}>
        <button onClick={updateData}>변경하기</button>
      </div>
    </div>
  );
};

export default ChangePassword;
