import React, { useEffect, useState } from "react";
import BBS from "../../component/bbs";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuR from "../../component/quickMenuR";
import "../../css/board.scss";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const StudentCommu = () => {
  const [tab, _tab] = useState();

  useEffect(() => {
    _tab("ipsi");
  }, []);

  return (
    <div>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <img
        src="/assets/community/commuBanner.png"
        className="communityBanner"
        alt="communityBanner"
      />
      <div className="subContent" style={{ marginTop: "30px" }}>
        <div className="commuLeftContent">
          <ul className="boardUl" style={{ margin: "0" }}>
            <li
              className={tab === "ipsi" ? "tabOn" : ""}
              onClick={() => {
                _tab("ipsi");
              }}
              style={{ width: "288px" }}
            >
              입시
            </li>
            <li
              className={tab === "study" ? "tabOn" : ""}
              onClick={() => {
                _tab("study");
              }}
              style={{ width: "288px" }}
            >
              학습
            </li>
            <li
              className={tab === "course" ? "tabOn" : ""}
              onClick={() => {
                _tab("course");
              }}
              style={{ width: "288px" }}
            >
              진로·취업
            </li>
            {/* <li
              className={tab === "living" ? "tabOn" : ""}
              onClick={() => {
                _tab("living");
              }}
            >
              생활
            </li>
            <li
              className={tab === "major" ? "tabOn" : ""}
              onClick={() => {
                _tab("major");
              }}
            >
              학과별 게시판
            </li> */}
          </ul>
          {tab === "ipsi" && <BBS boardType="ipsi" />}
          {tab === "study" && <BBS boardType="study" />}
          {tab === "course" && <BBS boardType="course" />}
          {/* {tab === "living" && <BBS boardType="living" />}
          {tab === "major" && <BBS boardType="major" />} */}
        </div>
        <div className="commuRightContent">
          <BBS boardType="list" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentCommu;
