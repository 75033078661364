import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Banner1 from "../assets/collegeBanner/random215x126/GaChon215x126.png";
import Search from "../assets/common/search.png";
import X from "../assets/common/x.png";
import logo from "../assets/inuni_logo.png";
import config from "../config/config";
import "../css/commercial.scss";
import "../css/layout.scss";
import { logout } from "../store/store";

const Header = (props) => {
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  const navigate = useNavigate();

  const [headerSimple, _headerSimple] = useState("showOff");
  const [header, _header] = useState("showOn");

  const [dDay, _dDay] = useState();
  const calculateDDay = () => {
    const today = new Date();
    const dDayDate = new Date("2025-11-13");
    const dDayCheck = Math.ceil((dDayDate - today) / (1000 * 60 * 60 * 24));

    _dDay(dDayCheck);
  };

  useEffect(() => {
    if (props.type === "simple") {
      _headerSimple("showOn");
      _header("showOff");
    } else {
      _headerSimple("showOff");
      _header("showOn");
    }
    calculateDDay();
  }, [props]);

  //광고배너 불러오기
  const [adTop, _adTop] = useState("");

  const getTopBanner = async () => {
    try {
      const position = "top";
      const mode = "adbannerfront";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("position", position);

      const response = await axios.post(
        `${config.URL + "adBanner.php"}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const banners = response.data.data;
      if (banners.length > 0) {
        const randomBanner =
          banners[Math.floor(Math.random() * banners.length)];
        _adTop(randomBanner);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getTopBanner();
  }, []);

  const bannerClick = async () => {
    try {
      if (!adTop) return;
      const formData = new FormData();
      formData.append("mode", "click");
      formData.append("adBannerID", adTop.id);
      formData.append("userID", user.userID);

      await axios.post(`${config.URL + "banner.php"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      window.open(adTop.link, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.log("클릭 저장 실패:", error);
    }
  };

  const bannerView = async () => {
    try {
      if (!adTop) return;
      const formData = new FormData();
      formData.append("mode", "view");
      formData.append("adBannerID", adTop.id);

      await axios.post(`${config.URL + "banner.php"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.log("클릭 저장 실패:", error);
    }
  };

  //드롭다운 메뉴
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const handleMouseOver = () => {
    setDropdownMenu(true);
  };
  const handleMouseOut = () => {
    setDropdownMenu(false);
  };

  //검색 메뉴
  const [searchMenu, setSearchMenu] = useState(false);
  const onClickEvent = () => {
    setSearchMenu(true);
  };
  const onExitEvent = () => {
    setSearchMenu(false);
  };

  //마이페이지 메뉴
  const [myMenu, _myMenu] = useState(false);

  const [keyword, _keyword] = useState("");

  const searchGo = () => {
    navigate(`/boardList/search/${keyword}`);
  };

  const checkEnter = (e) => {
    if (e.key === "Enter") {
      searchGo();
    }
  };

  //사이드 메뉴
  const [navigateBar, _navigateBar] = useState(false);
  const [menu1, _menu1] = useState(false);
  const [menu2, _menu2] = useState(false);
  const [menu3, _menu3] = useState(false);
  const [menu4, _menu4] = useState(false);
  const [menu5, _menu5] = useState(false);

  return (
    <header>
      <div className={`headerLayout ${header}`}>
        <div className="header_top">
          <ul className="header_btn_ul">
            {/* <Link
              to="/admin_main"
              target="_blank"
              style={{ marginRight: "30px" }}
            >
              관리자페이지
            </Link> */}
            <li style={{ color: "#ff1757", fontWeight: "700" }}>
              수능 D-day :{" "}
              {dDay === 0
                ? "D-Day"
                : dDay > 0
                ? `D-${dDay}`
                : `D+${Math.abs(dDay)}`}
              <span className="txt_bar"></span>
            </li>
            {isLoggedIn ? (
              <>
                <li>
                  <Link to="/" className="header_btn" onClick={logOut}>
                    로그아웃
                  </Link>
                  <span className="txt_bar"></span>
                </li>
                <li>
                  <img
                    src="/assets/member/mypageIcon.png"
                    style={{ width: "20px", margin: "0 5px -4px 0" }}
                    alt="mypage"
                  />
                  <Link to="/mysetting" onMouseEnter={() => _myMenu(true)}>
                    마이페이지
                  </Link>
                  <img
                    src="/assets/member/arrow.png"
                    style={{ margin: "0 0 3px 2px" }}
                    alt="arrow"
                  />
                  {myMenu && (
                    <ul className="myMenu" onMouseLeave={() => _myMenu(false)}>
                      {user.userType === "A" ? (
                        <Link to="/admin_main">관리자페이지</Link>
                      ) : (
                        ""
                      )}
                      <Link to="/mysetting/expoHistory">신청현황</Link>
                      <Link to="/mysetting/myPage">내정보수정</Link>
                      <Link to="/qa">1:1문의</Link>
                    </ul>
                  )}
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/Login" className="header_btn">
                    로그인
                  </Link>
                  <span className="txt_bar"></span>
                </li>
                <li>
                  <Link to="/Join">회원가입</Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="header_gnb">
          <a href="/" className="header_logo">
            <img src={logo} className="header_logo" alt="inuni_logo" />
          </a>
          <ul
            className="header_gnb_ul"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <li>
              <Link to="/CompanyMain/history">회사소개</Link>
            </li>
            <li>
              <Link to="/HighSchoolBoard/H">매체소개</Link>
            </li>
            <li>
              <Link to="/ExpoMain">박람회/설명회</Link>
            </li>
            <li>
              <Link to="/Ipsi/">입시정보</Link>
            </li>
            <li>
              <Link to="/Community">커뮤니티</Link>
            </li>
            <li>
              <Link to="/qa">문의하기</Link>
            </li>
          </ul>
          <div className="header_searchBox" onClick={onClickEvent}>
            <input type="text" placeholder="검색" />
            <img src={Search} alt="search" />
          </div>
        </div>
        {dropdownMenu && (
          <div
            className="header_sub_outline"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div className="border_gnb"></div>
            <div className="header_gnb_sub">
              {adTop && (
                <img
                  src={`${config.FILEURL + adTop.img}`}
                  className="navBanner"
                  alt="topBanner"
                  onClick={bannerClick}
                  onLoad={bannerView}
                  style={{ cursor: "pointer" }}
                />
              )}
              <div
                className="header_gnb_ul"
                style={{ width: "720px", margin: "0 0 0 30px" }}
              >
                <ul
                  className="header_gnb_sub_ul"
                  style={{ width: "69px", wordBreak: "keep-all" }}
                >
                  <li>
                    <Link to="/CompanyMain/history">연혁</Link>
                  </li>
                  <li>
                    <Link to="/CompanyMain/greeting">대표자인사말</Link>
                  </li>
                  <li>
                    <Link to="/CompanyMain/member">조직도</Link>
                  </li>
                </ul>
                <ul
                  className="header_gnb_sub_ul"
                  style={{ width: "69px", wordBreak: "keep-all" }}
                >
                  <li>
                    <Link to="/HighSchoolBoard/H">고교게시판</Link>
                  </li>
                  <li>
                    <Link to="/HighSchoolBoard/T">상위권게시판</Link>
                  </li>
                  <li>
                    <Link to="/HighSchoolBoard/L">지역게시판</Link>
                  </li>
                  <li>
                    <Link to="/HighSchoolBoard/B">모집요강배포</Link>
                  </li>
                  <li>
                    <Link to="/HighSchoolBoard/F">편입홍보</Link>
                  </li>
                  <li>
                    <Link to="/HighSchoolBoard/P">매체대행</Link>
                  </li>
                </ul>
                <ul
                  className="header_gnb_sub_ul"
                  style={{ width: "110px", wordBreak: "keep-all" }}
                >
                  <li>
                    <Link to="/TeacherExpo">교사설명회</Link>
                  </li>
                  <li>
                    <Link to="/NationalExpo">전국순회박람회</Link>
                  </li>
                  <li>
                    <Link to="/VisitingExpo">찾아가는박람회</Link>
                  </li>
                </ul>
                <ul
                  className="header_gnb_sub_ul"
                  style={{ width: "69px", wordBreak: "keep-all" }}
                >
                  <li>
                    <Link to="/CollegeList">대학소개</Link>
                  </li>
                  <li>
                    <Link to="/ipsiNews">입시뉴스</Link>
                  </li>
                </ul>
                <ul
                  className="header_gnb_sub_ul"
                  style={{ width: "69px", wordBreak: "keep-all" }}
                >
                  <li>
                    <Link to="/Community">학생들의이야기</Link>
                  </li>
                  <li>
                    <Link to="/event">이벤트</Link>
                  </li>
                  <li>
                    <Link to="/boardlist/O">공지사항</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {searchMenu && (
          <div className="header_sub_outline">
            <div className="border_gnb"></div>
            <div className="header_gnb_sub">
              <img src={Banner1} className="navBanner" alt="navBanner" />
              <div className="gnb_searchBox">
                <input
                  type="text"
                  placeholder="검색어를 입력해 주세요"
                  value={keyword}
                  onChange={(e) => _keyword(e.target.value)}
                  onKeyDown={checkEnter}
                />
                <img
                  src={Search}
                  alt="search"
                  className="search"
                  onClick={searchGo}
                />
                <img src={X} alt="X" className="exit" onClick={onExitEvent} />
              </div>
              <div className="topSearch"></div>
            </div>
          </div>
        )}
        <div className="mobileHeader">
          <a href="/">
            <img src={logo} className="header_logo" alt="inuni_logo" />
          </a>
          <button type="button" onClick={() => _navigateBar(true)}>
            <img src="/assets/common/nav_bar.png" />
          </button>
          <div className={`sidemenuLayout ${navigateBar ? "show" : ""}`}>
            <div className="navContent">
              <button className="closeNav">
                <img
                  src="/assets/common/x.png"
                  onClick={() => _navigateBar(false)}
                />
              </button>
              <div className="sideMenuTop">
                <div style={{ color: "#ff1757", fontWeight: "700" }}>
                  수능 D-day :{" "}
                  {dDay === 0
                    ? "D-Day"
                    : dDay > 0
                    ? `D-${dDay}`
                    : `D+${Math.abs(dDay)}`}
                </div>
                {isLoggedIn ? (
                  <ul className="mobileLoginBtn">
                    <li>
                      <Link to="/" className="header_btn" onClick={logOut}>
                        로그아웃
                      </Link>
                      <span className="txt_bar"></span>
                    </li>
                    <li>
                      <img
                        src="/assets/member/mypageIcon.png"
                        style={{ width: "20px", margin: "0 5px -4px 0" }}
                        alt="mypage"
                      />
                      <Link onClick={() => _myMenu(!myMenu)}>마이페이지</Link>
                      <img
                        src="/assets/member/arrow.png"
                        style={{ margin: "0 0 3px 2px" }}
                        alt="arrow"
                        className={`dropdownArrow ${myMenu ? "open" : ""}`}
                      />
                      <div className={`mobileMymenu ${myMenu ? "show" : ""}`}>
                        <Link to="/mysetting/expoHistory">신청현황</Link>
                        <Link to="/mysetting/myPage">내정보수정</Link>
                        <Link to="/qa">1:1문의</Link>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <ul className="mobileLoginBtn">
                    <li>
                      <Link to="/Login" className="header_btn">
                        로그인
                      </Link>
                      <span className="txt_bar"></span>
                    </li>
                    <li>
                      <Link to="/Join">회원가입</Link>
                    </li>
                  </ul>
                )}
              </div>
              <ul className="sideMenuUl">
                <li onClick={() => _menu1(!menu1)}>
                  <Link>회사소개</Link>
                  <img
                    src="/assets/common/arrowOpen.png"
                    className={`dropdownArrow ${menu1 ? "open" : ""}`}
                  />
                </li>
                <div className={`dropdownMenu ${menu1 ? "menu" : ""}`}>
                  <Link to="/CompanyMain/history">연혁</Link>
                  <Link to="/CompanyMain/greeting">대표자인사말</Link>
                  <Link to="/CompanyMain/member">조직도</Link>
                </div>
                <li onClick={() => _menu2(!menu2)}>
                  <Link>매체소개</Link>
                  <img
                    src="/assets/common/arrowOpen.png"
                    className={`dropdownArrow ${menu2 ? "open" : ""}`}
                  />
                </li>
                <div className={`dropdownMenu ${menu2 ? "menu2" : ""}`}>
                  <Link to="/HighSchoolBoard/H">고교게시판</Link>
                  <Link to="/HighSchoolBoard/T">상위권게시판</Link>
                  <Link to="/HighSchoolBoard/L">지역게시판</Link>
                  <Link to="/HighSchoolBoard/B">모집요강배포</Link>
                  <Link to="/HighSchoolBoard/F">편입홍보</Link>
                  <Link to="/HighSchoolBoard/P">매체대행</Link>
                </div>
                <li onClick={() => _menu3(!menu3)}>
                  <Link>박람회/설명회</Link>
                  <img
                    src="/assets/common/arrowOpen.png"
                    className={`dropdownArrow ${menu3 ? "open" : ""}`}
                  />
                </li>
                <div className={`dropdownMenu ${menu3 ? "menu" : ""}`}>
                  <Link to="/TeacherExpo">교사설명회</Link>
                  <Link to="/NationalExpo">전국순회박람회</Link>
                  <Link to="/VisitingExpo">찾아가는박람회</Link>
                </div>
                <li onClick={() => _menu4(!menu4)}>
                  <Link>입시정보</Link>
                  <img
                    src="/assets/common/arrowOpen.png"
                    className={`dropdownArrow ${menu4 ? "open" : ""}`}
                  />
                </li>
                <div className={`dropdownMenu ${menu4 ? "menu4" : ""}`}>
                  <Link to="/CollegeList">대학소개</Link>
                  <Link to="/ipsiNews">입시뉴스</Link>
                </div>
                <li onClick={() => _menu5(!menu5)}>
                  <Link>커뮤니티</Link>
                  <img
                    src="/assets/common/arrowOpen.png"
                    className={`dropdownArrow ${menu5 ? "open" : ""}`}
                  />
                </li>
                <div className={`dropdownMenu ${menu5 ? "menu" : ""}`}>
                  <Link to="/Community">학생들의이야기</Link>
                  <Link to="/event">이벤트</Link>
                  <Link to="/boardlist/O">공지사항</Link>
                </div>
                <li>
                  <Link to="/qa">문의하기</Link>
                </li>
              </ul>
              <div className="navAdBanner">
                {adTop && (
                  <img
                    src={`${config.FILEURL + adTop.img}`}
                    alt="topBanner"
                    onClick={bannerClick}
                    onLoad={bannerView}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
            <div className="navFooter">
              <p>
                대표 : 이봉운
                <span
                  className="txt_bar_b"
                  style={{ margin: "2px 10px 0 10px" }}
                ></span>
                사업자등록번호 : 214-86-62588
              </p>
              <p>대표번호 : 02-2624-1800</p>
              <p>주소 : 서울시 금천구 가산디지털1로 145, 408호</p>
              <p style={{ marginTop: "10px" }}>
                COPYRIGHT © 2025 아이앤유니. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`sType ${headerSimple}`}>
        <div className="simpleHeader">
          <img
            src={logo}
            onClick={() => {
              navigate("/");
            }}
            alt="logo"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
