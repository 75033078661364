import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdminNotice = () => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [type, _type] = useState("N");
  const [local, _local] = useState("D");
  const [city, _city] = useState();
  const [totalTeacher, _totalTeacher] = useState(100);
  const [startDate, _startDate] = useState(new Date());
  const [endDate, _endDate] = useState(new Date());
  const [id, _id] = useState("");
  const [tabState, _tabState] = useState("D");

  const getHtml = async () => {
    try {
      const mode = "teacherschedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(config.URL + "expo.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "teacherscheduleadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("local", local);
      formData.append("city", city);
      formData.append("totalTeacher", totalTeacher);
      formData.append("startDate", format(startDate, "yyyyMMdd"));
      formData.append("endDate", format(endDate, "yyyyMMdd"));

      const response = await axios.post(config.URL + "expo.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (schedule) => {
    _local(schedule.local);
    _city(schedule.city);
    _totalTeacher(schedule.totalTeacher);
    _startDate(schedule.startDate);
    _endDate(schedule.endDate);
    _id(schedule.id);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "teacherscheduleupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("local", local);
      formData.append("city", city);
      formData.append("totalTeacher", totalTeacher);
      formData.append("startDate", format(startDate, "yyyyMMdd"));
      formData.append("endDate", format(endDate, "yyyyMMdd"));
      formData.append("id", id);

      const response = await axios.post(config.URL + "expo.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    page == "D"
      ? _tabState("D")
      : page == "B"
      ? _tabState("B")
      : _tabState("D");
  }, [page]);

  useEffect(() => {
    getHtml();

    let today = new Date();
    _startDate(today);
    let nextDay = new Date();
    let plusMonth = nextDay.setMonth(nextDay.getMonth() + 1);
    _endDate(plusMonth);
  }, [tabState]);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>설명회 행사 일정 관리
              {type == "N" ? (
                <button onClick={() => postData()}>추가</button>
              ) : (
                <>
                  <button onClick={reload}>초기화</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">행사지역</td>
              <select onChange={(e) => _local(e.target.value)}>
                <option value={"D"}>대전</option>
                <option value={"B"}>부산·울산·경남</option>
              </select>
              <td className="tableTitle">행사장소</td>
              <td>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => _city(e.target.value)}
                  style={{ width: "90%", height: "30px", border: "0" }}
                />
              </td>
              <td className="tableTitle">모집인원</td>
              <td>
                <input
                  type="text"
                  value={totalTeacher}
                  onChange={(e) => _totalTeacher(e.target.value)}
                  style={{ width: "90%", height: "30px", border: "0" }}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">행사 개최일</td>
              <td>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(date) => _startDate(date)}
                />
              </td>
              <td className="tableTitle">행사 종료일</td>
              <td>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={endDate}
                  onChange={(date) => _endDate(date)}
                />
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>설명회 일정 목록
            </h2>
          </div>
          <ul
            className="tabStateUl"
            style={{ width: "1060px", marginBottom: "30px" }}
          >
            <li
              className={tabState === "D" ? "tabOn" : ""}
              onClick={() => {
                _tabState("D");
              }}
            >
              대전지역
            </li>
            <li
              className={tabState === "B" ? "tabOn" : ""}
              onClick={() => {
                _tabState("B");
              }}
            >
              부산 · 울산 · 경남지역
            </li>
          </ul>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width={"25%"}>
                행사장소
              </td>
              <td className="tableListTitle" width={"25%"}>
                행사 개최일
              </td>
              <td className="tableListTitle" width={"25%"}>
                행사 종료일
              </td>
              <td className="tableListTitle" width={"25%"}>
                모집인원
              </td>
            </tr>
            {tabState === "D" &&
              listdata.map((schedule, index) => (
                <tr key={index} onClick={() => changeData(schedule)}>
                  <td className="tableListTitle">{schedule.city}</td>
                  <td className="tableListTitle">
                    {format(schedule.startDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">
                    {format(schedule.endDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.totalTeacher}</td>
                </tr>
              ))}
            {tabState === "B" &&
              listdata.map((schedule, index) => (
                <tr key={index} onClick={() => changeData(schedule)}>
                  <td className="tableListTitle">{schedule.city}</td>
                  <td className="tableListTitle">
                    {format(schedule.startDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">
                    {format(schedule.endDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.totalTeacher}</td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminNotice;
