import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import config from "../config/config";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SlideBanner = () => {
  const [slideBanner, _slideBanner] = useState([]);

  const getSlideBanner = async () => {
    try {
      const mode = "slidelistpc";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(
        config.URL + "slideBanner.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      _slideBanner(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getSlideBanner();
  }, []);

  return (
    <div className="subContent">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        loop={true}
      >
        {slideBanner &&
          slideBanner.map(
            (slideBanner, index) =>
              slideBanner.hidden == "N" && (
                <SwiperSlide>
                  <Link to={slideBanner.link}>
                    <img key={index} src={config.FILEURL + slideBanner.img} />
                  </Link>
                </SwiperSlide>
              )
          )}
      </Swiper>
    </div>
  );
};

export default SlideBanner;
