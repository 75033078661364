import React from "react";
import { BrowserRouter, Route, Routes } from "react-router";
import IpsiBoard from "../component/ipsiBoard";
import NationalExpoModal from "../component/nationalExpoModal";
import TeacherExpoModal from "../component/teacherExpoModal";
import AdminAdLeftBanner from "../pages/admin/banner/adLeftBanner";
import AdminAdTopBanner from "../pages/admin/banner/adTopBanner";
import AdminAdWideBanner from "../pages/admin/banner/adWideBanner";
import MainBanner from "../pages/admin/banner/mainBanner";
import AdminNotice from "../pages/admin/board/index";
import Greeting from "../pages/admin/config/greeting";
import History from "../pages/admin/config/history";
import Config from "../pages/admin/config/index";
import Policy from "../pages/admin/config/policy";
import ExpoSchedule from "../pages/admin/expo/exposchedule";
import Fair from "../pages/admin/expo/fair";
import FairSchedule from "../pages/admin/expo/fairschedule";
import Expo from "../pages/admin/expo/index";
import VisitingExpoSchedule from "../pages/admin/expo/visitingExpoSchedule";
import AdminMain from "../pages/admin/home/index";
import IpsiInfo from "../pages/admin/ipsiinfo/index";
import SellProduct from "../pages/admin/sellProduct/index";
import BannerState from "../pages/admin/state/bannerState";
import BannerStateDetail from "../pages/admin/state/bannerStateDetail";
import State from "../pages/admin/state/index";
import EarlyExamUniv from "../pages/admin/univ/earlyExamUniv";
import Univ from "../pages/admin/univ/index";
import RollingUniv from "../pages/admin/univ/rollingUniv";
import TeacherUniv from "../pages/admin/univ/teacherUniv";
import User from "../pages/admin/user/index";
import BoardList from "../pages/board/boardList";
import BoardView from "../pages/board/boardView";
import BoardWrite from "../pages/board/boardWrite";
import Event from "../pages/community/event";
import Community from "../pages/community/studentCommu";
import CompanyMain from "../pages/company/companyMain";
import ExpoMain from "../pages/expo/expoMain";
import NationalExpo from "../pages/expo/nationalExpo";
import TeacherExpo from "../pages/expo/teacherExpo";
import TeacherExpoBS from "../pages/expo/teacherExpoBS";
import TeacherExpoDJ from "../pages/expo/teacherExpoDJ";
import VisitingExpo from "../pages/expo/visitingExpo";
import CollegeList from "../pages/ipsi/collegeList";
import Ipsi from "../pages/ipsi/ipsiMain";
import IpsiNews from "../pages/ipsi/ipsiNews";
import Home from "../pages/main/index";
import HighSchoolBoard from "../pages/mediaCommercial/highSchoolBoard";
import FindID from "../pages/member/findID";
import Join from "../pages/member/join";
import Login from "../pages/member/login";
import ChangePW from "../pages/mypage/changePw";
import ExpoHistory from "../pages/mypage/expoHistory";
import MyPage from "../pages/mypage/index";
import Mypage from "../pages/mypage/myPage";
import PostHistory from "../pages/mypage/postHistory";
import QaHistory from "../pages/mypage/qaHistory";
import QuitMember from "../pages/mypage/quitMember";
import ChangePassword from "./../pages/member/changePassword";
import Qa from "./../pages/service/qa";

const Router = () => {
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Login" element={<Login />} />
        <Route path="Join" element={<Join />} />
        <Route path="FindID" element={<FindID />} />
        <Route path="CompanyMain/:page" element={<CompanyMain />} />
        <Route path="ExpoMain" element={<ExpoMain />} />
        <Route path="HighSchoolBoard/:page" element={<HighSchoolBoard />} />
        <Route path="Ipsi" element={<Ipsi />} />
        <Route path="admin_main" element={<AdminMain />} />
        <Route path="Community" element={<Community />} />
        <Route path="IpsiBoard" element={<IpsiBoard />} />
        <Route path="TeacherExpo" element={<TeacherExpo />} />
        <Route path="teacherexpodj" element={<TeacherExpoDJ />} />
        <Route path="TeacherExpoBS" element={<TeacherExpoBS />} />
        <Route path="TeacherExpoModal" element={<TeacherExpoModal />} />
        <Route path="NationalExpo" element={<NationalExpo />} />
        <Route path="NationalExpoModal" element={<NationalExpoModal />} />
        <Route path="VisitingExpo" element={<VisitingExpo />} />
        <Route path="CollegeList" element={<CollegeList />} />
        <Route path="IpsiNews" element={<IpsiNews />} />
        <Route path="event" element={<Event />} />
        <Route path="config" element={<Config />} />
        <Route path="policy" element={<Policy />} />
        <Route path="main_banner" element={<MainBanner />} />
        <Route path="history" element={<History />} />
        <Route path="greeting" element={<Greeting />} />
        <Route path="sellproduct" element={<SellProduct />} />
        <Route path="sellproduct/:page" element={<SellProduct />} />
        <Route path="expo" element={<Expo />} />
        <Route path="exposcheduler" element={<ExpoSchedule />} />
        <Route path="fair" element={<Fair />} />
        <Route path="fairschedule" element={<FairSchedule />} />
        <Route path="univ" element={<Univ />} />
        <Route path="user" element={<User />} />
        <Route path="ipsiinfo" element={<IpsiInfo />} />
        <Route path="adminnotice/:page" element={<AdminNotice />} />
        <Route path="adminnotice" element={<AdminNotice />} />
        <Route path="admintopbanner" element={<AdminAdTopBanner />} />
        <Route path="adminwidebanner" element={<AdminAdWideBanner />} />
        <Route path="adminleftbanner" element={<AdminAdLeftBanner />} />
        <Route path="qa/:page" element={<Qa />} />
        <Route path="qa" element={<Qa />} />
        <Route path="state" element={<State />} />
        <Route path="bannerstate" element={<BannerState />} />
        <Route path="bannerstatedetail/:page" element={<BannerStateDetail />} />
        <Route path="mypage" element={<Mypage />} />
        <Route path="quitmember" element={<QuitMember />} />
        <Route path="changepw" element={<ChangePW />} />
        <Route path="expohistory" element={<ExpoHistory />} />
        <Route path="posthistory" element={<PostHistory />} />
        <Route path="qahistory" element={<QaHistory />} />
        <Route path="boardlist" element={<BoardList />} />
        <Route path="boardlist/:page" element={<BoardList />} />
        <Route path="boardlist/:page/:keyword" element={<BoardList />} />
        <Route path="boardview" element={<BoardView />} />
        <Route path="boardview/:page" element={<BoardView />} />
        <Route path="boardwrite" element={<BoardWrite />} />
        <Route path="boardwrite/:page" element={<BoardWrite />} />
        <Route path="mysetting" element={<MyPage />} />
        <Route path="mysetting/:page" element={<MyPage />} />
        <Route path="teacheruniv" element={<TeacherUniv />} />
        <Route path="earlyexamuniv" element={<EarlyExamUniv />} />
        <Route path="changepassword" element={<ChangePassword />} />
        <Route path="visitingexposchedule" element={<VisitingExpoSchedule />} />
        <Route path="rollinguniv" element={<RollingUniv />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
