import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>회원 관리</h1>
      <ul>
        <h2>회원</h2>
        <li>
          <Link
            to="/user"
            style={{
              color: location.pathname === "/user" && "#971b1e",
            }}
          >
            회원 리스트
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
