import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FullBanner from "../../component/fullBanner";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import "../../css/collegeExam.scss";
import "../../css/main.scss";
import Footer from "./../../layout/footer";
import Header from "./../../layout/header";

const CollegeProfile = () => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [tabState, _tabState] = useState("seoul");

  //대학리스트 불러오기
  const getHtml = async () => {
    try {
      const mode = "univlist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(config.URL + "univ.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    page == "S"
      ? _tabState("S")
      : page == "C"
      ? _tabState("C")
      : page == "H"
      ? _tabState("H")
      : page == "G"
      ? _tabState("G")
      : _tabState("S");
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [tabState]);

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <img src="/assets/ipsi/ipsiBanner.png" className="ipsiBanner" />
      <div className="collegeListOutline">
        <ul className="companyUl">
          <li
            className={tabState === "S" ? "tabOn" : ""}
            onClick={() => {
              _tabState("S");
            }}
          >
            서울·경기권
          </li>
          <li
            className={tabState === "C" ? "tabOn" : ""}
            onClick={() => {
              _tabState("C");
            }}
          >
            대전·충청권
          </li>
          <li
            className={tabState === "H" ? "tabOn" : ""}
            onClick={() => {
              _tabState("H");
            }}
          >
            호남권
          </li>
          <li
            className={tabState === "G" ? "tabOn" : ""}
            onClick={() => {
              _tabState("G");
            }}
          >
            부산·울산·경남·제주권
          </li>
        </ul>
        <div className="collegeProfileInline">
          {tabState == "S" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
          {tabState == "C" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
          {tabState == "H" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
          {tabState == "G" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
        </div>
      </div>
      <FullBanner />
      <Footer />
    </>
  );
};

export default CollegeProfile;
