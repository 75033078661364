import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../config/config";
import "../../css/main.scss";
import NewIcon from "./../../assets/common/newIcon.png";

const BoardMain = () => {
  const { page } = useParams();
  const [listnews, _listnews] = useState([]);
  const [listnotice, _listnotice] = useState([]);

  const getNews = async () => {
    try {
      const mode = "bbslistfront";
      const bbsType = "N";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listnews(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getNotice = async () => {
    try {
      const mode = "bbslistfront";
      const bbsType = "O";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listnotice(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //뉴아이콘 생성
  const checkDay = dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD");

  useEffect(() => {
    getNews();
    getNotice();
  }, []);

  return (
    <div className="subContent" style={{ padding: "0" }}>
      <div className="board_outline">
        <div className="boardTitle">
          <h1>
            <span></span>2025 입시
          </h1>
          <Link to="/ipsiNews">전체보기</Link>
        </div>
        <div className="mainNewsContent">
          <div className="mainHeadlineNewsBox">
            {listnews &&
              listnews.map(
                (bbs, index) =>
                  index < 2 && (
                    <Link
                      to="/boardview/N"
                      state={{ data: bbs }}
                      className="mainHeadline"
                      style={{ top: "0" }}
                      key={index}
                    >
                      <img src={config.FILEURL + bbs.img} />
                      <h4>{bbs.title}</h4>
                    </Link>
                  )
              )}
          </div>
          <div className="mainNewsTitleBox">
            {listnews &&
              listnews.map(
                (bbs, index) =>
                  index > 2 &&
                  index < 16 && (
                    <Link to="/boardview/N" key={index} state={{ data: bbs }}>
                      {bbs.title}
                    </Link>
                  )
              )}
          </div>
        </div>
      </div>
      <div className="board_outline">
        <div className="boardTitle">
          <h1>
            <span></span>공지사항
          </h1>
          <Link to="/boardlist/O">전체보기</Link>
        </div>
        <div className="mainNoticeBox">
          {listnotice &&
            listnotice.map(
              (bbs, index) =>
                index < 13 && (
                  <Link to="/boardview/O" key={index} state={{ data: bbs }}>
                    <ul style={{ display: "flex", width: "100%" }}>
                      <li style={{ width: "70%" }}>
                        {bbs.title}
                        {bbs.createDate >= checkDay && (
                          <img
                            src={NewIcon}
                            style={{
                              width: "30px",
                              height: "auto",
                              margin: "0 0 -2px 5px",
                            }}
                          />
                        )}
                      </li>
                      <li style={{ width: "10%" }}>{bbs.writer}</li>
                      <li style={{ textAlign: "end", width: "20%" }}>
                        {bbs.createDate.substr(0, 10)}
                      </li>
                    </ul>
                  </Link>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default BoardMain;
