import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../config/config";

const IpsiBoard = ({ boardType = "list" }) => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [type, _type] = useState("");
  const [tabState, _tabState] = useState("seoul");

  const getBBS = async (type) => {
    try {
      const mode = "bbslist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", type);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    /*
    커뮤니티 입시 = CI
    커뮤니티 학습 = CS
    커뮤티니 생활 = CL
    커뮤니티 진로/취업 = CC
    커뮤니티 학과 = CM 
    {tab === "ipsi" && <BBS boardType="ipsi" />}
    {tab === "study" && <BBS boardType="study" />}
    {tab === "course" && <BBS boardType="course" />}
    {tab === "living" && <BBS boardType="living" />}
    {tab === "major" && <BBS boardType="major" />}
    */

    if (boardType === "ipsi") {
      _type("CI");
    } else if (boardType === "study") {
      _type("CS");
    } else if (boardType === "course") {
      _type("CC");
    } else if (boardType === "living") {
      _type("CL");
    } else if (boardType === "major") {
      _type("CM");
    } else if (boardType === "list") {
      _type("list");
    }
    getBBS(type);
  }, [page, boardType, type]);

  return (
    <>
      {boardType !== "list" && (
        <div className="ipsiNewsBox" style={{ borderRight: "0" }}>
          <div className="ipsiNewsTitle">
            <img src="/assets/ipsi/ipsiNewsIcon1.png" alt="ipsiNewsIcon1" />
            <h2>
              {boardType === "ipsi" && "입시 커뮤니티"}
              {boardType === "study" && "학습 커뮤니티"}
              {boardType === "course" && "진로·취업 커뮤니티"}
              {boardType === "living" && "생활 커뮤니티"}
              {boardType === "major" && "학과별 게시판 커뮤니티"}
            </h2>
          </div>

          <div className="ipsiGallery">
            {listdata &&
              listdata.map(
                (bbs, index) =>
                  index < 16 && (
                    <Link
                      to={`/boardview/${type}`}
                      state={{ data: bbs }}
                      key={index}
                      className="ipsiGalleryHeadline"
                    >
                      {bbs.img ? (
                        <img src={config.FILEURL + bbs.img} alt={index} />
                      ) : (
                        <div
                          style={{
                            width: "150px",
                            height: "70px",
                            backgroundColor: "#ddd",
                            textAlign: "center",
                            paddingTop: "30px",
                          }}
                        >
                          <h2
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            NO IMAGE
                          </h2>
                        </div>
                      )}
                      <h2>{bbs.title}</h2>
                    </Link>
                  )
              )}
          </div>
        </div>
      )}
      {boardType === "list" && (
        <div className="ipsiVRBox">
          <div className="ipsiNewsTitle">
            <img src="/assets/ipsi/ipsiNewsIcon1.png" alt="ipsiNewsIcon" />
            <h2>베스트 커뮤니티</h2>
          </div>
          {listdata &&
            listdata.map(
              (bbs, index) =>
                index < 14 && (
                  <Link
                    to={`/boardview/${bbs.bbsType}`}
                    key={index}
                    state={{ data: bbs }}
                    className="ipsiBestList"
                  >
                    {bbs.title}
                  </Link>
                )
            )}
        </div>
      )}
    </>
  );
};

export default IpsiBoard;
