import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

const TeacherUniv = () => {
  const [listdata, _listdata] = useState([]);
  const { page } = useParams();
  const [type, _type] = useState("N");
  const [schoolName, _schoolName] = useState("");
  const [local, _local] = useState("D");
  const [hidden, _hidden] = useState("N");
  const [link, _link] = useState("");
  const [emblem, _emblem] = useState("");
  const [id, _id] = useState("");
  const [preview, _preview] = useState("");
  const [tabState, _tabState] = useState("D");

  const getHtml = async () => {
    try {
      const mode = "teacherunivlist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(
        config.URL + "teacherUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "teacherunivadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", schoolName);
      formData.append("local", local);
      formData.append("link", link);
      formData.append("hidden", hidden);
      emblem && formData.append("emblem", emblem);

      const response = await axios.post(
        config.URL + "teacherUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (univ) => {
    _id(univ.id);
    _hidden(univ.hidden);
    _schoolName(univ.schoolName);
    _local(univ.local);
    _link(univ.link);
    _preview(config.FILEURL + univ.emblem);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "teacherunivupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", schoolName);
      formData.append("local", local);
      formData.append("link", link);
      formData.append("hidden", hidden);
      formData.append("id", id);
      emblem && formData.append("emblem", emblem);

      const response = await axios.post(
        config.URL + "teacherUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteCheck = () => {
    if (window.confirm("삭제하시겠습니까? 복구할 수 없습니다.")) {
      deleteData();
    } else {
      return;
    }
  };

  const deleteData = async () => {
    try {
      const mode = "teacherunivdel";
      const formData = new FormData();
      formData.append("id", id);
      formData.append("mode", mode);

      const response = await axios.post(
        config.URL + "teacherUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    page == "D"
      ? _tabState("D")
      : page == "B"
      ? _tabState("B")
      : _tabState("D");
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [tabState]);

  const ImgFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      _emblem(file);
      _preview(URL.createObjectURL(file));
    }
  };

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>교사설명회 대학교 관리
              {type === "N" ? (
                <button onClick={() => postData()}>추가</button>
              ) : (
                <>
                  <button onClick={deleteCheck}>삭제</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">대학교 이름</td>
              <td width="250px">
                <input
                  type="text"
                  value={schoolName}
                  onChange={(e) => _schoolName(e.target.value)}
                  style={{ width: "80%", border: "0" }}
                />
              </td>
              <td className="tableTitle">지역</td>
              <td>
                <select
                  value={local}
                  onChange={(e) => _local(e.target.value)}
                  style={{ width: "150px" }}
                >
                  <option value="D">대전</option>
                  <option value="B">부산·울산·경남</option>
                </select>
              </td>
              <td className="tableTitle" rowSpan={2}>
                앰블럼
              </td>
              <td rowSpan={2} width={"20%"} style={{ textAlign: "center" }}>
                {preview && (
                  <img
                    src={preview}
                    alt="미리보기"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={ImgFileChange}
                  style={{ width: "90%" }}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">홈페이지 링크</td>
              <td>
                <input
                  type="text"
                  value={link}
                  onChange={(e) => _link(e.target.value)}
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
              <td className="tableTitle">사용여부</td>
              <td>
                <input
                  value="N"
                  checked={hidden === "N"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                Y
                <input
                  value="Y"
                  checked={hidden === "Y"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                N
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>교사설명회 대학교 목록
            </h2>
          </div>
          <ul
            className="tabStateUl"
            style={{ width: "1060px", marginBottom: "30px" }}
          >
            <li
              className={tabState === "D" ? "tabOn" : ""}
              onClick={() => {
                _tabState("D");
              }}
            >
              대전지역
            </li>
            <li
              className={tabState === "B" ? "tabOn" : ""}
              onClick={() => {
                _tabState("B");
              }}
            >
              부산 · 울산 · 경남지역
            </li>
          </ul>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width="7%">
                순서
              </td>
              <td className="tableListTitle" width="15%">
                지역
              </td>
              <td className="tableListTitle">대학교</td>
              <td className="tableListTitle" width="20%">
                앰블럼
              </td>
              <td className="tableListTitle" width="15%">
                홈페이지
              </td>
              <td className="tableListTitle" width="15%">
                사용여부
              </td>
            </tr>
            {tabState == "D" &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">
                    {univ.local === "D"
                      ? "대전"
                      : univ.local === "B"
                      ? "부산·울산·경남"
                      : ""}
                  </td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  <td className="tableListTitle">
                    <img
                      src={config.FILEURL + univ.emblem}
                      style={{
                        width: "60px",
                        objectFit: "contain",
                      }}
                      alt="emblem"
                    />
                  </td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="tableListTitle">{univ.hidden}</td>
                </tr>
              ))}
            {tabState == "B" &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">
                    {univ.local === "D"
                      ? "대전"
                      : univ.local === "B"
                      ? "부산·울산·경남"
                      : ""}
                  </td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  <td className="tableListTitle">
                    <img
                      src={config.FILEURL + univ.emblem}
                      style={{
                        width: "60px",
                        objectFit: "contain",
                      }}
                      alt="emblem"
                    />
                  </td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="tableListTitle">{univ.hidden}</td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TeacherUniv;
