import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../store/store";

const AdminHeader = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  const [dDay, _dDay] = useState();
  const calculateDDay = () => {
    const today = new Date();
    const dDayDate = new Date("2025-11-13");
    const dDayCheck = Math.ceil((dDayDate - today) / (1000 * 60 * 60 * 24));

    _dDay(dDayCheck);
  };

  const header = useState("showOn");
  useEffect(() => {
    calculateDDay();
  }, []);
  return (
    <header>
      <div className={`headerLayout ${header}`} style={{ height: "100px" }}>
        <div className="adminHeader_top">
          <ul className="header_btn_ul">
            <a href="/admin_main" className="header_logo">
              <img
                src="/assets/inuni_logo.png"
                className="adminHeader_logo"
                alt="inuni_logo"
              />
            </a>
            <li
              style={{ marginTop: "10px", color: "#ff1757", fontWeight: "700" }}
            >
              수능 D-day :{" "}
              {dDay === 0
                ? "D-Day"
                : dDay > 0
                ? `D-${dDay}`
                : `D+${Math.abs(dDay)}`}
              <span className="txt_bar"></span>
            </li>
            <li style={{ marginTop: "10px" }}>
              <Link to="/" target="_blank">
                홈페이지
              </Link>
              <span className="txt_bar"></span>
            </li>
            {isLoggedIn ? (
              <>
                <li style={{ marginTop: "10px" }}>
                  <Link
                    to="/admin_main"
                    className="header_btn"
                    onClick={logOut}
                  >
                    로그아웃
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li style={{ marginTop: "10px" }}>
                  <Link to="/Login" className="header_btn">
                    로그인
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="adminHeader_gnb">
          <ul>
            <li style={{ borderLeft: "1px solid #333" }}>
              <Link to="/config">홈페이지관리</Link>
            </li>
            <li>
              <Link to="/main_banner">배너관리</Link>
            </li>
            <li>
              <Link to="/sellproduct">매체관리</Link>
            </li>
            <li>
              <Link to="/expo">대입박람회/설명회관리</Link>
            </li>
            <li>
              <Link to="/univ">대학교관리</Link>
            </li>
            <li>
              <Link to="/user">회원관리</Link>
            </li>
            <li>
              <Link to="/adminnotice">게시판관리</Link>
            </li>
            <li>
              <Link to="/state">통계관리</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
