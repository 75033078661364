import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>매체 관리</h1>
      <ul>
        <h2>매체소개</h2>
        <li>
          <Link
            to="/sellproduct/H"
            style={{
              color:
                (location.pathname === "/sellproduct/H" ||
                  location.pathname === "/sellproduct") &&
                "#971b1e",
            }}
          >
            고교게시판
          </Link>
        </li>
        <li>
          <Link
            to="/sellproduct/T"
            style={{
              color: location.pathname === "/sellproduct/T" && "#971b1e",
            }}
          >
            상위권게시판
          </Link>
        </li>
        <li>
          <Link
            to="/sellproduct/L"
            style={{
              color: location.pathname === "/sellproduct/L" && "#971b1e",
            }}
          >
            지역게시판
          </Link>
        </li>
        <li>
          <Link
            to="/sellproduct/B"
            style={{
              color: location.pathname === "/sellproduct/B" && "#971b1e",
            }}
          >
            모집요강배포
          </Link>
        </li>
        <li>
          <Link
            to="/sellproduct/F"
            style={{
              color: location.pathname === "/sellproduct/F" && "#971b1e",
            }}
          >
            편입홍보
          </Link>
        </li>
        <li>
          <Link
            to="/sellproduct/P"
            style={{
              color: location.pathname === "/sellproduct/P" && "#971b1e",
            }}
          >
            매체대행
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
