import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import LogoBanner from "../../component/logoBanner";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const IpsiNews = () => {
  const [listdata, _listdata] = useState([]);
  const { page } = useParams();
  const [tabState, _tabState] = useState("seoul");
  const [listnews, _listnews] = useState([]);
  const [listvideo, _listvideo] = useState([]);
  const [view, _view] = useState(false);
  const [boardTitle, _boardTitle] = useState();
  const [boardContent, _boardContent] = useState();
  const [boardImg, _boardImg] = useState();
  const [bbs, _bbs] = useState();
  const [bbsType, _bbsType] = useState();

  //입시뉴스 불러오기
  const getNews = async () => {
    try {
      const mode = "bbslist";
      const bbsType = "N";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listnews(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //입시영상 불러오기
  const getVideo = async () => {
    try {
      const mode = "bbslist";
      const bbsType = "V";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listvideo(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getNews();
    getVideo();
  }, [page]);

  const viewBoard = (bbs) => {
    _bbs(bbs);
    _boardTitle(bbs.title);
    _boardContent(bbs.content);
    _boardImg(bbs.img);
    _bbsType(bbs.bbsType);
    _view(true);
  };

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <img
        src="/assets/ipsi/ipsiBanner.png"
        className="ipsiBanner"
        alt="ipsiBanner"
      />
      <div className="ipsiNewsLayout" style={{ marginTop: "30px" }}>
        <div className="ipsiNewsBox">
          <div className="ipsiNewsTitle">
            <img src="/assets/ipsi/ipsiNewsIcon1.png" alt="ipsiNewsIcon1" />
            <h2>입시뉴스</h2>
          </div>
          <div className="ipsiGallery">
            {view && (
              <div className="ipsiNewsViewBox" onClick={() => _view(false)}>
                <h2>
                  {boardTitle}
                  <Link to={`/boardview/${bbsType}`} state={{ data: bbs }}>
                    더보기
                  </Link>
                </h2>
                {bbsType == "V" ? (
                  ""
                ) : (
                  <img src={config.FILEURL + boardImg} alt="img" />
                )}

                <div
                  dangerouslySetInnerHTML={{
                    __html: boardContent,
                  }}
                />
              </div>
            )}
            {listnews &&
              listnews.map(
                (bbs, index) =>
                  index < 15 && (
                    <Link
                      to="#"
                      state={{ data: bbs }}
                      key={index}
                      className="ipsiGalleryHeadline"
                      onClick={() => viewBoard(bbs)}
                    >
                      {bbs.img ? (
                        <img src={config.FILEURL + bbs.img} alt={index} />
                      ) : (
                        <div
                          style={{
                            width: "150px",
                            height: "70px",
                            backgroundColor: "#ddd",
                            textAlign: "center",
                            paddingTop: "30px",
                          }}
                        >
                          <h2
                            style={{
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            NO IMAGE
                          </h2>
                        </div>
                      )}
                      <h2>{bbs.title}</h2>
                    </Link>
                  )
              )}
          </div>
        </div>
        <div className="ipsiVRBox">
          <div className="ipsiNewsTitle">
            <img src="/assets/ipsi/ipsiNewsIcon2.png" alt="ipsiNewsIcon2" />
            <h2>입시영상</h2>
          </div>
          {listvideo &&
            listvideo.map(
              (bbs, index) =>
                index < 6 && (
                  <Link
                    to="#"
                    state={{ data: bbs }}
                    key={index}
                    onClick={() => viewBoard(bbs)}
                  >
                    {bbs.img && (
                      <>
                        <div className="playBtn">
                          <span>
                            <img src="/assets/common/play.png" />
                          </span>
                        </div>
                        <img src={config.FILEURL + bbs.img} alt={index} />
                      </>
                    )}
                    <div>
                      <h2>{bbs.title}</h2>
                      <h3>
                        {bbs.writer}
                        <br />
                        {format(bbs.createDate, "yyyy-MM-dd")}
                      </h3>
                    </div>
                  </Link>
                )
            )}
        </div>
      </div>
      <div className="logoBanner">
        <LogoBanner />
      </div>
      <Footer />
    </>
  );
};

export default IpsiNews;
