import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FullBanner from "../../component/fullBanner";
import AdLeftBanner from "../../component/leftBanner";
import LogoBanner from "../../component/logoBanner";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const IpsiMain = () => {
  const [listdata, _listdata] = useState([]);
  const { page } = useParams();
  const [listnews, _listnews] = useState([]);
  const [listvideo, _listvideo] = useState([]);

  //대학리스트 불러오기
  const getHtml = async () => {
    try {
      const mode = "univlistbest";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "univ.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //입시뉴스 불러오기
  const getNews = async () => {
    try {
      const mode = "bbslist";
      const bbsType = "N";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listnews(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //입시영상 불러오기
  const getVideo = async () => {
    try {
      const mode = "bbslist";
      const bbsType = "V";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listvideo(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
    getNews();
    getVideo();
  }, []);

  return (
    <div>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <img src="/assets/ipsi/ipsiBanner.png" className="ipsiBanner" />
      <div className="collegeListOutline">
        <div className="univLinkTitle" style={{ marginBottom: "10px" }}>
          <h1>
            <span></span>BEST 대학
          </h1>
        </div>
        <div className="collegeProfileInline">
          {listdata &&
            listdata.map((univ, index) => (
              <div key={index} className="collegeProfile">
                <img src={config.FILEURL + univ.emblem} />
                <div className="profile_txt">
                  <h2>{univ.schoolName}</h2>
                  <ul>
                    <a
                      href={univ.earlyAdmissionLink}
                      target="_blank"
                      rel="noreferrer"
                      className="li original"
                    >
                      수시
                    </a>
                    <a
                      href={univ.regularAdmissionLink}
                      target="_blank"
                      rel="noreferrer"
                      className="li original"
                    >
                      정시
                    </a>
                    <a
                      href={univ.homepage}
                      target="_blank"
                      rel="noreferrer"
                      className="li home"
                    >
                      홈페이지
                    </a>
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
      <FullBanner />
      <div className="ipsiNewsLayout">
        <div className="ipsiNewsBox">
          <div className="ipsiNewsTitle">
            <img src="/assets/ipsi/ipsiNewsIcon1.png" />
            <h2>입시뉴스</h2>
            <Link to="/ipsiNews">전체보기</Link>
          </div>
          <div className="ipsiNewsContent">
            <div className="ipsiHeadlineBox">
              {listnews &&
                listnews.map(
                  (bbs, index) =>
                    index < 4 && (
                      <Link
                        to="/boardview/N"
                        state={{ data: bbs }}
                        key={index}
                        className="ipsiHeadline"
                      >
                        <img src={config.FILEURL + bbs.img} />
                        <h2>{bbs.title}</h2>
                      </Link>
                    )
                )}
            </div>
            <div className="ipsiTitleBox">
              {listnews &&
                listnews.map(
                  (bbs, index) =>
                    index > 3 &&
                    index < 17 && (
                      <Link to="/boardview/N" state={{ data: bbs }} key={index}>
                        <h2>{bbs.title}</h2>
                        <span>{format(bbs.createDate, "yyyy-MM-dd")}</span>
                      </Link>
                    )
                )}
            </div>
          </div>
        </div>
        <div className="ipsiVRBox">
          <div className="ipsiNewsTitle">
            <img src="/assets/ipsi/ipsiNewsIcon2.png" />
            <h2>입시영상</h2>
          </div>
          {listvideo &&
            listvideo.map(
              (bbs, index) =>
                index < 3 && (
                  <Link to="/boardview/V" state={{ data: bbs }} key={index}>
                    <div className="playBtn">
                      <span>
                        <img src="/assets/common/play.png" />
                      </span>
                    </div>
                    <img src={config.FILEURL + bbs.img} />

                    <div>
                      <h2>{bbs.title}</h2>
                      <h3>
                        {bbs.writer}
                        <br />
                        {format(bbs.createDate, "yyyy-MM-dd")}
                      </h3>
                    </div>
                  </Link>
                )
            )}
        </div>
      </div>
      <div className="logoBanner">
        <LogoBanner />
      </div>
      <Footer />
    </div>
  );
};

export default IpsiMain;
