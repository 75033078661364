import React from "react";
import { Link } from "react-router-dom";

const leftMenu = () => {

return(
    <div className='leftMenu'>
        <h1>입시 관리</h1>
        <ul>
            <h2>입시</h2>
            <li>
                <Link to='/ipsiinfo'>입시</Link>
            </li>
        </ul>
        
    </div>
);
}

export default leftMenu;