import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>통계 관리</h1>
      <ul>
        <h2>통계</h2>
        <li>
          <Link
            to="/state"
            style={{
              color: location.pathname === "/state" && "#971b1e",
            }}
          >
            통계
          </Link>
        </li>
        <li>
          <Link
            to="/bannerstate"
            style={{
              color: location.pathname === "/bannerstate" && "#971b1e",
            }}
          >
            배너통계
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
