import React from "react";
import FullBanner from "../../component/fullBanner";
import AdLeftBanner from "../../component/leftBanner";
import LogoBanner from "../../component/logoBanner";
import QuickMenu from "../../component/quickMenu";
import QuickMenuR from "../../component/quickMenuR";
import SlideBanner from "../../component/slideBanner";
import "../../css/layout.scss";
import "../../css/main.scss";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
import BoardMain from "../board/boardMain";

const Main = () => {
  return (
    <>
      <Header />

      <div className="contents">
        <SlideBanner />
        <div
          className="subContent"
          style={{
            padding: "0",
          }}
        >
          <AdLeftBanner />
          <QuickMenuR />
          <div>
            <QuickMenu />
          </div>
        </div>
        <div className="">
          <FullBanner />
        </div>
        <BoardMain />
        <LogoBanner />
      </div>
      <Footer />
    </>
  );
};

export default Main;
