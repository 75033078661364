import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import config from "../config/config";

const NationalExpoModal = ({ isOpen, onClose, eventDate, local, id }) => {
  const [listdata, _listdata] = useState([]);

  const getHtml = async () => {
    try {
      const mode = "earlyexamunivlist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", local);
      const response = await axios.post(
        config.URL + "earlyExamUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, [local]);

  return (
    <form>
      <div className="overlay">
        <div
          className={`teacherExpoModal ${isOpen ? "" : "hidden"}`}
          style={{ zIndex: 200 }}
        >
          <img src="/assets/common/x.png" onClick={onClose} alt="x" />
          <h1>참여대학 리스트</h1>
          <div className="modalInfoBox">
            <h2>
              <span>
                개최일시: <strong>{format(eventDate, "MM월 dd일")}</strong>
              </span>
              <span style={{ paddingLeft: "50px" }}>
                개최지역:
                <strong>
                  {local === "I"
                    ? "인천"
                    : local === "S"
                    ? "수원"
                    : local === "E"
                    ? "의정부"
                    : local === "G"
                    ? "고양"
                    : local === "P"
                    ? "평택"
                    : ""}
                </strong>
              </span>
            </h2>
          </div>
          <table
            style={{
              width: "65%",
              border: "0",
              margin: "20px auto 0",
            }}
            className="table"
          >
            {local &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} style={{ lineHeight: "40px" }}>
                  <td
                    className="tableListTitle"
                    width={"30%"}
                    style={{ textAlign: "left", paddingLeft: "10px" }}
                  >
                    {univ.schoolName}
                  </td>
                  <td
                    className="tableListTitle"
                    width={"40%"}
                    style={{ textAlign: "right", paddingRight: "10px" }}
                  >
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </table>
          <button type="button" onClick={onClose} className="submitBtn">
            닫기
          </button>
        </div>
      </div>
    </form>
  );
};

export default NationalExpoModal;
