import axios from 'axios';
import React, { useState, useEffect } from "react";
import Header from '../layout/header';
import Footer from '../../../layout/footer';
import LeftMenu from './leftMenu';
import config from '../../../config/config';

import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

const Ipsiinfo = () => {
    const [editor, _editor] = useState("");
    const modules = {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              "link",
              { indent: "-1" },
              { indent: "+1" }
            ],
            [
              {
                color: [
                  "#000000",
                  "#e60000",
                  "#ff9900",
                  "#ffff00",
                  "#008a00",
                  "#0066cc",
                  "custom-color"
                ]
              },
              { background: [] }
            ],
            ["image", "video"]
          ]
        }
    };

    const getHtml = async () => {
        try{
            const mode = "agreement";
            const response = await axios.post( config.URL + "homepage.php", { mode });
            console.log(response)
            _editor(response.data.data.agreement);
        } catch(error){
            console.log("error", error);
        }
    }

    const postHtml = async () => {
      try{
        const mode = "agreementupdate";
        const response = await axios.post( config.URL + "homepage.php", {
          mode,
          editor 
        });
        console.log(response);
        alert(response.data.message);
        getHtml();
      } catch(error){
        console.log("error", error);
      }
    }

    useEffect(() => {
        getHtml();
    }, []);

    const EditorChange = value => {
        _editor(value);
    };

    return(
        <>
        <Header />
        <div className='subContent' style={{marginBottom:'0'}}>
            <LeftMenu />
            <div className='content'>
                <div className='substance'>
                    <h2><span></span>이용약관
                      <button type='button' onClick={postHtml}>
                        저장
                      </button>
                    </h2>
                </div>
                <ReactQuill
                    value={editor}
                    style={{ width: "100%", height: "250px" }}
                    modules={modules}
                    onChange={EditorChange}
                />
            </div>
        </div>
        <Footer />
        </>
    );
}

export default Ipsiinfo;