import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Select from "react-select";
import config from "../config/config";

const NationalExpoModal = ({ isOpen, onClose, eventDate, local, id }) => {
  const [scheduleSubmit, _scheduleSubmit] = useState(1);
  const [schoolName, _schoolName] = useState([]);
  const [highschool, _highschool] = useState();
  const [teacherName, _teacherName] = useState();
  const [grade, _grade] = useState("3학년");
  const [phone, _phone] = useState();
  const [interestUniv1, _interestUniv1] = useState();
  const [interestUniv2, _interestUniv2] = useState();
  const [interestUniv3, _interestUniv3] = useState();

  const [options, setOptions] = useState([]);
  const [scheduleTitle, setScheduleTitle] = useState(null);
  const [school, setSchool] = useState(null);

  const getSchoolList = async () => {
    try {
      const mode = "schoollist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(
        config.URL + "earlyExamSchedule.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _schoolName(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (schoolName) {
      const mappedOptions = schoolName.map((list) => ({
        value: list.area,
        label: "(" + list.area + ")" + list.schoolName,
      }));
      setOptions(mappedOptions);
    }
  }, [schoolName]);

  const handleChange = (value) => {
    console.log("value:", value);
    if (value) {
      setSchool(value);
      setScheduleTitle("(" + value.value + ")" + value.label);
      _schoolName(value.label);
    }
  };

  const postData = async () => {
    try {
      const mode = "scheduleadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", highschool);
      formData.append("teacherName", teacherName);
      formData.append("grade", grade);
      formData.append("phone", phone);
      formData.append("eventDate", eventDate);
      formData.append("local", local);
      formData.append("interestUniv1", interestUniv1);
      formData.append("interestUniv2", interestUniv2);
      formData.append("interestUniv3", interestUniv3);
      formData.append("id", id);

      const response = await axios.post(
        config.URL + "earlyExamSchedule.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const nextStep = () => {
    if (schoolName == null) {
      alert("학교를 선택해 주세요.");
      return;
    }
    if (teacherName == null) {
      alert("성명을 입력 주세요.");
      return;
    }
    if (grade == null) {
      alert("직책을 선택해 주세요.");
      return;
    }
    if (phone == null) {
      alert("연락처를 입력해 주세요.");
      return;
    }
    if (phone < 9) {
      alert("연락처를 정확히 입력해 주세요.");
      return;
    }
    _scheduleSubmit(scheduleSubmit + 1);
    postData();
  };

  //핸드폰 번호 입력 시 - 추가 기능
  const mobileNoChange = (event) => {
    const input = event.target.value.replace(/\D/g, "");

    let formattedInput = "";

    formattedInput = input
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/-{1,2}$/g, "");

    _phone(formattedInput);
  };

  useEffect(() => {
    getSchoolList();
  }, []);

  return (
    <form>
      {scheduleSubmit == 1 && (
        <div className="overlay">
          <div
            className={'teacherExpoModal ${isOpen ? "" : "hidden"}'}
            style={{ zIndex: 200, height: "600px" }}
          >
            <img src="/assets/common/x.png" onClick={onClose} />
            <h1>설명회 신청</h1>
            <div className="modalInfoBox">
              <h2>
                <span>
                  개최일시: <strong>{format(eventDate, "MM월 dd일")}</strong>
                </span>
                <span style={{ paddingLeft: "50px" }}>
                  개최지역:{" "}
                  <strong>
                    {local == "I"
                      ? "인천"
                      : local == "S"
                      ? "수원"
                      : local == "E"
                      ? "의정부"
                      : local == "G"
                      ? "고양"
                      : local == "P"
                      ? "평택"
                      : ""}
                  </strong>
                </span>
              </h2>
            </div>

            <div className="modalContent">
              <div className="modalSub" style={{ width: "500px" }}>
                <h3>고등학교 명</h3>
                <Select
                  value={school}
                  onChange={handleChange}
                  options={options}
                />
              </div>
            </div>

            <div className="addTeacher">
              <h3>신청인인 정보</h3>
              <div className="modalContent">
                <div
                  className="modalSub"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    height: "30px",
                    marginBottom: "20px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="성명"
                    value={teacherName}
                    onChange={(e) => _teacherName(e.target.value)}
                  />
                  <select
                    className="teacherTypeSelect"
                    value={grade}
                    onChange={(e) => _grade(e.target.value)}
                  >
                    <option value="3학년">3학년</option>
                    <option value="2학년">2학년</option>
                    <option value="1학년">1학년</option>
                  </select>
                  <input
                    type="text"
                    placeholder="연락처를 입력해주세요"
                    value={phone}
                    onChange={(e) => {
                      mobileNoChange(e);
                    }}
                    style={{ width: "170px" }}
                  />
                </div>
              </div>
            </div>

            {/* <div className="modalContent">
              <div className="modalSub">
                <h3>신청인 성명</h3>
                <input
                  type="text"
                  value={teacherName}
                  onChange={(e) => _teacherName(e.target.value)}
                ></input>
              </div>
              <div className="modalSub">
                <h3>학년</h3>
                <select
                  className="teacherTypeSelect"
                  value={grade}
                  onChange={(e) => _grade(e.target.value)}
                >
                  <option value="3학년">3학년</option>
                  <option value="2학년">2학년</option>
                  <option value="1학년">1학년</option>
                </select>
              </div>
              <div className="modalSub">
                <h3>연락처</h3>
                <input
                  type="text"
                  placeholder="-없이 입력해주세요"
                  value={phone}
                  onChange={(e) => _phone(e.target.value)}
                  style={{ width: "160px" }}
                />
              </div>
            </div> */}

            <div
              className="modalContent"
              style={{ borderBottom: "1px solid #dfdfdf" }}
            >
              {/* <div className="modalSub">
                <h3>관심 대학</h3>
                <div
                  className="modalSub"
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    style={{ width: "100px" }}
                    onChange={(e) => _interestUniv1(e.target.value)}
                  ></input>
                  <input
                    type="text"
                    style={{ width: "100px" }}
                    onChange={(e) => _interestUniv2(e.target.value)}
                  ></input>
                  <input
                    type="text"
                    style={{ width: "100px" }}
                    onChange={(e) => _interestUniv3(e.target.value)}
                  ></input>
                </div>
              </div> */}
            </div>
            <button type="button" className="submitBtn" onClick={nextStep}>
              신청하기
            </button>
          </div>
        </div>
      )}
      {scheduleSubmit == 2 && (
        <div className="overlay">
          <div
            className={'completePage ${isOpen ? "" : "hidden"}'}
            style={{ zIndex: 200 }}
          >
            <img src="/assets/common/x.png" onClick={onClose} />
            <h1>
              신청이 완료되었습니다.
              <br />
              신청해 주셔서 감사합니다.
            </h1>
            <h2>
              신청내역은 마이페이지에서
              <br />
              확인 하실 수 있습니다.
            </h2>
          </div>
        </div>
      )}
    </form>
  );
};

export default NationalExpoModal;
