import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import AdLeftBanner from "../../component/leftBanner";
import NationalExpoModal from "../../component/nationalExpoModal";
import NationalExpoUnivModal from "../../component/nationalExpoUnivModal";
import QuickMenu from "../../component/quickMenu";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import Footer from "./../../layout/footer";
import Header from "./../../layout/header";

import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const NationalExpo = () => {
  const [isModalOpen, _isModalOpen] = useState(false);
  const [isUnivModalOpen, _isUnivModalOpen] = useState(false);
  const [scheduleList, _scheduleList] = useState([]);
  const [collegeList, _collegeList] = useState([]);
  const [eventDate, _eventDate] = useState();
  const [local, _local] = useState();
  const [address, _address] = useState();
  const [id, _id] = useState();

  //대학리스트 불러오기
  const getCollegeList = async () => {
    try {
      const mode = "teacherunivlist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(
        config.URL + "teacherUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _collegeList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //스케쥴 가저오기기
  const getScheduleList = async () => {
    try {
      const mode = "earlyexamschedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(
        config.URL + "earlyExamSchedule.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _scheduleList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const openModal = (eventDate, local, id) => {
    _eventDate(eventDate);
    _local(local);
    _id(id);
    _isModalOpen(true);
  };

  const openUnivModal = (eventDate, local, id) => {
    _eventDate(eventDate);
    _local(local);
    _id(id);
    _isUnivModalOpen(true);
  };

  const closeModal = () => {
    _isModalOpen(false);
  };

  const closeUnivModal = () => {
    _isUnivModalOpen(false);
  };

  useEffect(() => {
    getCollegeList();
    getScheduleList();
  }, []);

  const endDate = (eventDate) => {
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    const checkDate = dayjs(eventDate).add(-1, "day").format("YYYY-MM-DD");
    if (today > checkDate) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <div className="expoBannerBox">
        <div className="expoBanner">
          <h1>전국순회 박람회</h1>
          <h2>
            성공적인 입시를 위한 전국의 모든 대학들의 입시정보
            <br />
            아이앤유니와 함께 완벽 대비하세요!
          </h2>
        </div>
        <img
          src="/assets/exposition/localExpoBanner.png"
          data-aos="fade-left"
          data-aos-duration="1000"
          alt="localexpobanner"
        />
      </div>
      <div className="univLinkTitle" style={{ marginBottom: "10px" }}>
        <h1>
          <span></span>박람회 일정
        </h1>
      </div>
      <table className="expoScheduleTable">
        <tr className="expoScheduleTitle">
          <td>박람회 장소</td>
          <td>박람회 일시</td>
          <td>박람회장 주소</td>
          <td>참여대학</td>
        </tr>
        {scheduleList &&
          scheduleList.map((schedule, index) => (
            <tr key={index}>
              <td className="tableListTitle">
                {schedule.local == "I"
                  ? "인천"
                  : schedule.local == "S"
                  ? "수원"
                  : schedule.local == "E"
                  ? "의정부"
                  : schedule.local == "G"
                  ? "고양"
                  : schedule.local == "P"
                  ? "평택"
                  : ""}
              </td>
              <td className="tableListTitle">
                {format(schedule.eventDate, "yyyy-MM-dd")}
              </td>
              <td className="tableListTitle">{schedule.address}</td>
              <td className="tableListTitle">
                <button
                  type="button"
                  onClick={() => {
                    openUnivModal(
                      schedule.eventDate,
                      schedule.local,
                      schedule.id
                    );
                  }}
                >
                  참여대학 확인하기
                </button>
              </td>
            </tr>
          ))}
      </table>
      <img
        src="/assets/exposition/localIpsiBanner.png"
        className="localIpsiBanner"
        alt="localipsibanner"
      />
      <div className="univLinkTitle">
        <h1>
          <span></span>박람회 신청
        </h1>
        <div
          className="scheduleList"
          style={{
            gridTemplateColumns: "repeat(5, 1fr)",
            marginBottom: "50px",
          }}
        >
          {scheduleList &&
            scheduleList.map((scheduleList, index) => (
              <div key={index} className="nScheduleContent">
                <h2>
                  {scheduleList.local === "I"
                    ? "인천"
                    : scheduleList.local === "S"
                    ? "수원"
                    : scheduleList.local === "E"
                    ? "의정부"
                    : scheduleList.local === "G"
                    ? "고양"
                    : scheduleList.local === "P"
                    ? "평택"
                    : ""}{" "}
                  박람회
                </h2>
                {endDate(scheduleList.eventDate) ? (
                  <button
                    type="button"
                    onClick={() => {
                      alert("신청이 마감 되었습니다.");
                    }}
                    style={{ backgroundColor: "#bbb" }}
                  >
                    마감
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      openModal(
                        scheduleList.eventDate,
                        scheduleList.local,
                        scheduleList.id
                      );
                    }}
                  >
                    {" "}
                    신청{" "}
                  </button>
                )}
                <button
                  type="button"
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #bbb",
                    color: "#333",
                    fontWeight: "500",
                    lineHeight: "30px",
                  }}
                  onClick={() => {
                    openUnivModal(
                      scheduleList.eventDate,
                      scheduleList.local,
                      scheduleList.id
                    );
                  }}
                >
                  참여대학
                </button>
              </div>
            ))}
        </div>
      </div>
      {isModalOpen && (
        <NationalExpoModal
          isOpen={isModalOpen}
          onClose={closeModal}
          eventDate={eventDate}
          local={local}
          id={id}
        />
      )}
      {isUnivModalOpen && (
        <NationalExpoUnivModal
          isOpen={isUnivModalOpen}
          onClose={closeUnivModal}
          eventDate={eventDate}
          local={local}
          id={id}
        />
      )}
      <QuickMenu type="margin" />
      <Footer />
    </div>
  );
};

export default NationalExpo;
