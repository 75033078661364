import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../../config/config";
import "./../../../css/admin.scss";
import Footer from "./../../../layout/footer";
import Header from "./../layout/header";

const AdminMain = () => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [tabState, _tabState] = useState("seoul");
  const [scheduleList, _scheduleList] = useState([]);

  const koreanButtonText = {
    today: "오늘",
    prevYear: "작년",
    nextYear: "내년",
    month: "월",
    week: "주",
    day: "일",
    list: "목록",
  };

  //대학리스트 불러오기
  const getHtml = async () => {
    try {
      const mode = "univlist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(config.URL + "univ.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  //스케쥴 가저오기
  const getCalendar = async () => {
    try {
      const mode = "schedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const data = response.data.data;
      if (data) {
        const scheduleDataList = data.map((item) => ({
          title: item.schoolName,
          date: item.scheduleDate,
          idx: item.id,
          pinCode: item.pinCode,
        }));
        _scheduleList(scheduleDataList);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCalendar();
  }, []);

  useEffect(() => {
    page == "S"
      ? _tabState("S")
      : page == "C"
      ? _tabState("C")
      : page == "H"
      ? _tabState("H")
      : page == "G"
      ? _tabState("G")
      : _tabState("S");
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [tabState]);

  return (
    <>
      <Header />
      <div className="subContent" style={{ flexDirection: "column" }}>
        <div className="titleBox">
          <h1>
            <span></span>주요일정
          </h1>
          <Link to="/exposcheduler">관리</Link>
        </div>
        <div className="calendarOutline">
          <div className="calendarBox">
            <FullCalendar
              dayMaxEvents={true}
              plugins={[dayGridPlugin, interactionPlugin]}
              buttonText={koreanButtonText}
              weekends="false"
              hiddenDays={[0, 6]}
              locale={"ko"}
              initialView="dayGridMonth"
              headerToolbar={{
                left: "prev,today",
                center: "title",
                right: "next",
              }}
              events={scheduleList}
            />
          </div>
          <div className="weekSchedule">
            <FullCalendar
              dayMaxEvents={true}
              plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
              buttonText={koreanButtonText}
              weekends="false"
              hiddenDays={[0, 6]}
              locale={"ko"}
              initialView="listWeek"
              headerToolbar={{
                left: "prev,today",
                center: "title",
                right: "next",
              }}
              events={scheduleList}
              height={"656px"}
            />
          </div>
        </div>
      </div>
      <div className="collegeListOutline">
        <div className="titleBox">
          <h1>
            <span></span>입시정보 대학교 리스트
          </h1>
          <Link to="/univ">관리</Link>
        </div>
        <ul className="companyUl">
          <li
            className={tabState === "S" ? "tabOn" : ""}
            onClick={() => {
              _tabState("S");
            }}
          >
            서울·경기권
          </li>
          <li
            className={tabState === "C" ? "tabOn" : ""}
            onClick={() => {
              _tabState("C");
            }}
          >
            대전·충청권
          </li>
          <li
            className={tabState === "H" ? "tabOn" : ""}
            onClick={() => {
              _tabState("H");
            }}
          >
            호남권
          </li>
          <li
            className={tabState === "G" ? "tabOn" : ""}
            onClick={() => {
              _tabState("G");
            }}
          >
            부산·울산·경남·제주권
          </li>
        </ul>
        <div className="collegeProfileInline">
          {tabState == "S" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
          {tabState == "C" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
          {tabState == "H" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
          {tabState == "G" && (
            <>
              {listdata &&
                listdata.map(
                  (univ, index) =>
                    index < 8 && (
                      <div key={index} className="collegeProfile">
                        <img src={config.FILEURL + univ.emblem} />
                        <div className="profile_txt">
                          <h2>{univ.schoolName}</h2>
                          <ul>
                            <a
                              href={univ.earlyAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              수시
                            </a>
                            <a
                              href={univ.regularAdmissionLink}
                              target="_blank"
                              rel="noreferrer"
                              className="li original"
                            >
                              정시
                            </a>
                            <a
                              href={univ.homepage}
                              target="_blank"
                              rel="noreferrer"
                              className="li home"
                            >
                              홈페이지
                            </a>
                          </ul>
                        </div>
                      </div>
                    )
                )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminMain;
