import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const VisitingExpoSchedule = () => {
  const [listdata, _listdata] = useState([]);
  const [excelData, _excelData] = useState([]);

  const getHtml = async () => {
    try {
      const mode = "schedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(config.URL + "schedule.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      _listdata(response.data.data);
      _excelData(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = "excel_data";

  const excelDownload = (excelData) => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "행사날자",
        "행사시간",
        "고등학교명",
        "신청장소",
        "신청인",
        "휴대폰",
        "직통번호",
        "신청현황",
      ],
    ]);

    excelData.forEach((data) => {
      const scheduleState = data.cancel == "N" ? "신청" : "신청취소";
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            data.scheduleDate,
            data.scheduleTime,
            data.schoolName,
            data.place == "G"
              ? "체육관"
              : data.place == "H"
              ? "강당"
              : data.place == "C"
              ? "교실"
              : data.place == "E"
              ? "기타"
              : "",
            data.teacherName,
            data.phone,
            data.schoolPhone,
            scheduleState,
          ],
        ],
        {
          origin: -1,
        }
      );
      return false;
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelBuffer], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
  };

  useEffect(() => {
    getHtml();
  }, []);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>찾아가는 박람회 신청 내역
            </h2>
          </div>
          <div className="substance">
            <h2>
              <button type="button" onClick={() => excelDownload(excelData)}>
                excel
              </button>
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width={"10%"}>
                행사 날자
              </td>
              <td className="tableListTitle" width={"13%"}>
                행사 시간
              </td>
              <td className="tableListTitle" width={"19%"}>
                고등학교명
              </td>
              <td className="tableListTitle" width={"15%"}>
                신청 장소
              </td>
              <td className="tableListTitle" width={"15%"}>
                신청인
              </td>
              <td className="tableListTitle" width={"18%"}>
                휴대폰
              </td>
              <td className="tableListTitle" width={"10%"}>
                신청현황
              </td>
            </tr>
            {listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">
                    {format(schedule.scheduleDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.scheduleTime}</td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">
                    {schedule.place == "G"
                      ? "체육관"
                      : schedule.place == "H"
                      ? "강당"
                      : schedule.place == "C"
                      ? "교실"
                      : schedule.place == "E"
                      ? "기타"
                      : ""}
                  </td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VisitingExpoSchedule;
