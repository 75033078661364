import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FairSchedule = () => {
  const [listdata, _listdata] = useState([]);
  const [type, _type] = useState("N");
  const [local, _local] = useState("I");
  const [address, _address] = useState();
  const [eventDate, _eventDate] = useState(new Date());
  const [startTime, _startTime] = useState(10);
  const [endTime, _endTime] = useState(16);
  const [id, _id] = useState("");

  const getHtml = async () => {
    try {
      const mode = "earlyexamschedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      const response = await axios.post(
        config.URL + "earlyExamSchedule.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "earlyexamscheduleadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("local", local);
      formData.append("address", address);
      formData.append("eventDate", format(eventDate, "yyyyMMdd"));
      formData.append("startTime", startTime);
      formData.append("endTime", endTime);

      const response = await axios.post(
        config.URL + "earlyExamSchedule.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (schedule) => {
    _local(schedule.local);
    _address(schedule.address);
    _eventDate(schedule.eventDate);
    _startTime(schedule.startTime);
    _endTime(schedule.endTime);
    _id(schedule.id);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "earlyexamscheduleupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("local", local);
      formData.append("address", address);
      formData.append("eventDate", format(eventDate, "yyyyMMdd"));
      formData.append("startTime", startTime);
      formData.append("endTime", endTime);
      formData.append("id", id);

      const response = await axios.post(
        config.URL + "earlyExamSchedule.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    getHtml();

    let today = new Date();
    _eventDate(today);
    let nextDay = new Date();
    let plusMonth = nextDay.setMonth(nextDay.getMonth() + 1);
    _eventDate(plusMonth);
  }, []);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>전국순회박람회 일정 관리
              {type == "N" ? (
                <button onClick={() => postData()}>추가</button>
              ) : (
                <>
                  <button onClick={reload}>초기화</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">행사지역</td>
              <select onChange={(e) => _local(e.target.value)}>
                <option value={"I"}>인천</option>
                <option value={"S"}>수원</option>
                <option value={"E"}>의정부</option>
                <option value={"G"}>고양</option>
                <option value={"P"}>평택</option>
              </select>
              <td className="tableTitle">박람회장 주소</td>
              <td colSpan={3}>
                <input
                  type="text"
                  value={address}
                  onChange={(e) => _address(e.target.value)}
                  style={{ width: "90%", height: "30px", border: "0" }}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">행사 개최일</td>
              <td>
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={eventDate}
                  onChange={(date) => _eventDate(date)}
                />
              </td>
              <td className="tableTitle">행사 시작시간</td>
              <td>
                <select onChange={(e) => _startTime(e.target.value)}>
                  <option value={"10"}>10:00</option>
                  <option value={"11"}>11:00</option>
                  <option value={"12"}>12:00</option>
                  <option value={"13"}>13:00</option>
                  <option value={"14"}>14:00</option>
                </select>
              </td>
              <td className="tableTitle">행사 종료시간</td>
              <td>
                <select onChange={(e) => _endTime(e.target.value)}>
                  <option value={"16"}>16:00</option>
                  <option value={"17"}>17:00</option>
                  <option value={"18"}>18:00</option>
                  <option value={"19"}>19:00</option>
                  <option value={"20"}>20:00</option>
                </select>
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>행사일정 목록
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width={"15%"}>
                행사장소
              </td>
              <td className="tableListTitle" width={"20%"}>
                행사 개최일
              </td>
              <td className="tableListTitle">행사장 주소</td>
              <td className="tableListTitle" width={"25%"}>
                행사 시간
              </td>
            </tr>
            {listdata &&
              listdata.map((schedule, index) => (
                <tr key={index} onClick={() => changeData(schedule)}>
                  <td className="tableListTitle">
                    {schedule.local == "I"
                      ? "인천"
                      : schedule.local == "S"
                      ? "수원"
                      : schedule.local == "E"
                      ? "의정부"
                      : schedule.local == "G"
                      ? "고양"
                      : schedule.local == "P"
                      ? "평택"
                      : ""}
                  </td>
                  <td className="tableListTitle">
                    {format(schedule.eventDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.address}</td>
                  <td className="tableListTitle">
                    {schedule.startTime}:00 ~{schedule.endTime}:00
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FairSchedule;
