import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

const History = () => {
  const [listdata, _listdata] = useState([]);
  const [type, _type] = useState("N");
  const [year, _year] = useState("");
  const [month, _month] = useState("");
  const [content, _content] = useState("");
  const [hidden, _hidden] = useState("");
  const [id, _id] = useState("");

  const getHtml = async () => {
    try {
      const mode = "companyhistory";
      const response = await axios.post(config.URL + "homepage.php", { mode });
      console.log(response);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const reload = () => {
    window.location.reload();
  };

  const postData = async () => {
    try {
      const mode = "companyhistoryadd";
      await axios.post(config.URL + "homepage.php", {
        mode,
        year,
        month,
        content,
        hidden,
      });

      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeHistory = async (history) => {
    _year(history.year);
    _month(history.month);
    _content(history.content);
    _hidden(history.hidden);
    _id(history.id);
    _type("Y");
  };

  const updateHistory = async () => {
    try {
      const mode = "companyhistoryupdate";
      const response = await axios.post(config.URL + "homepage.php", {
        mode,
        year,
        month,
        content,
        hidden,
        id,
      });
      reload();
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, []);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>연혁관리
              {type == "N" ? (
                <button onClick={() => postData()}>추가</button>
              ) : (
                <>
                  <button onClick={reload}>초기화</button>
                  <button
                    onClick={updateHistory}
                    style={{ marginRight: "10px" }}
                  >
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">년도</td>
              <td>
                <input
                  type="text"
                  value={year}
                  onChange={(e) => _year(e.target.value)}
                />
              </td>
              <td className="tableTitle">월</td>
              <td>
                <input
                  type="text"
                  value={month}
                  onChange={(e) => _month(e.target.value)}
                />
              </td>
              <td className="tableTitle">사용여부</td>
              <td>
                <input
                  value="Y"
                  checked={hidden == "Y"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                Y
                <input
                  value="N"
                  checked={hidden == "N"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                N
              </td>
            </tr>
            <tr>
              <td className="tableTitle">내용</td>
              <td colSpan={5}>
                <input
                  type="text"
                  style={{ width: "90%" }}
                  value={content}
                  onChange={(e) => _content(e.target.value)}
                />
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>연혁목록
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" style={{ width: "120px" }}>
                년도
              </td>
              <td className="tableListTitle" style={{ width: "120px" }}>
                월
              </td>
              <td className="tableListTitle">내용</td>
              <td className="tableListTitle" style={{ width: "80px" }}>
                사용여부
              </td>
            </tr>
            {listdata &&
              listdata.map((history, index) => (
                <tr key={index} onClick={() => changeHistory(history)}>
                  <td className="tableListTitle" style={{ width: "120px" }}>
                    {history.year}
                  </td>
                  <td className="tableListTitle" style={{ width: "120px" }}>
                    {history.month}
                  </td>
                  <td
                    className="tableListTitle"
                    style={{ textAlign: "left", paddingLeft: "10px" }}
                  >
                    {history.content}
                  </td>
                  <td className="tableListTitle" style={{ width: "80px" }}>
                    {history.hidden}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default History;
