import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

const SellProduct = () => {
  const { page } = useParams();
  const [type, _type] = useState("N");
  const [listdata, _listdata] = useState([]);
  const [tabState, _tabState] = useState(page);
  const [content, _content] = useState("");
  const [img, _img] = useState("");
  const [preview, _preview] = useState("");
  const [title, _title] = useState("");
  const [hidden, _hidden] = useState("N");
  const [width, _width] = useState("");
  const [height, _height] = useState("");
  const [startDate, _startDate] = useState(new Date());
  const [endDate, _endDate] = useState(new Date());
  const [id, _id] = useState("");

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          "link",
          { indent: "-1" },
          { indent: "+1" },
        ],
        [
          {
            color: [
              "#000000",
              "#e60000",
              "#ff9900",
              "#ffff00",
              "#008a00",
              "#0066cc",
              "custom-color",
            ],
          },
          { background: [] },
        ],
        // ["image", "video"],
      ],
    },
  };

  const getHtml = async () => {
    try {
      const mode = "sellproduct";

      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);

      const response = await axios.post(
        config.URL + "sellProduct.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      // _content(response.data.data);
      _listdata(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "sellproductadd";

      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      formData.append("title", title);
      formData.append("hidden", hidden);
      formData.append("width", width);
      formData.append("height", height);
      formData.append("startDate", format(startDate, "yyyyMMdd"));
      formData.append("endDate", format(endDate, "yyyyMMdd"));
      formData.append("content", content);
      formData.append("id", id);
      img && formData.append("img", img);

      const response = await axios.post(
        config.URL + "sellProduct.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      alert(response.data.message);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (product) => {
    _title(product.title);
    _hidden(product.hidden);
    _width(product.width);
    _height(product.height);
    _content(product.content);
    _startDate(product.startDate);
    _endDate(product.endDate);
    _preview(config.FILEURL + product.img);
    _id(product.id);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "sellproductupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      formData.append("title", title);
      formData.append("hidden", hidden);
      formData.append("width", width);
      formData.append("height", height);
      formData.append("startDate", format(startDate, "yyyyMMdd"));
      formData.append("endDate", format(endDate, "yyyyMMdd"));
      formData.append("content", content);
      formData.append("id", id);
      img && formData.append("img", img);

      const response = await axios.post(
        config.URL + "sellProduct.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      alert(response.data.message);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteCheck = () => {
    if (window.confirm("삭제하시겠습니까? 복구할 수 없습니다.")) {
      deleteData();
    } else {
      return;
    }
  };

  const deleteData = async () => {
    try {
      const mode = "sellproductdel";
      const formData = new FormData();
      formData.append("id", id);
      formData.append("mode", mode);

      const response = await axios.post(
        config.URL + "sellProduct.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (page) {
      _tabState(page);
    } else {
      _tabState("H");
    }

    let today = new Date();
    _startDate(today);
    let nextDay = new Date();
    let plusMonth = nextDay.setMonth(nextDay.getMonth() + 1);
    _endDate(plusMonth);
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [tabState]);

  const EditorChange = (value) => {
    _content(value);
  };

  const ImgFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      _img(file);
      _preview(URL.createObjectURL(file));
    }
  };

  const reload = () => {
    window.location.reload();
  };

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>
              {tabState == "H"
                ? "고교게시판"
                : tabState == "T"
                ? "상위권게시판"
                : tabState == "L"
                ? "지역게시판"
                : tabState == "B"
                ? "모집요강배포"
                : tabState == "F"
                ? "편입홍보"
                : tabState == "P"
                ? "매체대행"
                : "고교게시판"}
              {type == "N" ? (
                <>
                  <button onClick={() => postData()}>추가</button>
                  <button onClick={reload} style={{ marginRight: "10px" }}>
                    초기화
                  </button>
                </>
              ) : (
                <>
                  <button onClick={deleteCheck}>삭제</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                  <button onClick={reload} style={{ marginRight: "10px" }}>
                    초기화
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">제목</td>
              <td>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => _title(e.target.value)}
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
              <td className="tableTitle" width={"10%"}>
                사용여부
              </td>
              <td width={"20%"}>
                <input
                  value="N"
                  checked={hidden == "N"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                사용
                <input
                  value="Y"
                  checked={hidden == "Y"}
                  onChange={(e) => _hidden(e.target.value)}
                  type="radio"
                  className="tableRadio"
                />
                미사용
              </td>
              <td className="tableTitle" rowSpan={3} width={"10%"}>
                첨부파일
              </td>
              <td rowSpan={3} width={"20%"}>
                {preview && (
                  <img
                    src={preview}
                    alt="미리보기"
                    style={{ width: "100px", height: "100px" }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={ImgFileChange}
                  style={{ width: "90%" }}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle" rowSpan={2}>
                크기
              </td>
              <td rowSpan={2}>
                <input
                  type="text"
                  value={width}
                  onChange={(e) => _width(e.target.value)}
                  placeholder="가로: (숫자만)"
                  style={{ width: "45%", height: "30px", border: "0" }}
                />
                x
                <br />
                <input
                  type="text"
                  value={height}
                  onChange={(e) => _height(e.target.value)}
                  placeholder="세로: (숫자만)"
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
              <td className="tableTitle">시작일</td>
              <td>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={startDate}
                  onChange={(date) => _startDate(date)}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">종료일</td>
              <td>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  selected={endDate}
                  onChange={(date) => _endDate(date)}
                />
              </td>
            </tr>
            <tr>
              <td className="tableTitle">내용</td>
              <td colSpan={5} style={{ height: "290px" }}>
                <ReactQuill
                  value={content}
                  style={{ width: "100%", height: "250px", marginTop: "-41px" }}
                  modules={modules}
                  onChange={EditorChange}
                />
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>게시판 목록
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle">게시판</td>
              <td className="tableListTitle">내용</td>
              <td className="tableListTitle" width="10%">
                사용기간
              </td>
              <td className="tableListTitle" width="7%">
                사용여부
              </td>
            </tr>
            {listdata &&
              listdata.map((product, index) => (
                <tr key={index} onClick={() => changeData(product)}>
                  <td className="tableListTitle">
                    <p style={{ margin: "10px" }}>{product.title}</p>
                    <br />
                    <img
                      src={config.FILEURL + product.img}
                      alt={index}
                      style={{ width: "200px", height: "200px" }}
                    />
                  </td>
                  <td className="tableListTitle">
                    {product.width}x{product.height}
                    <br />
                    <div
                      dangerouslySetInnerHTML={{ __html: product.content }}
                    ></div>
                  </td>
                  <td className="tableListTitle">
                    {format(product.startDate, "yyyy-MM-dd")}~<br />
                    {format(product.endDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">
                    {product.hidden == "Y"
                      ? "미사용"
                      : product.hidden == "N"
                      ? "사용"
                      : ""}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SellProduct;
