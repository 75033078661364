import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const BoardView = () => {
  const location = useLocation();
  const bbs = location.state.data;
  const navigate = useNavigate();
  const { page } = useParams();

  const updateView = async () => {
    try {
      const mode = "bbsview";
      const id = bbs.id;
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("id", id);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    updateView();
  }, [page, bbs]);

  const handleBack = (bbsType) => {
    navigate(`/boardlist/${bbsType}`);
  };

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <div className="communityBanner">
        <h1>
          {page === "N" && "입시뉴스"}
          {page === "R" && "입시리포트"}
          {page === "V" && "입시영상"}
          {page === "O" && "공지사항"}
          {page === "E" && "이벤트"}

          {page === "CI" && "입시"}
          {page === "CS" && "학습"}
          {page === "CC" && "진로·취업"}
          {page === "CL" && "생활"}
          {page === "CM" && "학과별 게시판"}
        </h1>
      </div>
      <div className="handleBakcBtn">
        <button onClick={() => handleBack(bbs.bbsType)}>목록</button>
      </div>
      <div className="boardListContent" style={{ paddingBottom: "30px" }}>
        <h2 className="boardTitle">{bbs.title}</h2>
        <div className="boardInfo">
          <span>{bbs.writer}</span>
          <span className="txtBar"></span>
          <span>{bbs.createDate}</span>
          <span style={{ marginLeft: "auto" }}>조회수 {bbs.view}</span>
        </div>
        {bbs.img && bbs.bbsType !== "V" && (
          <img src={config.FILEURL + bbs.img} alt="img" />
        )}
        <p dangerouslySetInnerHTML={{ __html: bbs.content }} />
        {/* <ul className="boardCommendList">
        <li className="boardCommendCount">
          댓글 4개
        </li>
        <li>댓2</li>
        <li>댓3</li>
      </ul> */}
      </div>
      <Footer />
    </>
  );
};

export default BoardView;
