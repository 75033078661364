import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>게시판 관리</h1>
      <ul>
        <h2>공지사항/이벤트</h2>
        <li>
          <Link
            to="/adminnotice/O"
            style={{
              color: location.pathname === "/adminnotice/O" && "#971b1e",
            }}
          >
            공지사항
          </Link>
          <Link
            to="/adminnotice/E"
            style={{
              color: location.pathname === "/adminnotice/E" && "#971b1e",
            }}
          >
            이벤트
          </Link>
        </li>
      </ul>
      <ul>
        <h2>학생들의이야기</h2>
        <li>
          <Link
            to="/adminnotice/CI"
            style={{
              color: location.pathname === "/adminnotice/CI" && "#971b1e",
            }}
          >
            입시
          </Link>
          <Link
            to="/adminnotice/CS"
            style={{
              color: location.pathname === "/adminnotice/CS" && "#971b1e",
            }}
          >
            학습
          </Link>
          {/* <Link
            to="/adminnotice/CL"
            style={{
              color: location.pathname === "/adminnotice/CL" && "#971b1e",
            }}
          >
            생활
          </Link> */}
          <Link
            to="/adminnotice/CC"
            style={{
              color: location.pathname === "/adminnotice/CC" && "#971b1e",
            }}
          >
            진로·취업
          </Link>
          {/* <Link
            to="/adminnotice/CM"
            style={{
              color: location.pathname === "/adminnotice/CM" && "#971b1e",
            }}
          >
            학과별 게시판
          </Link> */}
        </li>
      </ul>
      <ul>
        <h2>입시정보</h2>
        <li>
          <Link
            to="/adminnotice/N"
            style={{
              color: location.pathname === "/adminnotice/N" && "#971b1e",
            }}
          >
            입시뉴스
          </Link>
          <Link
            to="/adminnotice/V"
            style={{
              color: location.pathname === "/adminnotice/V" && "#971b1e",
            }}
          >
            입시영상
          </Link>
        </li>
      </ul>
      <ul>
        <h2>문의하기</h2>
        <li>
          <Link
            to="/adminnotice"
            style={{
              color: location.pathname === "/adminnotice" && "#971b1e",
            }}
          >
            문의하기
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
