import React, { useEffect, useState } from "react";
import logo from "../assets/inuni_logo.png";
import "../css/layout.scss";
import AgreementModal from "./../config/AgreementModal";
import PrivacyPoliceModal from "./../config/PrivacyPolicyModal";

const Footer = (props) => {
  const [cName, _cName] = useState();
  const [policy, _policy] = useState(false);
  const [agreement, _agreement] = useState(false);

  const openPolicy = () => {
    _policy(true);
  };

  const closePolicy = () => {
    _policy(false);
  };

  const openAgreement = () => {
    _agreement(true);
  };

  const closeAgreement = () => {
    _agreement(false);
  };

  useEffect(() => {
    props.type === "login"
      ? _cName("footer_fullBox_login")
      : _cName("footer_fullBox");
  }, [props.type]);
  return (
    <>
      {policy && <PrivacyPoliceModal isOpen={policy} onClose={closePolicy} />}
      {agreement && (
        <AgreementModal isOpen={agreement} onClose={closeAgreement} />
      )}
      <div className={cName}>
        <div className="footer_layout">
          <div>
            <ul className="footer_ul">
              <li>
                <button onClick={() => openPolicy()}>개인정보처리방침</button>
              </li>
              <span className="txt_bar_b"></span>
              <li>
                <button onClick={() => openAgreement()}>이용약관</button>
              </li>
            </ul>
            <p className="footer_info">
              대표 : 이봉운
              <span className="txt_bar_b"></span>
              사업자등록번호 : 214-86-62588
              <span className="txt_bar_b"></span>
              FAX : 02-2624-1805
            </p>
            <p className="footer_info">
              대표번호 : 02-2624-1800
              <span className="txt_bar_b"></span>
              주소 : 서울시 금천구 가산디지털1로 145, 408호
            </p>
            <p className="footer_info" style={{ marginTop: "10px" }}>
              COPYRIGHT © 2024 아이앤유니. All Rights Reserved.
            </p>
          </div>
          <a href="/" className="footer_logo">
            <img src={logo} className="footer_logo" alt="inuni_logo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
