import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const BoardWrite = () => {
  const page = useParams();
  const user = useSelector((state) => state.auth.user);
  const [listdata, _listdata] = useState([]);
  const [bbsType, _bbsType] = useState("");
  const [content, _content] = useState("");
  const [title, _title] = useState("");
  const [writer, _writer] = useState("");
  const [img, _img] = useState("");
  const [hidden, _hidden] = useState("Y");
  const [id, _id] = useState("");

  const getHtml = async () => {
    try {
      const mode = "bbslist";
      const response = await axios.post(config.URL + "bbs.php", { mode });
      console.log(response);
      _listdata(response.data.data.title);
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "bbsadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", bbsType);
      formData.append("title", title);
      formData.append("content", content);
      formData.append("writer", writer);
      formData.append("hidden", hidden);
      img && formData.append("img", img);

      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, [bbsType, page]);

  const ImgFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      _img(file);
    }
  };

  const textarea = useRef();

  const handleResizeHeight = () => {
    textarea.current.style.height = "auto"; //height 초기화
    textarea.current.style.height = textarea.current.scrollHeight + "px";
  };

  return (
    <>
      <Header />
      <img
        src="/assets/community/commuBanner.png"
        className="communityBanner"
      />
      <h2 className="boardWriteTitle">
        <span></span>
        글쓰기
      </h2>
      <table className="boardWriteTable">
        <tbody>
          <tr>
            <td className="boardWriteHeadline" width={"10%"}>
              게시글 유형
            </td>
            <td>
              <select>
                <option value="">입시</option>
                <option value="">학습</option>
                <option value="">진로·취업</option>
                <option value="">생활</option>
                <option value="">학과별 게시판</option>
              </select>
            </td>
          </tr>
          <tr>
            <td className="boardWriteHeadline" width={"10%"}>
              작성자
            </td>
            <td>
              {user ? (
                user.nickName
              ) : (
                <input
                  type="text"
                  value={writer}
                  onChange={(e) => _writer(e.target.value)}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="boardWriteHeadline" width={"10%"}>
              제목
            </td>
            <td>
              <input
                type="text"
                value={title}
                onChange={(e) => _title(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="boardWriteHeadline" width={"10%"}>
              내용
            </td>
            <td>
              <textarea
                value={content}
                onChange={(e) => _content(e.target.value)}
                cols={155}
                rows={10}
              ></textarea>
            </td>
          </tr>
          <tr>
            <td className="boardWriteHeadline" width={"10%"}>
              첨부파일
            </td>
            <td>
              <input
                type="file"
                accept="image/*"
                onChange={ImgFileChange}
                style={{ width: "90%" }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="okQa">
        <button onClick={postData}>등록하기</button>
      </div>
      <Footer />
    </>
  );
};

export default BoardWrite;
