import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../../../layout/footer";
import LeftMenu from "./leftMenu";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const State = () => {
  const [chart1, setChart1] = useState("");
  const [chart2, setChart2] = useState("");
  const [chart3, setChart3] = useState("");
  const [chart4, setChart4] = useState("");
  const [chart5, setChart5] = useState("");
  const [chart6, setChart6] = useState("");
  const [chart7, setChart7] = useState("");
  const [chart8, setChart8] = useState("");

  const getChart1 = async () => {
    try {
      var type = "";

      const response = await axios.post("https://goipsi.co.kr:8008/chart1", {
        type,
      });

      const data = response.data;
      console.log(data[0]);
      setChart1(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChart2 = async () => {
    try {
      const response = await axios.post("https://goipsi.co.kr:8008/chart2", {});

      const data = response.data;
      console.log(data[0]);
      setChart2(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChart3 = async () => {
    try {
      const response = await axios.post("https://goipsi.co.kr:8008/chart3", {});

      const data = response.data;
      console.log(data[0]);
      setChart3(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChart4 = async () => {
    try {
      const response = await axios.post("https://goipsi.co.kr:8008/chart4", {});

      const data = response.data;
      console.log(data[0]);
      setChart4(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChart5 = async () => {
    try {
      const response = await axios.post("https://goipsi.co.kr:8008/chart5", {});

      const data = response.data;
      console.log(data[0]);
      setChart5(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChart6 = async () => {
    try {
      const response = await axios.post("https://goipsi.co.kr:8008/chart6", {});

      const data = response.data;
      console.log(data[0]);
      setChart6(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChart7 = async () => {
    try {
      const response = await axios.post("https://goipsi.co.kr:8008/chart7", {});

      const data = response.data;
      console.log(data[0]);
      setChart7(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChart8 = async () => {
    try {
      const response = await axios.post("https://goipsi.co.kr:8008/chart8", {});

      const data = response.data;
      console.log(data[0]);
      setChart8(data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getChart1();
    getChart2();
    getChart3();
    getChart4();
    getChart5();
    getChart6();
    getChart7();
    getChart8();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options_total = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "전체 건수",
      },
    },

    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart1.total + 50,
      },
    },
  };
  const data_total = {
    labels: [
      "신청건수",
      "취소건수",
      "행사건수",
      "불참건수",
      "실제행사진행건수",
    ],
    datasets: [
      {
        data: [
          chart1.total,
          chart1.cancel,
          chart1.scheduletotal,
          chart1.scheduleError,
          chart1.scheduletotal,
        ],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const data_total_univ = {
    labels: [
      "신청건수",
      "취소건수",
      "행사건수",
      "불참건수",
      "실제행사진행건수",
    ],
    datasets: [
      {
        data: [
          chart1.total,
          chart1.cancel,
          chart1.scheduletotal,
          chart1.scheduleError,
          chart1.scheduleuniv,
          chart1.scheduletotal,
        ],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options_schedule = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "진행 내역",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart2.schedule1 + 50,
      },
    },
  };
  const data_options_schedule = {
    labels: ["설명회", "박람회", "교사대상 설명회"],
    datasets: [
      {
        data: [chart2.schedule1, chart2.schedule2, chart2.schedule3],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options_univ = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "대학별 신청건수",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart3.univ1 + 50,
      },
    },
  };
  const data_univ = {
    labels: [
      "나사렛대",
      "남서울대",
      "단국대",
      "백석대",
      "상명대",
      "선문대",
      "한국기술교육대",
      "호서대",
    ],
    datasets: [
      {
        data: [
          chart3.univ1,
          chart3.univ2,
          chart3.univ3,
          chart3.univ4,
          chart3.univ5,
          chart3.univ6,
          chart3.univ7,
          chart3.univ8,
        ],
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options_area = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "지역",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart4.area1 + 10,
      },
    },
  };
  const data_options_area = {
    labels: ["경기", "충남", "충북", "서울", "인천", "대전", "세종"],
    datasets: [
      {
        data: [
          chart4.area1,
          chart4.area2,
          chart4.area5,
          chart4.area3,
          chart4.area4,
          chart4.area7,
          chart4.area6,
        ],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options_month = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "행사 시기",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart4.area1 + 10,
      },
    },
  };
  const data_options_month = {
    labels: ["5월", "6월", "7월", "8월", "9월"],
    datasets: [
      {
        data: [
          chart5.month1,
          chart5.month2,
          chart5.month3,
          chart5.month4,
          chart5.month5,
        ],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options_student = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "학생수",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart4.area1 + 10,
      },
    },
  };
  const data_options_student = {
    labels: [
      "1~10명",
      "11~20명",
      "21~30명",
      "31~40명",
      "41~50명",
      "51~60명",
      "61~70명",
      "71~80명",
      "81~90명",
      "91~100명",
      "100명이상",
    ],
    datasets: [
      {
        data: [
          chart6.stu1,
          chart6.stu2,
          chart6.stu3,
          chart6.stu4,
          chart6.stu5,
          chart6.stu6,
          chart6.stu7,
          chart6.stu8,
          chart6.stu9,
          chart6.stu10,
          chart6.stu11,
        ],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options_thea = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "교사수",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart4.area1 + 10,
      },
    },
  };
  const data_options_thea = {
    labels: ["1~5명", "6~10명", "11~15명", "16명이상"],
    datasets: [
      {
        data: [chart7.thea1, chart7.thea2, chart7.thea3, chart7.thea4],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options_mf = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "학부모수",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: chart4.area1 + 10,
      },
    },
  };
  const data_options_mf = {
    labels: ["1~5명", "6명이상"],
    datasets: [
      {
        data: [chart8.mf1, chart8.mf2],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>통계 관리
            </h2>
          </div>
          <div>
            <div className="statistics_top"></div>
            {/* 신청 수 */}
            <div className="container-fluid">
              <div className="group">
                <div className="graph">
                  <Bar options={options_total} data={data_total} />
                </div>
              </div>
            </div>
            {/* 지역 */}
            <div className="container-fluid ">
              <div className="group">
                <div className="graph">
                  <Bar options={options_area} data={data_options_area} />
                </div>
              </div>
            </div>
            {/* 형식 */}
            <div className="container-fluid ">
              <div className="group">
                <div className="graph">
                  <Bar
                    options={options_schedule}
                    data={data_options_schedule}
                  />
                </div>
              </div>
            </div>
            {/* 고교유형 */}
            {/* 행사시기 */}
            <div className="container-fluid ">
              <div className="group">
                <div className="graph">
                  <Bar options={options_month} data={data_options_month} />
                </div>
              </div>
            </div>
            {/* 행사요일 */}
            {/* 행사시간 */}
            {/* 학생수 */}
            <div className="container-fluid ">
              <div className="group">
                <div className="graph">
                  <Bar options={options_student} data={data_options_student} />
                </div>
              </div>
            </div>
            {/* 교사수 */}
            <div className="container-fluid ">
              <div className="group">
                <div className="graph">
                  <Bar options={options_thea} data={data_options_thea} />
                </div>
              </div>
            </div>
            {/* 학부모수 */}
            <div className="container-fluid ">
              <div className="group">
                <div className="graph">
                  <Bar options={options_mf} data={data_options_mf} />
                </div>
              </div>
            </div>

            {/* 대학별 신청건수 */}
            <div className="container-fluid">
              <div className="group">
                <div className="graph">
                  <Bar options={options_univ} data={data_univ} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default State;
