import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>대학교 관리</h1>
      <ul>
        <h2>대학교</h2>
        <li>
          <Link
            to="/univ"
            style={{
              color: location.pathname === "/univ" && "#971b1e",
            }}
          >
            입시정보 대학 리스트
          </Link>
        </li>
        <li>
          <Link
            to="/teacheruniv"
            style={{
              color: location.pathname === "/teacheruniv" && "#971b1e",
            }}
          >
            교사설명회 대학 관리
          </Link>
        </li>
        <li>
          <Link
            to="/earlyexamuniv"
            style={{
              color: location.pathname === "/earlyexamuniv" && "#971b1e",
            }}
          >
            전국순회박람회 대학 관리
          </Link>
        </li>
        <li>
          <Link
            to="/rollinguniv"
            style={{
              color: location.pathname === "/rollinguniv" && "#971b1e",
            }}
          >
            하단 롤링배너 대학 관리
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
