import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>홈페이지 관리</h1>
      <ul>
        <h2>기본정책</h2>
        <li>
          <Link
            to="/config"
            style={{
              color: location.pathname === "/config" && "#971b1e",
            }}
          >
            이용약관
          </Link>
        </li>
        <li>
          <Link
            to="/policy"
            style={{
              color: location.pathname === "/policy" && "#971b1e",
            }}
          >
            개인정보처리방침
          </Link>
        </li>
      </ul>
      <ul>
        <h2>회사소개관리</h2>
        <li>
          <Link
            to="/history"
            style={{
              color: location.pathname === "/history" && "#971b1e",
            }}
          >
            연혁 관리
          </Link>
        </li>
        <li>
          <Link
            to="/greeting"
            style={{
              color: location.pathname === "/greeting" && "#971b1e",
            }}
          >
            대표자 인사말
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
