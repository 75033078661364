import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CollegeIcon from "../assets/quickMenu/quickMenu_college.png";
import ExpoIcon from "../assets/quickMenu/quickMenu_expo.png";
import NewsIcon from "../assets/quickMenu/quickMenu_news.png";
import teachersIcon from "../assets/quickMenu/quickMenu_teachers.png";
import visitingExpoIcon from "../assets/quickMenu/quickMenu_visitingExpo.png";
import "../css/common.scss";

const QuickMenu = () => {
  const [scrollTop, _scrollTop] = useState("20%");

  useEffect(() => {
    const scrollCheck = () => {
      if (window.scrollY > 100) {
        _scrollTop("2%");
      } else if (window.scrollY < 10) {
        _scrollTop("20%");
      }
    };
    window.addEventListener("scroll", scrollCheck);
    return () => {
      window.removeEventListener("scroll", scrollCheck);
    };
  }, []);
  return (
    <ul className="sideQuickMenu" style={{ top: scrollTop }}>
      <li>
        <Link to="/TeacherExpo">
          <img src={teachersIcon} alt="teachersIcon" />
          교사설명회
        </Link>
      </li>
      <li>
        <Link to="/NationalExpo">
          <img src={ExpoIcon} alt="ExpoIcon" />
          전국순회
          <br />
          박람회
        </Link>
      </li>
      <li>
        <Link to="/VisitingExpo">
          <img src={visitingExpoIcon} alt="visitingExpoIcon" />
          찾아가는
          <br />
          박람회
        </Link>
      </li>
      <li>
        <Link to="/IpsiNews">
          <img src={NewsIcon} alt="NewsIcon" />
          입시뉴스
        </Link>
      </li>
      <li style={{ borderBottom: "0" }}>
        <Link to="/CollegeList">
          <img src={CollegeIcon} alt="CollegeIcon" />
          대학소개
        </Link>
      </li>
    </ul>
  );
};

export default QuickMenu;
