import Aos from "aos";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import LogoBanner from "../../component/logoBanner";
import QuickMenu from "../../component/quickMenu";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import "../../css/exposition.scss";
import "../../css/main.scss";
import Footer from "../../layout/footer";
import Header from "../../layout/header";
Aos.init();

const ExpoMain = () => {
  const { keyword } = useParams();
  const [listdata, _listdata] = useState([]);

  const [boardPage, _boardPage] = useState(1);
  const itemsPerPage = 2;
  const [currentList, _currentList] = useState(listdata);
  const indexOfLastItem = boardPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const changePage = (boardPage) => {
    _boardPage(boardPage);
  };

  const getHtml = async () => {
    try {
      const mode = "bbslist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", "E");
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);

      _listdata(response.data.data);
      _currentList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getHtml();
  }, []);

  useEffect(() => {
    _currentList(listdata.slice(indexOfFirstItem, indexOfLastItem));
  }, [boardPage, listdata]);

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <div className="contents">
        <div className="expoBannerBox">
          <div className="expoBanner">
            <h1>대입박람회/설명회</h1>
            <h2>
              교사들을 대상으로 하는 입시설명회, 전국을 순회하는 입시박람회,
              찾아가는 박람회까지
              <br />
              성공적인 입시를 위한 각 대학들의 모든 입시정보
              <br />
              아이앤유니와 함께 완벽대비하세요.
            </h2>
          </div>
          <img
            src="/assets/exposition/expoBannerImg.png"
            data-aos="fade-left"
            data-aos-duration="1000"
          />
        </div>
        <div className="expoContent">
          <div class="flip-card">
            <div class="flip-card-inner">
              <div
                class="flip-card-front"
                style={{
                  backgroundImage: 'url("/assets/exposition/expo1.png")',
                }}
              >
                <Link to="/TeacherExpo"></Link>
                <h2>교사설명회</h2>
              </div>
              <div
                class="flip-card-back"
                style={{
                  backgroundImage: 'url("/assets/exposition/expo1Hover.png")',
                }}
              >
                <Link to="/TeacherExpo"></Link>
                <h2>교사설명회</h2>
                <div className="expoUiBtn">&nbsp;More</div>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div
                class="flip-card-front"
                style={{
                  backgroundImage: 'url("/assets/exposition/expo2.png")',
                }}
              >
                <Link to="/NationalExpo"></Link>
                <h2>전국 순회박람회</h2>
              </div>
              <div
                class="flip-card-back"
                style={{
                  backgroundImage: 'url("/assets/exposition/expo2Hover.png")',
                }}
              >
                <Link to="/NationalExpo"></Link>
                <h2>전국 순회박람회</h2>
                <div className="expoUiBtn">&nbsp;More</div>
              </div>
            </div>
          </div>

          <div class="flip-card">
            <div class="flip-card-inner">
              <div
                class="flip-card-front"
                style={{
                  backgroundImage: 'url("/assets/exposition/expo3.png")',
                }}
              >
                <Link to="/VisitingExpo"></Link>
                <h2>찾아가는 박람회</h2>
              </div>
              <div
                class="flip-card-back"
                style={{
                  backgroundImage: 'url("/assets/exposition/expo3Hover.png")',
                }}
              >
                <Link to="/VisitingExpo"></Link>
                <h2>찾아가는 박람회</h2>
                <div className="expoUiBtn">&nbsp;More</div>
              </div>
            </div>
          </div>
          <div className="expoNoticeBox" style={{ height: "445px" }}>
            <Link to="/event" style={{}}>
              <img
                src="/assets/exposition/expoEvent.png"
                style={{ height: "445px" }}
              />
            </Link>
          </div>
        </div>
        <QuickMenu type="margin" />
        <LogoBanner />
      </div>
      <Footer />
    </>
  );
};

export default ExpoMain;
