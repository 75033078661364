import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

import FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const TeacherReservationList = () => {
  const { page } = useParams();
  const [listdata, _listdata] = useState([]);
  const [tabState, _tabState] = useState("I");
  const [excelData, _excelData] = useState([]);

  const getHtml = async () => {
    try {
      const mode = "earlyexamshedulelist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(
        config.URL + "earlyExamRequest.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
      _excelData(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelFileExtension = ".xlsx";
  const excelFileName = "excel_data";

  const excelDownload = (excelData) => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "행사지역",
        "행사개최일",
        "고등학교명",
        "이름",
        "학년",
        "연락처",
        "신청현황",
      ],
    ]);

    excelData.forEach((data) => {
      const scheduleState = data.cancel == "N" ? "신청" : "신청취소";
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            data.local == "I"
              ? "인천"
              : data.local == "S"
              ? "수원"
              : data.local == "E"
              ? "의정부"
              : data.local == "G"
              ? "고양"
              : data.local == "P"
              ? "평택"
              : "",
            data.eventDate,
            data.schoolName,
            data.teacherName,
            data.grade,
            data.phone,
            scheduleState,
          ],
        ],
        {
          origin: -1,
        }
      );
      return false;
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelBuffer], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
  };

  useEffect(() => {
    page == "I"
      ? _tabState("I")
      : page == "S"
      ? _tabState("S")
      : page == "E"
      ? _tabState("E")
      : page == "G"
      ? _tabState("G")
      : page == "P"
      ? _tabState("P")
      : _tabState("I");
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [tabState]);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>전국순회박람회 신청 내역
            </h2>
          </div>
          <ul
            className="tabStateUl"
            style={{ width: "1060px", marginBottom: "30px" }}
          >
            <li
              className={tabState === "I" ? "tabOn" : ""}
              onClick={() => {
                _tabState("I");
              }}
            >
              인천
            </li>
            <li
              className={tabState === "S" ? "tabOn" : ""}
              onClick={() => {
                _tabState("S");
              }}
            >
              수원
            </li>
            <li
              className={tabState === "E" ? "tabOn" : ""}
              onClick={() => {
                _tabState("E");
              }}
            >
              의정부
            </li>
            <li
              className={tabState === "G" ? "tabOn" : ""}
              onClick={() => {
                _tabState("G");
              }}
            >
              고양
            </li>
            <li
              className={tabState === "P" ? "tabOn" : ""}
              onClick={() => {
                _tabState("P");
              }}
            >
              평택
            </li>
          </ul>
          <div className="substance">
            <h2>
              <button type="button" onClick={() => excelDownload(excelData)}>
                excel
              </button>
            </h2>
          </div>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width={"10%"}>
                행사 개최일
              </td>
              <td className="tableListTitle" width={"15%"}>
                고등학교명
              </td>
              <td className="tableListTitle" width={"10%"}>
                이름
              </td>
              <td className="tableListTitle" width={"10%"}>
                학년
              </td>
              <td className="tableListTitle" width={"15%"}>
                연락처
              </td>
              {/* <td className="tableListTitle" width={"15%"}>
                관심대학
              </td> */}
              <td className="tableListTitle" width={"10%"}>
                신청현황
              </td>
            </tr>
            {tabState === "I" &&
              listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">
                    {format(schedule.eventDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.grade}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  {/* <td className="tableListTitle">
                    {schedule.interestUniv1}
                    <br />
                    {schedule.interestUniv2}
                    <br />
                    {schedule.interestUniv3}
                  </td> */}
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
            {tabState === "S" &&
              listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">
                    {format(schedule.eventDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.grade}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  {/* <td className="tableListTitle">
                    {schedule.interestUniv1}
                    <br />
                    {schedule.interestUniv2}
                    <br />
                    {schedule.interestUniv3}
                  </td> */}
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
            {tabState === "E" &&
              listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">
                    {format(schedule.eventDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.grade}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  {/* <td className="tableListTitle">
                    {schedule.interestUniv1}
                    <br />
                    {schedule.interestUniv2}
                    <br />
                    {schedule.interestUniv3}
                  </td> */}
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
            {tabState === "G" &&
              listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">
                    {format(schedule.eventDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.grade}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  {/* <td className="tableListTitle">
                    {schedule.interestUniv1}
                    <br />
                    {schedule.interestUniv2}
                    <br />
                    {schedule.interestUniv3}
                  </td> */}
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
            {tabState === "P" &&
              listdata &&
              listdata.map((schedule, index) => (
                <tr key={index}>
                  <td className="tableListTitle">
                    {format(schedule.eventDate, "yyyy-MM-dd")}
                  </td>
                  <td className="tableListTitle">{schedule.schoolName}</td>
                  <td className="tableListTitle">{schedule.teacherName}</td>
                  <td className="tableListTitle">{schedule.grade}</td>
                  <td className="tableListTitle">{schedule.phone}</td>
                  {/* <td className="tableListTitle">
                    {schedule.interestUniv1}
                    <br />
                    {schedule.interestUniv2}
                    <br />
                    {schedule.interestUniv3}
                  </td> */}
                  <td className="tableListTitle">
                    {schedule.cancel === "N"
                      ? "신청"
                      : schedule.cancel === "Y"
                      ? "신청취소"
                      : ""}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TeacherReservationList;
