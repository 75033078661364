import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdLeftBanner from "../../component/leftBanner";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import "../../css/main.scss";
import "../../css/mediaCommercial.scss";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const HighSchoolBoard = () => {
  const { page } = useParams();
  const [tabState, _tabState] = useState("");
  const [rData, _rData] = useState([]);

  const validTabs = ["H", "T", "L", "B", "F", "P"];

  const getSellProduct = async (currentTab) => {
    try {
      const response = await axios.post(
        `${config.URL}/sellProduct.php`,
        {
          mode: "sellproductfront",
          tabState: currentTab,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("Fetched Data:", response.data);
      _rData(response.data.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    const newTab = validTabs.includes(page) ? page : "H";
    _tabState(newTab);
    getSellProduct(newTab);
  }, [page]);

  useEffect(() => {
    console.log("Current tabState:", tabState);
    getSellProduct(tabState);
  }, [tabState]);

  return (
    <>
      <Header />
      {/* <AdLeftBanner /> */}
      <div
        className="content"
        style={{ width: "1280px", margin: "0 auto", border: "0" }}
      >
        <img
          src="/assets/commercial/banner.png"
          className="ipsiBanner"
          style={{ margin: "0 auto", padding: "30px 0 30px 0" }}
        />
        <ul className="boardUl">
          <li
            className={tabState === "H" ? "tabOn" : ""}
            onClick={() => {
              _tabState("H");
            }}
          >
            고교게시판
          </li>
          <li
            className={tabState === "T" ? "tabOn" : ""}
            onClick={() => {
              _tabState("T");
            }}
          >
            상위권게시판
          </li>
          <li
            className={tabState === "L" ? "tabOn" : ""}
            onClick={() => {
              _tabState("L");
            }}
          >
            지역게시판
          </li>
          <li
            className={tabState === "B" ? "tabOn" : ""}
            onClick={() => {
              _tabState("B");
            }}
          >
            모집요강배포
          </li>
          <li
            className={tabState === "F" ? "tabOn" : ""}
            onClick={() => {
              _tabState("F");
            }}
          >
            편입홍보
          </li>
          <li
            className={tabState === "P" ? "tabOn" : ""}
            onClick={() => {
              _tabState("P");
            }}
          >
            매체대행
          </li>
        </ul>
        <AdLeftBanner />
        <QuickMenuR />
        {rData && (
          <>
            {rData?.length > 0 &&
              rData.map((product) => (
                <div
                  className="boardContentBox"
                  key={product.id}
                  tabState={tabState}
                >
                  <h2>
                    <span></span>
                    {product.title}
                  </h2>
                  <div className="boardContent">
                    <div className="boardImgBox">
                      <img
                        src={`${config.FILEURL + product.img}`}
                        alt={product.title}
                      />
                    </div>
                    <div
                      className="boardTextContent"
                      dangerouslySetInnerHTML={{ __html: product.content }}
                    ></div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HighSchoolBoard;
