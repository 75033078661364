import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>배너 관리</h1>
      <ul>
        <h2>광고배너 관리</h2>
        <li>
          <Link
            to="/main_banner"
            style={{
              color: location.pathname === "/main_banner" && "#971b1e",
            }}
          >
            메인 슬라이드 배너
          </Link>
        </li>
        <li>
          <Link
            to="/admintopbanner"
            style={{
              color: location.pathname === "/admintopbanner" && "#971b1e",
            }}
          >
            상단 메뉴 배너
          </Link>
        </li>
        <li>
          <Link
            to="/adminwidebanner"
            style={{
              color: location.pathname === "/adminwidebanner" && "#971b1e",
            }}
          >
            본문 와이드형 배너
          </Link>
        </li>
        <li>
          <Link
            to="/adminleftbanner"
            style={{
              color: location.pathname === "/adminleftbanner" && "#971b1e",
            }}
          >
            왼쪽 세로형 배너
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
