import React from "react";
import { Link, useLocation } from "react-router-dom";

const LeftMenu = () => {
  const location = useLocation();

  return (
    <div className="leftMenu">
      <h1>대입박람회/설명회 관리</h1>
      <ul>
        <h2>대입설명회</h2>
        <li>
          <Link
            to="/expo"
            style={{
              color: location.pathname === "/expo" && "#971b1e",
            }}
          >
            교사설명회 신청내역
          </Link>
        </li>
        <li>
          <Link
            to="/exposcheduler"
            style={{
              color: location.pathname === "/exposcheduler" && "#971b1e",
            }}
          >
            교사설명회 행사일정
          </Link>
        </li>
      </ul>
      <ul>
        <h2>대입박람회</h2>
        <li>
          <Link
            to="/fair"
            style={{
              color: location.pathname === "/fair" && "#971b1e",
            }}
          >
            전국순회박람회 신청내역
          </Link>
        </li>
        <li>
          <Link
            to="/fairschedule"
            style={{
              color: location.pathname === "/fairschedule" && "#971b1e",
            }}
          >
            전국순회박람회 행사일정
          </Link>
        </li>
        <li>
          <Link
            to="/visitingexposchedule"
            style={{
              color: location.pathname === "/visitingexposchedule" && "#971b1e",
            }}
          >
            찾아가는박람회 신청내역
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;
