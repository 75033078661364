import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CollegeIcon from "../assets/quickMenu/quickMenu_college.png";
import ExpoIcon from "../assets/quickMenu/quickMenu_expo.png";
import NewsIcon from "../assets/quickMenu/quickMenu_news.png";
import teachersIcon from "../assets/quickMenu/quickMenu_teachers.png";
import visitingExpoIcon from "../assets/quickMenu/quickMenu_visitingExpo.png";
import "../css/common.scss";
import { logout } from "../store/store";

const QuickMenu = (props) => {
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  const [quickMargin, _quickMargin] = useState();

  useEffect(() => {
    props.type == "margin"
      ? _quickMargin("quickBoxMarginB")
      : _quickMargin("quickBox");
  });

  return (
    <div className={quickMargin}>
      <h2 className="quickBoxTitle">Quick Menu</h2>
      <ul className="quickMenuBox">
        <li>
          <Link to="/TeacherExpo">
            <img src={teachersIcon} alt="teachersIcon" />
            교사설명회
          </Link>
        </li>
        <li>
          <Link to="/NationalExpo">
            <img src={ExpoIcon} alt="ExpoIcon" />
            전국순회박람회
          </Link>
        </li>
        <li>
          <Link to="/VisitingExpo">
            <img src={visitingExpoIcon} alt="visitingExpoIcon" />
            찾아가는박람회
          </Link>
        </li>
        <li>
          <Link to="/IpsiNews">
            <img src={NewsIcon} alt="NewsIcon" />
            입시뉴스
          </Link>
        </li>
        <li>
          <Link to="/CollegeList">
            <img src={CollegeIcon} alt="CollegeIcon" />
            대학소개
          </Link>
        </li>
      </ul>
      <div className="userBox">
        {isLoggedIn ? (
          <>
            {user.name} 님
            <span className="txt_bar" style={{ margin: "0 5px" }}></span>
            {user.userType == "A"
              ? "관리자"
              : user.userType == "T"
              ? "교사회원"
              : user.userType == "S"
              ? "학생회원"
              : user.userType == "M"
              ? "일반회원"
              : ""}
            <ul>
              <li>
                <Link to="/mysetting">마이페이지</Link>
              </li>
              <li>
                <Link to="#" style={{ border: "0" }} onClick={logOut}>
                  로그아웃
                </Link>
              </li>
            </ul>
          </>
        ) : (
          <>
            <Link to="/Login">로그인</Link>
            <ul>
              <li>
                <Link to="/FindID">아이디찾기</Link>
              </li>
              <li>
                <Link to="/FindID">비밀번호찾기</Link>
              </li>
              <li>
                <Link to="Join" style={{ border: "0" }}>
                  회원가입
                </Link>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default QuickMenu;
