import React from "react";
import "../css/board.scss";

const Pagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  prevPageText = "<",
  nextPageText = ">",
  onChange,
}) => {
  const numPages = Math.ceil(totalItemsCount / itemsCountPerPage);
  const firstNum = Math.max(1, activePage - Math.floor(pageRangeDisplayed / 2));
  const lastNum = Math.min(numPages, firstNum + pageRangeDisplayed - 1);

  return (
    <div className="paginationBtn">
      <button
        onClick={() => onChange(activePage - 1)}
        disabled={activePage === 1}
      >
        {prevPageText}
      </button>
      {[...Array(lastNum - firstNum + 1)].map((_, i) => {
        const page = firstNum + i;
        return (
          <button
            key={page}
            className={activePage === page ? "active" : ""}
            onClick={() => onChange(page)}
          >
            {page}
          </button>
        );
      })}
      <button
        onClick={() => onChange(activePage + 1)}
        disabled={activePage === numPages}
      >
        {nextPageText}
      </button>
    </div>
  );
};

export default Pagination;
