import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config/config";
import Footer from "../../../layout/footer";
import Header from "../layout/header";
import LeftMenu from "./leftMenu";

const EarlyExamUniv = () => {
  const [listdata, _listdata] = useState([]);
  const { page } = useParams();
  const [type, _type] = useState("N");
  const [schoolName, _schoolName] = useState("");
  const [local, _local] = useState("I");
  const [link, _link] = useState("");
  const [id, _id] = useState("");
  const [tabState, _tabState] = useState("I");

  const getHtml = async () => {
    try {
      const mode = "earlyexamunivlist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("tabState", tabState);
      const response = await axios.post(
        config.URL + "earlyExamUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      _listdata(response.data.data);
      _type("N");
    } catch (error) {
      console.log("error", error);
    }
  };

  const postData = async () => {
    try {
      const mode = "earlyexamunivadd";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", schoolName);
      formData.append("local", local);
      formData.append("link", link);

      const response = await axios.post(
        config.URL + "earlyExamUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const changeData = async (univ) => {
    _id(univ.id);
    _schoolName(univ.schoolName);
    _local(univ.local);
    _link(univ.link);
    _type("Y");
  };

  const updateData = async () => {
    try {
      const mode = "earlyexamunivupdate";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("schoolName", schoolName);
      formData.append("local", local);
      formData.append("link", link);
      formData.append("id", id);

      const response = await axios.post(
        config.URL + "earlyExamUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteCheck = () => {
    if (window.confirm("삭제하시겠습니까? 복구할 수 없습니다.")) {
      deleteData();
    } else {
      return;
    }
  };

  const deleteData = async () => {
    try {
      const mode = "earlyexamunivdel";
      const formData = new FormData();
      formData.append("id", id);
      formData.append("mode", mode);

      const response = await axios.post(
        config.URL + "earlyExamUniv.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log(response);
      getHtml();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    page == "I"
      ? _tabState("I")
      : page == "S"
      ? _tabState("S")
      : page == "E"
      ? _tabState("E")
      : page == "G"
      ? _tabState("G")
      : page == "P"
      ? _tabState("P")
      : _tabState("I");
  }, [page]);

  useEffect(() => {
    getHtml();
  }, [tabState]);

  return (
    <>
      <Header />
      <div className="subContent" style={{ marginBottom: "0" }}>
        <LeftMenu />
        <div className="content">
          <div className="substance">
            <h2>
              <span></span>전국순회박람회 대학교 관리
              {type === "N" ? (
                <button onClick={() => postData()}>추가</button>
              ) : (
                <>
                  <button onClick={deleteCheck}>삭제</button>
                  <button onClick={updateData} style={{ marginRight: "10px" }}>
                    수정
                  </button>
                </>
              )}
            </h2>
          </div>
          <table className="table">
            <tr>
              <td className="tableTitle">대학교 이름</td>
              <td width="250px">
                <input
                  type="text"
                  value={schoolName}
                  onChange={(e) => _schoolName(e.target.value)}
                  style={{ width: "80%", border: "0" }}
                />
              </td>
              <td className="tableTitle">지역</td>
              <td>
                <select
                  value={local}
                  onChange={(e) => _local(e.target.value)}
                  style={{ width: "120px" }}
                >
                  <option value={"I"}>인천</option>
                  <option value={"S"}>수원</option>
                  <option value={"E"}>의정부</option>
                  <option value={"G"}>고양</option>
                  <option value={"P"}>평택</option>
                </select>
              </td>
            </tr>
            <tr>
              <td className="tableTitle">홈페이지 링크</td>
              <td colSpan={3}>
                <input
                  type="text"
                  value={link}
                  onChange={(e) => _link(e.target.value)}
                  style={{ width: "80%", height: "30px", border: "0" }}
                />
              </td>
            </tr>
          </table>
          <div className="substance" style={{ marginTop: "50px" }}>
            <h2>
              <span></span>전국순회박람회 대학교 목록
            </h2>
          </div>
          <ul
            className="tabStateUl"
            style={{ width: "1060px", marginBottom: "30px" }}
          >
            <li
              className={tabState === "I" ? "tabOn" : ""}
              onClick={() => {
                _tabState("I");
              }}
            >
              인천
            </li>
            <li
              className={tabState === "S" ? "tabOn" : ""}
              onClick={() => {
                _tabState("S");
              }}
            >
              수원
            </li>
            <li
              className={tabState === "E" ? "tabOn" : ""}
              onClick={() => {
                _tabState("E");
              }}
            >
              의정부
            </li>
            <li
              className={tabState === "G" ? "tabOn" : ""}
              onClick={() => {
                _tabState("G");
              }}
            >
              고양
            </li>
            <li
              className={tabState === "P" ? "tabOn" : ""}
              onClick={() => {
                _tabState("P");
              }}
            >
              평택
            </li>
          </ul>
          <table className="tableList">
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <td className="tableListTitle" width="15%">
                순서
              </td>
              <td className="tableListTitle">대학교</td>
              <td className="tableListTitle" width="30%">
                홈페이지
              </td>
            </tr>
            {tabState == "I" &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            {tabState == "S" &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            {tabState == "E" &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            {tabState == "G" &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            {tabState == "P" &&
              listdata &&
              listdata.map((univ, index) => (
                <tr key={index} onClick={() => changeData(univ)}>
                  <td className="tableListTitle">{index + 1}</td>
                  <td className="tableListTitle">{univ.schoolName}</td>
                  <td className="tableListTitle">
                    {univ.link ? (
                      <a href={univ.link} target="_blank" rel="noreferrer">
                        바로가기
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EarlyExamUniv;
