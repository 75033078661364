import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import AdLeftBanner from "../../component/leftBanner";
import Pagination from "../../component/pagination";
import QuickMenuR from "../../component/quickMenuR";
import config from "../../config/config";
import Footer from "../../layout/footer";
import Header from "../../layout/header";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Event = () => {
  const { keyword } = useParams();
  const [listdata, _listdata] = useState([]);

  const [boardPage, _boardPage] = useState(1);
  const itemsPerPage = 2;
  const [currentList, _currentList] = useState(listdata);
  const indexOfLastItem = boardPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [loading, _loading] = useState(true);
  const [color, _color] = useState("#ffffff");

  const changePage = (boardPage) => {
    _boardPage(boardPage);
  };

  const getHtml = async () => {
    try {
      const mode = "bbslist";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("bbsType", "E");
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);

      _listdata(response.data.data);
      _currentList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getSearch = async () => {
    try {
      const mode = "bbssearch";
      const formData = new FormData();
      formData.append("mode", mode);
      formData.append("keyword", keyword);
      const response = await axios.post(config.URL + "bbs.php", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response);
      if (response.data.data.length > 0) {
        _listdata(response.data.data);
        _currentList(response.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    _boardPage(1);
    getHtml();
  }, []);

  useEffect(() => {
    _currentList(listdata.slice(indexOfFirstItem, indexOfLastItem));
  }, [boardPage, listdata]);

  return (
    <>
      <Header />
      <AdLeftBanner />
      <QuickMenuR />
      <img
        src="/assets/contactus/eventBanner.png"
        alt="이벤트 배너"
        className="ipsiBanner"
      />
      <ul className="eventGallery">
        {!listdata ? (
          <ClipLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          currentList.map((bbs, index) => (
            <li>
              <Link to="/boardview/E" state={{ data: bbs }}>
                <img src={config.FILEURL + bbs.img} />
                <h2>{bbs.title}</h2>
              </Link>
            </li>
          ))
        )}
      </ul>
      <ul className="paginationBtn">
        <Pagination
          activePage={boardPage} // 현재 페이지
          itemsCountPerPage={itemsPerPage} // 한 페이지 당 보여줄 아이템 갯수
          totalItemsCount={listdata.length} // 총 아이템 갯수
          pageRangeDisplayed={10} // paginator에 나타낼 페이지 범위
          prevPageText={"<"} // "이전"을 나타낼 텍스트
          nextPageText={">"} // "다음"을 나타낼 텍스트
          onChange={changePage} // 페이지 변경을 핸들링하는 함수
        />
      </ul>
      <Footer />
    </>
  );
};

export default Event;
